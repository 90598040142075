import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'src/contexts/auth';

export const useRoutePath = () => {
  const location = useLocation();

  const [routePath, setRoutePath] = useState<string>(location.pathname);

  const { authState } = useAuth();

  useEffect(() => {
    const { pathname, search } = location;

    const queryParams = [];

    if (authState.isReferral) {
      queryParams.push('utm_source=referral');
    }

    if (search) {
      queryParams.push(search.substring(1));
    }

    const computedPath = queryParams.length > 0 ? `${pathname}?${queryParams.join('&')}` : pathname;

    setRoutePath(computedPath);
  }, [authState.isReferral, location]);

  return routePath;
};
