import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ViewIconOff = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.79 4.8901L17.79 7.8901L17.8468 7.92694C19.4404 9.02157 20.8352 10.3846 22 12C22 12 18 18 12 18H11.9977C10.7114 17.9942 9.48522 17.7338 8.42997 17.29L4.90997 20.8C4.81094 20.9151 4.66363 20.9888 4.49988 20.9888C4.36603 20.9888 4.24356 20.9401 4.14997 20.8601C4.05744 20.7662 4.00034 20.6373 4.00034 20.4951C4.00034 20.3528 4.05747 20.2239 4.14997 20.1301L20.1496 4.13044C20.242 4.04626 20.3651 3.99463 20.5 3.99463C20.635 3.99463 20.7579 4.0461 20.85 4.13007C20.9306 4.22444 20.9787 4.34616 20.9787 4.47997C20.9787 4.64375 20.9058 4.79047 20.79 4.8901ZM12.0195 16.9996C14.5647 16.8994 16.8705 15.8674 18.6 14.2402L18.621 14.2225C19.293 13.6412 19.9179 13.0069 20.51 12.3002L20.76 12.0002L20.51 11.7002L20.489 11.6745C19.9179 10.9936 19.2931 10.3592 18.6 9.7602L18.6163 9.77429C18.1532 9.36732 17.664 8.98929 17.1 8.61023L14 11.7002V12.0002C14 13.1048 13.1046 14.0002 12 14.0002H11.7L9.18 16.5002L9.12103 16.4813C10.0185 16.811 10.9869 16.9937 12 17.0002L12.0195 16.9996Z'
      fill='#278BA5'
    />
    <path
      d='M6.56 16.3101L7.28 15.5901C6.56925 15.163 5.95491 14.7173 5.38194 14.2249L5.39997 14.2401C4.70694 13.6411 4.08209 13.0067 3.51094 12.3258L3.48997 12.3001L3.23997 12.0001L3.48997 11.7001C4.08209 10.9935 4.70694 10.3591 5.379 9.77785L5.4 9.7601C7.12862 8.13141 9.43494 7.09904 11.9809 7.00069L12 7.0001C13.1424 7.00651 14.2294 7.23648 15.2218 7.64842L15.22 7.6501L15.2759 7.67113L15.2218 7.64842L16 6.9001C14.8281 6.33925 13.4537 6.00822 12.0028 6.00013L12 6.0001C6 6.0001 2 12.0001 2 12.0001C3.24597 13.7152 4.75966 15.1492 6.49447 16.2704L6.56 16.3101Z'
      fill='#278BA5'
    />
    <path
      d='M10 12.0001C10 10.8955 10.8954 10.0001 12 10.0001C12.2596 10.0027 12.5077 10.0576 12.72 10.1501L10.15 12.7201L10.1547 12.7323C10.0575 12.5078 10.0026 12.2607 10 12.0001Z'
      fill='#278BA5'
    />
  </SvgIcon>
);

export default ViewIconOff;
