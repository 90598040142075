import { FC, ReactElement, ReactNode, createContext, useContext, useReducer } from 'react';
import { SET_SSB_INFO, SSBActionTypes, SSBContextValue, SSBState } from './types';

const SSBContext = createContext<SSBContextValue | undefined>(undefined);

const initialState: SSBState = null;

const ssbReducer = (prevState: SSBState, action: SSBActionTypes): SSBState => {
  const { type, payload } = action;

  switch (type) {
    case SET_SSB_INFO:
      return payload;

    default:
      return prevState;
  }
};

export const SSBProvider: FC<{ children: ReactNode }> = ({ children }): ReactElement => {
  const [ssbState, dispatchSSB] = useReducer(ssbReducer, initialState);

  return <SSBContext.Provider value={{ ssbState, dispatchSSB }}>{children}</SSBContext.Provider>;
};

export const useSSB = (): SSBContextValue => {
  const ssbContext = useContext(SSBContext);

  if (!ssbContext) {
    throw new Error('useSSB must be used within a SSBContext.Provider');
  }

  return ssbContext;
};
