import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FileUploadIcon = (props: SvgIconProps) => (
  <SvgIcon style={{ width: 48, height: 48 }} viewBox='0 0 48 48' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24 4C12.954 4 4 12.954 4 24C4 35.046 12.954 44 24 44C35.046 44 44 35.046 44 24C44 12.954 35.046 4 24 4ZM23 12C23 11.448 23.448 11 24 11C24.552 11 25 11.448 25 12V23H36C36.552 23 37 23.448 37 24C37 24.552 36.552 25 36 25H25V36C25 36.552 24.552 37 24 37C23.448 37 23 36.552 23 36V25H12C11.448 25 11 24.552 11 24C11 23.448 11.448 23 12 23H23V12Z'
      fill='#278BA5'
    />
  </SvgIcon>
);

export default FileUploadIcon;
