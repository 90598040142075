import {
  ElementTracker,
  ElementTrackerType,
  isNumberValid,
  userClient,
} from '@enaratech/funnel-helper';
import {
  Button,
  CircularProgress,
  Divider,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, FC, FormEvent, ReactElement, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthLayout from 'src/components/Layout/AuthLayout/AuthLayout';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { getEmailFromSession } from 'src/lib/session-storage';
import { navigateToPage } from '../routes';
import './scss/recoveryPassword.scss';

const VerificationCodePassword: FC = (): ReactElement => {
  const [values, setValues] = useState<{ code: string }>({ code: '' });
  const [error, setError] = useState<{ code: string }>({ code: '' });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const routePath = useRoutePath();

  const validateCode = (code: string) => {
    if (!isNumberValid(code)) {
      setError({ code: 'The format is not valid' });
      return false;
    }

    setError({ code: '' });
    return true;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    validateCode(e.target.value);
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const verifyCode = async (): Promise<boolean> => {
    const email = getEmailFromSession();

    if (!email) {
      return false;
    }

    const response = await userClient.recoveryPassCodeAuthenticate({
      email,
      password: values.code,
    });
    setIsSubmitting(false);

    return response;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!validateCode(values?.code)) {
      return;
    }

    setIsSubmitting(true);
    const codeIsValid = verifyCode();

    if (!codeIsValid) {
      formRef.current?.reset();
      setError({ code: 'The code is not valid' });
      return;
    }

    navigateToPage({ targetPage: '/recovery-password', navigate });
  };

  return (
    <AuthLayout>
      <Box mb={1} mt={6}>
        <Typography variant='h3'>Check your inbox</Typography>
      </Box>
      <Box mb={3}>
        <Typography variant='h4'>
          Please enter the verification code that was sent to you.
        </Typography>
      </Box>
      <Box mb={4}>
        <Divider />
      </Box>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Box mb={6}>
          <FormGroup className='form-group'>
            <Box mb={1}>
              <label htmlFor='code'>Verification code</label>
            </Box>
            <ElementTracker
              routePath={routePath}
              name='Verification code'
              type={ElementTrackerType.Blurrable}>
              <TextField
                type='number'
                variant='filled'
                name='code'
                placeholder='Verification code'
                hiddenLabel
                error={!!error?.code}
                helperText={error?.code}
                onChange={handleChange}
              />
            </ElementTracker>
          </FormGroup>
        </Box>
        <FormGroup className='form-group'>
          <ElementTracker
            routePath={routePath}
            name='Verification code button'
            type={ElementTrackerType.Clickable}>
            <Button type='submit' variant='contained' color='primary' disabled={!values.code}>
              {isSubmitting ? <CircularProgress /> : 'Verify'}
            </Button>
          </ElementTracker>
        </FormGroup>
        <Grid container item mb={2} alignItems='center'>
          <Typography variant='subtitle2' mr={1}>
            Don't receive a code?
          </Typography>
          <ElementTracker
            routePath={routePath}
            name='Try again link'
            type={ElementTrackerType.Clickable}>
            <NavLink to='/forgot-password'>
              <Typography variant='subtitle1' color='primary'>
                Try again
              </Typography>
            </NavLink>
          </ElementTracker>
        </Grid>
        <Grid container item mt={4} justifyContent='center' alignItems='center'>
          <ElementTracker
            routePath={routePath}
            name='Back to login link'
            type={ElementTrackerType.Clickable}>
            <NavLink to='/login'>
              <Typography variant='subtitle1' color='primary'>
                Back to Login
              </Typography>
            </NavLink>
          </ElementTracker>
        </Grid>
      </form>
    </AuthLayout>
  );
};

export default VerificationCodePassword;
