import { Grid, Hidden, Typography } from '@mui/material';
import { FC, HTMLAttributes } from 'react';
import EnaraLogoIcon from 'src/components/Common/Icons/EnaraLogoIcon';
import WomanPNG from '../../../assets/images/woman.png';
import './scss/authLayout.scss';

type Props = HTMLAttributes<HTMLDivElement>;

const AuthLayout: FC<Props> = ({ children }) => {
  return (
    <Grid container className='auth-layout vh-full'>
      <Grid container item xs={12} md={5} justifyContent='center' className='login-sidebar'>
        <Grid item xs={10}>
          <Grid item container justifyContent='flex-start' mb={2} ml={-0.5}>
            <EnaraLogoIcon />
          </Grid>
          {children}
        </Grid>
      </Grid>
      <Hidden mdDown>
        <Grid
          container
          item
          xs={0}
          md={7}
          justifyContent='center'
          alignItems='center'
          className='right-side'>
          <Grid item>
            <Typography variant='h1' color='primary' mb={4}>
              Lose weight, not faith
            </Typography>
            <img src={WomanPNG} alt='Lose weight, not faith' />
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default AuthLayout;
