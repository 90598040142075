import {
  ElementTracker,
  ElementTrackerType,
  InputFieldsType,
  isZipCodeValid,
  SECRET_INPUT,
} from '@enaratech/funnel-helper';
import { Grid, TextField } from '@mui/material';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { StripeTextField } from 'src/components/Common/Inputs/StripeInput/StripeTextField';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { PaymentErrors, PaymentForm, PaymentMethodResult } from './Payment.types';

type Props = {
  patient: string;
  inputs: InputFieldsType[];
  setCanContinue: (value: boolean) => void;
  form: PaymentForm;
  setForm: Dispatch<SetStateAction<PaymentForm>>;
  getPaymentMethod: (paymentMethod: PaymentMethodResult) => void;
};

const PaymentInputs: FC<Props> = ({
  patient,
  inputs,
  setCanContinue,
  form,
  setForm,
  getPaymentMethod,
}): ReactElement => {
  const [errors, setErrors] = useState<PaymentErrors | {}>({});

  const stripe = useStripe();
  const elements = useElements();

  const routePath = useRoutePath();

  const canContinue = (): void => {
    if (
      Object.values(errors).every((i) => i.length === 0) &&
      Object.values(form).every((i) => i.length > 0)
    ) {
      setCanContinue(true);
      createPaymentMethod();
    } else {
      setCanContinue(false);
    }
  };

  const createPaymentMethod = async () => {
    var cardElement = elements?.getElement(CardNumberElement);
    const paymentMethodResult = await stripe!.createPaymentMethod({
      billing_details: {
        name: patient,
        address: {
          line1: form.street,
          city: form.city,
          state: form.state,
          postal_code: form.zipCode,
        },
      },
      type: 'card',
      card: cardElement!,
    });

    getPaymentMethod(
      paymentMethodResult?.error
        ? { error: paymentMethodResult?.error }
        : { stripePaymentMethod: { id: `${paymentMethodResult?.paymentMethod.id}` } }
    );
  };

  const validateForm = (field: string, stripe = false, value = false) => {
    if (stripe) {
      if (value) {
        setErrors({
          ...errors,
          [field]: '',
        });
      } else {
        setErrors({
          ...errors,
          [field]: `The ${field.toLowerCase()} is required`,
        });
      }
    } else {
      if (!form[field as keyof typeof form]) {
        setErrors({
          ...errors,
          [field]: `The ${field.toLowerCase()} is required`,
        });
      }

      if (form[field as keyof typeof form]!.length > 0) {
        setErrors({
          ...errors,
          [field]: '',
        });
      }

      if (field === 'zipcode' && form.zipCode && !isZipCodeValid(form.zipCode)) {
        setErrors({
          ...errors,
          [field]: 'Please enter a valid zip code',
        });
      }
    }

    canContinue();
  };

  useEffect(() => {
    canContinue();
  }, [errors]);

  return (
    <Grid container justifyContent={'space-between'} spacing={3} className='inputs-payment'>
      {inputs.map(({ label, field, size, inputType, name, track, component }) => (
        <Grid item xs={12} sm={size} key={`${field}-payment`}>
          {inputType === 'stripe' ? (
            <ElementTracker
              routePath={routePath}
              name={name}
              type={ElementTrackerType.Blurrable}
              value={!track ? SECRET_INPUT : undefined}>
              <StripeTextField
                id={`${field}-payment`}
                field={field}
                label={label}
                stripeElement={component}
                onFieldEmpty={validateForm}
              />
            </ElementTracker>
          ) : (
            <ElementTracker
              routePath={routePath}
              name={name}
              type={ElementTrackerType.Blurrable}
              value={!track ? SECRET_INPUT : undefined}>
              <TextField
                id={`${field}-payment`}
                label={label}
                value={form![field as keyof typeof form]}
                variant='filled'
                type={field === 'zipCode' ? 'number' : 'text'}
                autoComplete='invalidText' // To avoid autoComplete
                onChange={(e) => {
                  setForm({
                    ...form,
                    [field]: e.target.value,
                  });
                  validateForm(field);
                }}
                error={!!errors[field as keyof typeof errors]}
                onBlur={(e) => {
                  setForm({
                    ...form,
                    [field]: e.target.value,
                  });
                  validateForm(field);
                }}
                helperText={
                  !!errors[field as keyof typeof errors] ? errors[field as keyof typeof errors] : ''
                }
              />
            </ElementTracker>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default PaymentInputs;
