import { Clinic, Partner } from '@enaratech/funnel-helper';
import { Dispatch } from 'react';

export interface ClinicWithAvailablePartners extends Clinic {
  partners: Partner[];
}

export type ClinicState = ClinicWithAvailablePartners | null;

export type ClinicContextValue = {
  clinicState: ClinicState;
  dispatchClinic: Dispatch<ClinicActionTypes>;
};

export const SET_CLINIC_INFO = '@enara/types/set-clinic-info';

export type SetClinicInfoType = {
  type: typeof SET_CLINIC_INFO;
  payload: ClinicState;
};

export type ClinicActionTypes = SetClinicInfoType;
