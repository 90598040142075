import { STRIPE_PUBLIC_KEY } from '@enaratech/funnel-helper';
import { Stack, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Dispatch, FC, useState } from 'react';
import { PaymentMethodResult } from 'src/pages/Payment/Inputs/Payment.types';
import PaymentInputs from './Inputs/PaymentInputs';
import { PaymentForm } from './Inputs/input.types';
import { inputFields } from './Inputs/inputFields';

interface Props {
  formState: PaymentForm;
  setCanContinue: (value: boolean) => void;
  setFormState: Dispatch<React.SetStateAction<PaymentForm>>;
  setStripePaymentMethod: (paymentMethod: PaymentMethodResult) => void;
  user: { firstName: string; lastName: string };
}

const StripePayment: FC<Props> = ({
  formState,
  setCanContinue,
  setFormState,
  setStripePaymentMethod,
  user,
}) => {
  const [stripePromise] = useState(() => loadStripe(STRIPE_PUBLIC_KEY));

  const options = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Proxima+Nova',
      },
    ],
  };

  return (
    <Stack>
      <Typography variant={'h4'}>Payment Method</Typography>
      <Typography variant={'body2'} style={{ lineHeight: '28px' }}>
        You will only be charged after your first appointment with your care team.
      </Typography>
      <Elements stripe={stripePromise} options={options}>
        <PaymentInputs
          patient={`${user?.firstName} ${user?.lastName}`}
          inputs={inputFields}
          setCanContinue={setCanContinue}
          form={formState}
          setForm={setFormState}
          getPaymentMethod={setStripePaymentMethod}
        />
      </Elements>
    </Stack>
  );
};

export default StripePayment;
