import { StatsigUser } from 'statsig-js';

export const ENABLE_SSB_FROM_USER_ID = Number(process.env.REACT_APP_STATSIG_ENABLE_SSB_FROM_ID);

export const expandExperimentUser = (userId: number, experimentUser: StatsigUser): StatsigUser => {
  return {
    ...experimentUser,
    custom: {
      ...experimentUser.custom,
      userId,
      authorizedForSSB: `${userId > ENABLE_SSB_FROM_USER_ID}`,
    },
  };
};
