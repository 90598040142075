import { Box, Stack, Typography } from '@mui/material';
import { FC, HTMLAttributes } from 'react';
import theme from 'src/theme';
import './scss/coverage-card.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  title: string;
};

const CoverageCard: FC<Props> = ({ title, children, ...rest }) => {
  return (
    <Stack
      direction='column'
      className='coverage-card'
      borderRadius='.8rem'
      overflow='hidden'
      width='100%'
      {...rest}>
      <Box bgcolor={theme.palette.primary.light} padding={1}>
        <Typography variant='h4' className='title' color='white' fontSize={14} fontWeight={600}>
          {title}
        </Typography>
      </Box>
      <Box bgcolor={theme.palette.grey[100]} padding={1} flexGrow={1}>
        {children}
      </Box>
    </Stack>
  );
};

export default CoverageCard;
