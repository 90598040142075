import {
  AvailableLanguages,
  Clinic,
  clinicClient,
  ContactMethod,
  ElementTracker,
  ElementTrackerType,
  FUNNEL_VERSION,
  getBMI,
  isEmailValid,
  isHeightFeetValid,
  isHeightInchesValid,
  isValidPassword,
  isWeightLbsValid,
  LeadOnboardingStage,
  MembershipType,
  Metadata,
  MixpanelClient,
  MixpanelEvent,
  onboardingClient,
  PRIVACY_POLICY,
  ReferralLandingInfo,
  ReferralRawInfo,
  referralsClient,
  StatsigEventName,
  StatsigManager,
  TERMS_OF_USE,
  userClient,
} from '@enaratech/funnel-helper';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Agreements, { RefAgreements } from 'src/components/Common/Agreements/Agreements';
import ViewIcon from 'src/components/Common/Icons/ViewIcon';
import ViewIconOff from 'src/components/Common/Icons/ViewIconOff';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useExperiment } from 'src/contexts/experiments';
import { SET_USER_METADATA } from 'src/contexts/experiments/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { expandExperimentUser } from '../../../../lib/experiments';
import {
  CreateReferralAccountErrors,
  CreateReferralAccountForm,
} from './createReferralAccount.types';
import { FieldMinLength, inputFields, InputFieldsForBMI } from './inputFields';

type Props = {
  landingInfo: ReferralLandingInfo;
  rawInfo: ReferralRawInfo;
  dateOfBirth: string;
  onCreateReferralAccount: (membershipType?: MembershipType) => void;
};

const CreateReferralAccount: FC<Props> = ({
  landingInfo,
  rawInfo,
  dateOfBirth,
  onCreateReferralAccount,
}) => {
  const [formState, setFormState] = useState<CreateReferralAccountForm>({
    email: '',
    password: '',
    confirmPassword: '',
    heightFeet: '',
    heightInches: '',
    weightLbs: '',
  });
  const [errors, setErrors] = useState<Partial<CreateReferralAccountErrors> | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<{
    password: boolean;
    confirmPassword: boolean;
  }>({
    password: false,
    confirmPassword: false,
  });
  const [clinicInfo, setClinicInfo] = useState<Clinic | null>(null);
  const [agreementsLoaded, setAgreementsLoaded] = useState<boolean>(false);
  const [allAgreementsSelected, setAllAgreementsSelected] = useState<boolean>(false);
  const [isComputingBMI, setComputingBMI] = useState<boolean>(false);

  const agreementsRef = useRef<RefAgreements>(null);

  const routePath = useRoutePath();
  const navigate = useNavigate();
  const {
    experimentState: { isInitialized },
    dispatchExperimentState,
  } = useExperiment();

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
  }, [isInitialized]);

  useEffect(() => {
    const newState = { ...formState };

    if (rawInfo.email) {
      newState.email = rawInfo.email;
    }

    if (landingInfo.member.bmi) {
      setComputingBMI(false);
    } else {
      setComputingBMI(true);
    }

    setFormState(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawInfo.email, landingInfo.member.bmi]);

  useEffect(() => {
    (async () => {
      const { clinic } = rawInfo;

      try {
        const clinicInfo = await clinicClient.fetchClosestClinicByZipCode(clinic!.zipCode, true);
        setClinicInfo(clinicInfo);
      } catch (error) {
        Toast.notification('error', 'Could not resolve clinic information for zip code');
      }
    })();
  }, [rawInfo, navigate]);

  const validateForm = (field: string, value: string) => {
    const updatedFormState = { ...formState, [field]: value };

    if (!updatedFormState[field as keyof typeof updatedFormState]) {
      setErrors((prev) => ({
        ...prev,
        [field]: [
          `The ${
            field === 'confirmPassword' ? 'confirm password' : field.toLowerCase()
          } is required`,
        ],
      }));
    }

    if (updatedFormState[field as keyof typeof updatedFormState].length > 0) {
      setErrors((prev) => ({ ...prev, [field]: [] }));
    }

    if (field === InputFieldsForBMI.HeightFeet && !isHeightFeetValid(value)) {
      setErrors((prev) => ({ ...prev, [field]: ['Minimum must be 1 and maximum 10'] }));
    } else if (field === InputFieldsForBMI.HeightInches && !isHeightInchesValid(value)) {
      setErrors((prev) => ({ ...prev, [field]: ['Minimum must be 0 and maximum 11'] }));
    } else if (field === InputFieldsForBMI.WeightLbs && !isWeightLbsValid(value)) {
      setErrors((prev) => ({
        ...prev,
        [field]: ['Weight field minimum must be 10 and maximum 999'],
      }));
    }

    if (field === 'email' && !isEmailValid(updatedFormState.email)) {
      setErrors((prev) => ({ ...prev, [field]: ['Please enter a valid email'] }));
    }

    if (['password', 'confirmPassword'].includes(field)) {
      if (!isValidPassword(updatedFormState.password)) {
        setErrors((prev) => ({
          ...prev,
          password: [
            'A lowercase letter (a-z)',
            'An uppercase letter (A-Z)',
            'A Number (0-9)',
            'Being at least 8 characters',
          ],
        }));
      } else if (updatedFormState.confirmPassword.length > 0) {
        setErrors((prev) => ({
          ...prev,
          confirmPassword:
            updatedFormState.confirmPassword !== updatedFormState.password
              ? ['Passwords do not match']
              : [],
        }));
      }
    }

    setFormState(updatedFormState);
  };

  const handleSubmit = async () => {
    const { email, password } = formState;

    setIsSubmitting(true);

    if (!(await userClient.checkAvailability(email))) {
      Toast.notification('error', 'The email address is already in use');
      return setIsSubmitting(false);
    }

    const { details, program } = clinicInfo!;
    const { member, contactMethod: referralContactMethod } = landingInfo;

    const clinicId = details.clinicId;
    const systemSource = referralContactMethod || ContactMethod.PartnerReferral;

    const bmi = isComputingBMI
      ? getBMI({
          weight: Number(formState.weightLbs),
          feet: Number(formState.heightFeet),
          inches: Number(formState.heightInches),
        }).toString()
      : member.bmi!.toFixed(2);

    const user = await userClient.createReferralUser({
      city: details.city,
      clinicId: clinicId.toString(),
      contactMethod: systemSource,
      dateOfBirth,
      email,
      firstName: member.firstName,
      gender: member.gender,
      lastName: member.lastName,
      leadsquaredId: rawInfo.leadsquaredId!,
      password,
      phone: rawInfo.phone,
      salesforceId: rawInfo.salesforceId,
      state: details.state,
      uuid: rawInfo.uuid,
      zipCode: rawInfo.zipCode!,
      clinicZipCode: details.zipCode,
      programType: program,
      bmi: Number(bmi),
    });

    if (!user) {
      Toast.notification('error', 'User could not be created');

      MixpanelClient.trackEvent({
        event: MixpanelEvent.Show,
        properties: {
          field: 'Error message',
          value: 'Could not create user',
          source: routePath,
        },
      });

      return setIsSubmitting(false);
    }

    const linkedUser = await referralsClient.linkReferralToUser(user.id, {
      dateOfBirth,
      internalFullName: member.internalFullName,
    });

    const userMetadata = {
      email,
      firstName: member.firstName,
      lastName: member.lastName,
      programType: program,
      clinicId,
      referral: 'fax',
      ...(linkedUser?.eligibility?.membershipType
        ? { membershipType: linkedUser.eligibility.membershipType }
        : {}),
    };

    MixpanelClient.identityAlias(user.id);
    MixpanelClient.setMetadata({
      ...userMetadata,
      isNewMember: false,
      experiments: StatsigManager.getExperimentsSummary(),
    } as Partial<Metadata>);

    // Updating user for experiments dashboard
    StatsigManager.logEvent({
      eventName: StatsigEventName.AccountCreation,
      metadata: {
        ...userMetadata,
        clinicId: `${clinicId}`,
        memberId: `${user.id}`,
        memberUuid: user.uuid,
        systemSource,
      },
    });

    dispatchExperimentState({
      type: SET_USER_METADATA,
      payload: expandExperimentUser(user.id, {
        appVersion: FUNNEL_VERSION,
        custom: { ...userMetadata, systemSource },
        email: userMetadata.email,
        userID: user.uuid,
      }),
    });

    userClient.createDefaultProviders();

    const { phone, insurance } = linkedUser!;

    await userClient.updateUser({
      insuranceCompany: insurance.carrier,
      insuranceId: insurance.policyNumber,
      phone,
    });

    onboardingClient.updateMemberStatus({
      onboardingStage: LeadOnboardingStage.ReferralAccountCreation,
      firstLanguage: AvailableLanguages.English,
      groupId: insurance.groupNumber,
    });

    if (agreementsRef.current) {
      agreementsRef.current.signAgreements();
    }

    onCreateReferralAccount(userMetadata.membershipType);
  };

  return (
    <BasicLayout
      title={`Awesome, ${landingInfo.member.firstName}!`}
      subtitle='Let’s set up your account and check your insurance'
      buttonProps={{
        disabled:
          Object.entries(formState).some(([key, value]) => value.length < FieldMinLength[key]) ||
          (!!errors && Object.values(errors).some((i) => i.length > 0)) ||
          !clinicInfo ||
          !agreementsLoaded ||
          !allAgreementsSelected,
        loading: isSubmitting,
        onClick: handleSubmit,
      }}
      back>
      <form>
        {/** TODO: Separate this as a new component */}
        {isComputingBMI && (
          <Box sx={{ marginBottom: 2 }}>
            <Typography variant={'h4'}>What’s your height and weight?</Typography>
            <Typography paragraph variant={'h5'} className='typography-bmi'>
              (This calculates your BMI)
            </Typography>
            <Stack spacing='10px'>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing='10px'>
                <ElementTracker routePath={routePath} name='Height Feet'>
                  <TextField
                    id={InputFieldsForBMI.HeightFeet}
                    label={'ft.'}
                    variant='filled'
                    inputProps={{ placeholder: 'ft.' }}
                    value={formState.heightFeet || ''}
                    className='get-started-input'
                    onChange={(e) => validateForm(InputFieldsForBMI.HeightFeet, e.target.value)}
                    error={!!errors?.[InputFieldsForBMI.HeightFeet as keyof typeof errors]?.length}
                    helperText={
                      !!errors?.[InputFieldsForBMI.HeightFeet as keyof typeof errors] && (
                        <ul>
                          {(
                            Object.values(
                              errors[InputFieldsForBMI.HeightFeet as keyof typeof errors]!
                            ) as string[]
                          ).map((entry, i) => (
                            <li key={`referral-password-hint-${i}`}>{entry}</li>
                          ))}
                        </ul>
                      )
                    }
                  />
                </ElementTracker>
                <ElementTracker routePath={routePath} name='Height Inches'>
                  <TextField
                    id={InputFieldsForBMI.HeightInches}
                    label={'in.'}
                    variant='filled'
                    inputProps={{ placeholder: 'in.' }}
                    value={formState.heightInches || ''}
                    className='get-started-input'
                    onChange={(e) => validateForm(InputFieldsForBMI.HeightInches, e.target.value)}
                    error={
                      !!errors?.[InputFieldsForBMI.HeightInches as keyof typeof errors]?.length
                    }
                    helperText={
                      !!errors?.[InputFieldsForBMI.HeightInches as keyof typeof errors] && (
                        <ul>
                          {(
                            Object.values(
                              errors[InputFieldsForBMI.HeightInches as keyof typeof errors]!
                            ) as string[]
                          ).map((entry, i) => (
                            <li key={`referral-password-hint-${i}`}>{entry}</li>
                          ))}
                        </ul>
                      )
                    }
                  />
                </ElementTracker>
              </Stack>
              <ElementTracker routePath={routePath} name='Weight Lbs'>
                <TextField
                  id={InputFieldsForBMI.WeightLbs}
                  label={'lbs.'}
                  variant='filled'
                  value={formState.weightLbs || ''}
                  className='get-started-input'
                  inputProps={{ placeholder: 'lbs.' }}
                  onChange={(e) => validateForm(InputFieldsForBMI.WeightLbs, e.target.value)}
                  error={!!errors?.[InputFieldsForBMI.WeightLbs as keyof typeof errors]?.length}
                  helperText={
                    !!errors?.[InputFieldsForBMI.WeightLbs as keyof typeof errors] && (
                      <ul>
                        {(
                          Object.values(
                            errors[InputFieldsForBMI.WeightLbs as keyof typeof errors]!
                          ) as string[]
                        ).map((entry, i) => (
                          <li key={`referral-password-hint-${i}`}>{entry}</li>
                        ))}
                      </ul>
                    )
                  }
                />
              </ElementTracker>
            </Stack>
          </Box>
        )}

        {isComputingBMI && (
          <Box sx={{ marginTop: 2 }}>
            <Typography variant={'h4'}>Access info</Typography>
            <Typography paragraph variant={'h5'} className='typography-bmi'>
              (You will be able to access with these credentials)
            </Typography>
          </Box>
        )}

        <Grid container spacing={2}>
          {inputFields.map(({ label, field, name }) => (
            <Grid item xs={12} sm={6} key={`create-account-grid-${field}`}>
              <ElementTracker
                routePath={routePath}
                name={name}
                type={ElementTrackerType.Blurrable}
                value={
                  ['Confirm Password', 'Password'].includes(name)
                    ? !!errors?.[field as keyof typeof errors] === false
                    : undefined
                }>
                <TextField
                  data-test={`createAccount-input-${field}`}
                  name={name}
                  label={label}
                  fullWidth
                  variant='filled'
                  value={formState[field as keyof CreateReferralAccountForm]}
                  type={
                    !['confirmPassword', 'password'].includes(field)
                      ? 'text'
                      : showPassword[field as keyof typeof showPassword]
                      ? 'text'
                      : 'password'
                  }
                  // @ts-ignore,
                  FormHelperTextProps={{ component: 'span' }}
                  InputProps={{
                    endAdornment: ['confirmPassword', 'password'].includes(field) && (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              [field]: !showPassword[field as keyof typeof showPassword],
                            })
                          }>
                          {showPassword[field as keyof typeof showPassword] ? (
                            <ViewIcon />
                          ) : (
                            <ViewIconOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete={field}
                  onChange={(e) => validateForm(field, e.target.value)}
                  error={!!errors?.[field as keyof typeof errors]?.length}
                  helperText={
                    !!errors?.[field as keyof typeof errors] && (
                      <ul>
                        {(Object.values(errors[field as keyof typeof errors]!) as string[]).map(
                          (entry, i) => (
                            <li key={`referral-password-hint-${i}`}>{entry}</li>
                          )
                        )}
                      </ul>
                    )
                  }
                />
              </ElementTracker>
            </Grid>
          ))}
        </Grid>
      </form>

      <Typography variant='h6'>
        By clicking in Next, you agree to Enara Health{' '}
        <ElementTracker
          routePath={routePath}
          name='Link terms of use'
          type={ElementTrackerType.Clickable}>
          <Link id='term-of-use' href={TERMS_OF_USE} target='_blank'>
            <strong>Terms of Use</strong>
          </Link>
        </ElementTracker>{' '}
        and{' '}
        <ElementTracker
          routePath={routePath}
          name='Link privacy policy'
          type={ElementTrackerType.Clickable}>
          <Link id='privacy-policy' href={PRIVACY_POLICY} target='_blank'>
            <strong>Privacy Policy</strong>
          </Link>
        </ElementTracker>
      </Typography>

      <Box my={2}>
        <Agreements
          ref={agreementsRef}
          agreementsMode={'public'}
          onLoaded={useCallback(() => setAgreementsLoaded(true), [])}
          onSelect={useCallback((all) => setAllAgreementsSelected(all), [])}
        />
      </Box>
    </BasicLayout>
  );
};

export default CreateReferralAccount;
