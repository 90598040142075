import {
  ElementTracker,
  ElementTrackerType,
  PRIVACY_POLICY,
  TERMS_OF_USE,
} from '@enaratech/funnel-helper';
import { Link, Typography } from '@mui/material';
import { useRoutePath } from 'src/hooks/useRoutePath';

const TermsOfUse = () => {
  const routePath = useRoutePath();
  return (
    <>
      <Typography variant='h6'>
        By clicking in Next, you agree to Enara Health{' '}
        <ElementTracker
          routePath={routePath}
          name='Link terms of use'
          type={ElementTrackerType.Clickable}>
          <Link id='term-of-use' href={TERMS_OF_USE} target='_blank'>
            <strong>Terms of Use</strong>
          </Link>
        </ElementTracker>{' '}
        and{' '}
        <ElementTracker
          routePath={routePath}
          name='Link privacy policy'
          type={ElementTrackerType.Clickable}>
          <Link id='privacy-policy' href={PRIVACY_POLICY} target='_blank'>
            <strong>Privacy Policy</strong>
          </Link>
        </ElementTracker>
      </Typography>
    </>
  );
};

export default TermsOfUse;
