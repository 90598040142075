import { MixpanelClient, MixpanelEvent } from '@enaratech/funnel-helper';
import { FC } from 'react';
import { NavigateFunction, NavigateOptions } from 'react-router-dom';
import MemberSuccessBooking from './Appointments/MemberSuccess/MemberSuccessBooking';
import SelfServeBooking from './Appointments/SelfServeBooking/SelfServeBooking';
import Branding from './Branding/Branding';
import ComingCallResult from './Congratulations/ComingCallResult/ComingCallResult';
import MemberSuccessBookingResult from './Congratulations/MemberSuccess/MemberSuccessBookingResult';
import SelfServeBookingResult from './Congratulations/SelfServeBooking/SelfServeBookingResult';
import Coverage from './Coverage/Coverage';
import CreateAccount from './CreateAccount/CreateAccount';
import CustomAgreements from './CustomAgreements/CustomAgreements';
import DateOfBirth from './DOB/DateOfBirth';
import GetStarted from './GetStarted/GetStarted';
import LowBMI from './GetStarted/LowBMI';
import Goals from './Goals/Goals';
import IPOEligibilityCheckBooking from './IPO/Appointments/EligibilityCheck/EligibilityCheckBooking';
import SelfServeBookingForInPersonFlow from './IPO/Appointments/SelfServeBooking/SelfServeBookingForInPersonFlow';
import SelfServeBookingResultForInPersonFlow from './IPO/Congratulations/SelfServeBooking/SelfServeBookingResultForInPersonFlow';
import IPOCoverage from './IPO/Coverage/IPOCoverage';
import FAQ from './IPO/FAQ/FAQ';
import NewMemberProfile from './IPO/NewMemberProfile/NewMemberProfile';
import PaymentMethod from './IPO/PaymentMethod/PaymentMethod';
import PhoneVerification from './IPO/PhoneVerification/PhoneVerification';
import Welcome from './IPO/Welcome/Welcome';
import Insurance from './Insurance/Insurance';
import ManualInsurance from './Insurance/ManualInsurance';
import Login from './Login/Login';
import MagicLink from './MagicLink/MagicLink';
import NotAvailable from './Partners/NotAvailable';
import Partners from './Partners/Partners';
import Payment from './Payment/Payment';
import ForgotPassword from './RecoveryPassword/ForgotPassword';
import RecoveryPassword from './RecoveryPassword/RecoveryPassword';
import VerificationCodePassword from './RecoveryPassword/VerificationCodePassword';
import Referrals from './Referrals/Referrals';

type FunnelRoutes = {
  [path: string]: {
    Component: FC;
    goBack: {
      mainPath: string | null;
      secondaryPath?: string | null;
    };
  };
};

// IPO stands for In Person Onboarding
export enum IPOPath {
  Welcome = '/welcome',
  FAQ = '/faq',
  PaymentMethod = '/payment-method',
  PhoneVerification = '/phone-verification',
  NewMemberProfile = '/new-member-profile',
  ScheduleAppointments = '/schedule-appointments',
  ScheduleAppointmentsResult = '/schedule-appointments/result',
  IPOCoverage = '/ipo-coverage',
  IPOEligibilityCheckBooking = '/schedule-appointments/eligibility-check',
}

type IPORoutes = {
  [key in IPOPath]: {
    Component: FC;
    goBack: {
      mainPath: IPOPath | null;
      secondaryPath?: IPOPath | null;
    };
  };
};

export const ipoRoutes: IPORoutes = {
  [IPOPath.Welcome]: {
    Component: Welcome,
    goBack: { mainPath: null },
  },
  [IPOPath.FAQ]: {
    Component: FAQ,
    goBack: { mainPath: IPOPath.Welcome },
  },
  [IPOPath.PaymentMethod]: {
    Component: PaymentMethod,
    goBack: { mainPath: IPOPath.IPOCoverage },
  },
  [IPOPath.PhoneVerification]: {
    Component: PhoneVerification,
    goBack: { mainPath: IPOPath.Welcome },
  },
  [IPOPath.NewMemberProfile]: {
    Component: NewMemberProfile,
    goBack: { mainPath: IPOPath.FAQ },
  },
  [IPOPath.ScheduleAppointments]: {
    Component: SelfServeBookingForInPersonFlow,
    goBack: { mainPath: IPOPath.PaymentMethod },
  },
  [IPOPath.ScheduleAppointmentsResult]: {
    Component: SelfServeBookingResultForInPersonFlow,
    goBack: { mainPath: IPOPath.ScheduleAppointments },
  },
  [IPOPath.IPOCoverage]: {
    Component: IPOCoverage,
    goBack: { mainPath: null },
  },
  [IPOPath.IPOEligibilityCheckBooking]: {
    Component: IPOEligibilityCheckBooking,
    goBack: { mainPath: IPOPath.NewMemberProfile },
  },
};

export const funnelRoutes: FunnelRoutes = {
  '/goals': {
    Component: Goals,
    goBack: { mainPath: null },
  },
  '/get-started': {
    Component: GetStarted,
    goBack: { mainPath: '/goals' },
  },
  '/low-bmi': {
    Component: LowBMI,
    goBack: { mainPath: '/get-started' },
  },
  '/about': {
    Component: DateOfBirth,
    goBack: { mainPath: '/get-started' },
  },
  '/partners': {
    Component: Partners,
    goBack: { mainPath: '/about' },
  },
  '/not-available': {
    Component: NotAvailable,
    goBack: { mainPath: '/partners' },
  },
  '/create-account': {
    Component: CreateAccount,
    goBack: {
      mainPath: '/partners',
      secondaryPath: '/about',
    },
  },
  '/insurance': {
    Component: Insurance,
    goBack: { mainPath: null },
  },
  '/manual-insurance': {
    Component: ManualInsurance,
    goBack: { mainPath: '/insurance' },
  },
  '/coverage': {
    Component: Coverage,
    goBack: { mainPath: '/insurance' },
  },
  '/appointment/ms-booking': {
    Component: MemberSuccessBooking,
    goBack: { mainPath: null },
  },
  '/appointment/ms-booking/result': {
    Component: MemberSuccessBookingResult,
    goBack: { mainPath: null },
  },
  '/appointment/self-serve-booking': {
    Component: SelfServeBooking,
    goBack: { mainPath: null },
  },
  '/appointment/self-serve-booking/result': {
    Component: SelfServeBookingResult,
    goBack: { mainPath: null },
  },
  '/payment': {
    Component: Payment,
    goBack: { mainPath: '/coverage' },
  },
  '/login': {
    Component: Login,
    goBack: { mainPath: null },
  },
  '/forgot-password': {
    Component: ForgotPassword,
    goBack: { mainPath: '/login' },
  },
  '/verification-code': {
    Component: VerificationCodePassword,
    goBack: { mainPath: '/forgot-password' },
  },
  '/recovery-password': {
    Component: RecoveryPassword,
    goBack: { mainPath: '/verification-code' },
  },
  '/magic-link': {
    Component: MagicLink,
    goBack: { mainPath: null },
  },
  '/referrals': {
    Component: Referrals,
    goBack: { mainPath: null },
  },
  '/:partnerSlug': {
    Component: Branding,
    goBack: { mainPath: null },
  },
  '/custom-agreements': {
    Component: CustomAgreements,
    goBack: { mainPath: null },
  },
  'onboarding-call-result': {
    Component: ComingCallResult,
    goBack: { mainPath: null },
  },
};

export const allRoutes = { ...funnelRoutes, ...ipoRoutes };

export const getQueryParams = (): string => new URLSearchParams(window.location.search).toString();

export const goBackTo = ({
  pathname: rawPathName,
  useSecondaryPath,
  navigate,
}: {
  pathname: string;
  useSecondaryPath?: boolean;
  navigate: NavigateFunction;
}): void => {
  let pathname = rawPathName;
  if (rawPathName.endsWith('/')) {
    pathname = rawPathName.slice(0, rawPathName.length - 1);
  }

  const route = funnelRoutes[pathname] || ipoRoutes[pathname as IPOPath];

  if (route) {
    const {
      goBack: { mainPath, secondaryPath },
    } = route;

    let goBackPath = useSecondaryPath && secondaryPath ? secondaryPath : mainPath;

    if (goBackPath) {
      navigateToPage({ targetPage: goBackPath, options: { replace: true }, navigate });

      MixpanelClient.trackEvent({
        event: MixpanelEvent.Back,
        properties: { value: pathname },
      });
    } else {
      console.log(`Going back from this location ${pathname} is not allowed`);
    }
  } else {
    console.log(`Route not found for source location: ${pathname}`);
  }
};

export const navigateToPage = ({
  targetPage,
  options,
  navigate,
}: {
  targetPage: string;
  options?: NavigateOptions;
  navigate: NavigateFunction;
}) => {
  const queryParams = getQueryParams();

  const path =
    queryParams.length > 0 && !queryParams.includes(targetPage.split('?')[1])
      ? `${targetPage}${targetPage.includes('?') ? '&' : '?'}${queryParams}`
      : targetPage;

  navigate(path, options);
};

export const getAppDefaultPath = (): string => {
  const queryParams = getQueryParams();

  return '/goals' + (queryParams.length > 0 ? `?${queryParams}` : '');
};

// TODO: Delete this in favor of any user prop (member status) that provides the same info
export const isIPOPath = (path: string) => {
  return Object.keys(ipoRoutes).includes(path.split('?')[0]);
};
