import { createContext, FC, ReactElement, ReactNode, useContext, useState } from 'react';

type AppContextValue = {
  canNavigateBetweenPages: boolean;
  shouldSkipPartnerPage: boolean;
  allowUserToNavigateBetweenPages: () => void;
  lockNavigationBetweenPages: () => void;
  changeSkipPartnerPageStatus: (skip: boolean) => void;
};

const AppContext = createContext<AppContextValue | undefined>(undefined);

export const AppProvider: FC<{ children: ReactNode }> = ({ children }): ReactElement => {
  const [canNavigateBetweenPages, setCanNavigateBetweenPages] = useState<boolean>(true);
  const [shouldSkipPartnerPage, setShouldSkipPartnerPage] = useState<boolean>(false);

  const allowUserToNavigateBetweenPages = (): void => {
    setCanNavigateBetweenPages(false);
  };

  const lockNavigationBetweenPages = (): void => {
    setCanNavigateBetweenPages(true);
  };

  const changeSkipPartnerPageStatus = (skip: boolean): void => {
    setShouldSkipPartnerPage(skip);
  };

  return (
    <AppContext.Provider
      value={{
        canNavigateBetweenPages,
        shouldSkipPartnerPage,
        allowUserToNavigateBetweenPages,
        lockNavigationBetweenPages,
        changeSkipPartnerPageStatus,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = (): AppContextValue => {
  const appContext = useContext(AppContext);

  if (!appContext) {
    throw new Error('useAuth must be used within a AppContext.Provider');
  }

  return appContext;
};
