import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckCircleIcon = (props: SvgIconProps) => (
  <SvgIcon style={{ width: 29, height: 29 }} viewBox='0 0 26 31' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.4993 0.666504C6.13535 0.666504 0.166016 6.63584 0.166016 13.9998C0.166016 21.3638 6.13535 27.3332 13.4993 27.3332C20.8633 27.3332 26.8327 21.3638 26.8327 13.9998C26.8327 6.63584 20.8633 0.666504 13.4993 0.666504ZM21.2994 9.79984L10.3793 20.6665L5.71268 15.9998C5.59135 15.8785 5.51668 15.7118 5.51668 15.5265C5.51668 15.1572 5.81668 14.8572 6.18602 14.8572C6.37135 14.8572 6.53802 14.9318 6.65935 15.0532L10.4193 18.8132L20.3793 8.85317C20.5007 8.73184 20.6673 8.65717 20.8527 8.65717C21.222 8.65717 21.522 8.95717 21.522 9.3265C21.522 9.51184 21.4473 9.6785 21.326 9.79984H21.2994Z'
      fill='#278BA5'
    />
  </SvgIcon>
);

export default CheckCircleIcon;
