import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EnaraLogoIcon = (props: SvgIconProps) => (
  <SvgIcon style={{ fontSize: '6rem', height: '1.5rem' }} viewBox='0 0 100 25' {...props}>
    <path
      d='M35.8948 22.5326V15.0032C35.8948 12.4515 33.9055 10.374 31.1266 10.374C28.3478 10.374 26.3585 12.4515 26.3585 15.0032V22.5326H23.1924V15.0032C23.1924 10.8481 26.6015 7.4668 31.1266 7.4668C35.6518 7.4668 39.0609 10.8481 39.0609 15.0032V22.5326H35.8948Z'
      fill='#278BA5'
    />
    <path
      d='M54.1246 22.5326V20.964L53.6386 21.2637C52.2872 22.0934 50.7155 22.5326 49.0831 22.5326C44.5579 22.5326 40.8755 19.1513 40.8755 15.0032C40.8755 10.855 44.5655 7.4668 49.0907 7.4668C53.5172 7.4668 57.116 10.6459 57.2831 14.7104L57.2907 14.9056V22.5396H54.1246V22.5326ZM49.0907 10.374C46.3118 10.374 44.0492 12.4515 44.0492 15.0032C44.0492 17.5548 46.3118 19.6324 49.0907 19.6324C51.8696 19.6324 54.1322 17.5548 54.1322 15.0032C54.1246 12.4515 51.8696 10.374 49.0907 10.374Z'
      fill='#278BA5'
    />
    <path
      d='M83.0977 22.5326V20.964L82.6118 21.2637C81.2603 22.0934 79.6886 22.5326 78.0562 22.5326C73.531 22.5326 69.8486 19.1513 69.8486 15.0032C69.8486 10.855 73.5386 7.4668 78.0562 7.4668C82.4827 7.4668 86.0816 10.6459 86.2486 14.7104L86.2562 14.9056V22.5396H83.0977V22.5326ZM78.0562 10.374C75.2773 10.374 73.0147 12.4515 73.0147 15.0032C73.0147 17.5548 75.2773 19.6324 78.0562 19.6324C80.8351 19.6324 83.0977 17.5548 83.0977 15.0032C83.0977 12.4515 80.8351 10.374 78.0562 10.374Z'
      fill='#278BA5'
    />
    <path
      d='M67.3358 7.4668H67.3131C62.7879 7.4668 59.1055 10.8481 59.1055 15.0032V22.5326H62.2716V15.0032C62.2716 12.4515 64.5342 10.374 67.3131 10.374H67.3358H69.5377V7.4668H67.3358Z'
      fill='#278BA5'
    />
    <path
      d='M18.0444 17.186C17.475 18.162 16.5487 18.9149 15.4098 19.3193C13.542 19.9816 11.4237 19.5563 10.019 18.2526L9.68496 17.9389L21.8331 13.6583L21.2637 12.2988C19.6389 8.42256 14.8859 6.47746 10.6644 7.96243C6.44292 9.45436 4.32459 13.8186 5.94941 17.6949C7.57422 21.5711 12.3272 23.5162 16.5487 22.0243C18.3481 21.3899 19.8135 20.2117 20.7398 18.7058L18.0444 17.186ZM8.57644 14.7877C8.66755 12.9541 9.93552 11.3367 11.8033 10.6814C13.6711 10.0191 15.7894 10.4443 17.194 11.748L17.5281 12.0618L8.55367 15.2269L8.57644 14.7877Z'
      fill='#278BA5'
    />
  </SvgIcon>
);

export default EnaraLogoIcon;
