import { ProgramGoals } from '@enaratech/funnel-helper';

export const inputFields = [
  {
    goal: ProgramGoals.LoseWeight,
    label: 'Lose weight',
  },
  {
    goal: ProgramGoals.GetStronger,
    label: 'Feel stronger',
  },
  {
    goal: ProgramGoals.ReverseType2Diabetes,
    label: 'Reverse type 2 diabetes',
  },
  {
    goal: ProgramGoals.DecreaseCholesterol,
    label: 'Decrease high cholesterol / hypertension',
  },
  {
    goal: ProgramGoals.HealthyHabits,
    label: 'Develop healthy habits',
  },
];
