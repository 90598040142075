import {
  GenderOption,
  getAnonymousLeadProgress,
  getAnonymousProgress,
  InputDateOfBirth,
  isValidDateOfBirth,
  onboardingClient,
  ProgressKey,
  storeAnonymousProgress,
} from '@enaratech/funnel-helper';
import { Box, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'src/components/Common/Inputs/DatePicker/DatePicker';
import RadioButtons from 'src/components/Common/Inputs/RadioButtons/RadioButtons';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useApp } from 'src/contexts/app';
import { navigateToPage } from '../routes';
import './scss/dateOfBirth.scss';

// TODO: Ask backend for available genders

export const GENDER_OPTIONS = [
  { value: GenderOption.Female, label: 'Female' },
  { value: GenderOption.Male, label: 'Male' },
  { value: GenderOption.PreferNotToSay, label: 'Prefer not to say' },
];

const DateOfBirth: FC = () => {
  const [dob, setDob] = useState<InputDateOfBirth>({ value: null });
  const [gender, setGender] = useState<string | null>(null);

  const { shouldSkipPartnerPage } = useApp();

  const navigate = useNavigate();

  useEffect(() => {
    const anonymousProgress = getAnonymousProgress();

    if (anonymousProgress) {
      const form = anonymousProgress[ProgressKey.DateOfBirth];

      if (form) {
        setDob({ value: DateTime.fromISO(form.dateOfBirth) });
        setGender(form.gender);
      }
    }
  }, []);

  const handleChangeDob = (date: DateTime | null) => {
    setDob({ value: date });
  };

  const handleChangeGender = (gender: string) => {
    if (GENDER_OPTIONS.find(({ value }) => value === gender)) {
      setGender(gender);
    }
  };

  const handleClick = (): void => {
    storeAnonymousProgress(ProgressKey.DateOfBirth, {
      dateOfBirth: dob.value,
      gender,
    });

    const leadData = getAnonymousLeadProgress();
    if (leadData) {
      onboardingClient.upsertLead(leadData);
    }

    if (shouldSkipPartnerPage) {
      navigateToPage({ targetPage: '/create-account', navigate });
    } else {
      navigateToPage({ targetPage: '/partners', navigate });
    }
  };

  const isNextEnabled = useMemo(() => !!dob.value && !dob.error && !!gender, [dob, gender]);

  // Validation
  useEffect(() => {
    if (dob.value) {
      const isDobValid = isValidDateOfBirth(dob.value);

      if (isDobValid === 'invalid') {
        setDob((prevDob) => ({
          ...prevDob,
          error: true,
          errorMessage: 'Please enter a valid date MM/DD/YYYY',
        }));
      }
      if (isDobValid === 'below-accepted') {
        setDob((prevDob) => ({
          ...prevDob,
          error: true,
          errorMessage: 'Oops! We are currently not accepting any members below the age of 16',
        }));
      }
    } else {
      setDob((prevDob) => ({ ...prevDob, error: false, errorMessage: undefined }));
    }
  }, [dob.value]);

  return (
    <BasicLayout
      title={'Great!'}
      subtitle={'We need to learn a little bit about you to see if you are insurance covered'}
      buttonProps={{ disabled: !isNextEnabled, onClick: handleClick }}
      back>
      <Stack spacing='24px'>
        <Box>
          <Typography paragraph variant='h4'>
            When is your birthday? (DOB)
          </Typography>
          <DatePicker
            error={dob.error}
            errorMessage={dob.errorMessage}
            onChange={handleChangeDob}
            value={dob.value}
          />
        </Box>
        <Box className='dob-container'>
          <Typography paragraph variant='h4'>
            What sex were you assigned at birth, as shown on your original birth certificate?
          </Typography>
          <Typography paragraph variant='h5'>
            (We require this for medical purposes only)
          </Typography>
          <RadioButtons
            name='Gender'
            id='dob-gender'
            options={GENDER_OPTIONS}
            value={gender}
            onChange={(_, value) => handleChangeGender(value)}
          />
        </Box>
      </Stack>
    </BasicLayout>
  );
};

export default withProgress(DateOfBirth, Page.DateOfBirth);
