import { ArrowExpandIcon, ElementTracker, ElementTrackerType } from '@enaratech/funnel-helper';
import { Box, Stack, Typography } from '@mui/material';
import { FC, HTMLAttributes, useState } from 'react';
import { useRoutePath } from 'src/hooks/useRoutePath';

type Props = HTMLAttributes<HTMLDivElement> & {
  text: string;
  fontSize?: number;
};

const Dropdown: FC<Props> = ({ text, fontSize, children, ...rest }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const routePath = useRoutePath();

  return (
    <Stack {...rest} borderBottom='1px solid #eee'>
      <Box display='flex' justifyContent='space-between' alignItems='center' paddingY={1}>
        <Typography variant='body1' fontSize={fontSize || 20}>
          {text}
        </Typography>
        <ElementTracker type={ElementTrackerType.Clickable} name={text} routePath={routePath}>
          <ArrowExpandIcon
            cursor='pointer'
            onClick={() => setIsOpen((prev) => !prev)}
            sx={{ transform: isOpen ? 'rotate(180deg)' : '' }}
          />
        </ElementTracker>
      </Box>
      {isOpen && <Box paddingY={1}>{children}</Box>}
    </Stack>
  );
};

export default Dropdown;
