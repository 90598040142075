export enum SessionKey {
  Email = 'email',
  CustomActivitySSB = 'custom-activity-ssb',
}

export const storeEmailInSession = (email?: string) => {
  if (!email) {
    return;
  }

  sessionStorage.setItem(SessionKey.Email, email);
};

export const getEmailFromSession = () => {
  return sessionStorage.getItem(SessionKey.Email);
};
