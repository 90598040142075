import {
  getAnonymousProgress,
  ProgramGoals,
  ProgressKey,
  storeAnonymousProgress,
} from '@enaratech/funnel-helper';
import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Checkbox from 'src/components/Common/Inputs/Checkbox/Checkbox';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { navigateToPage } from '../routes';
import { inputFields } from './inputFields';
import './scss/goals.scss';

const Goals: FC = () => {
  const [goals, setGoals] = useState<ProgramGoals[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const anonymousProgress = getAnonymousProgress();

    if (anonymousProgress) {
      setGoals(anonymousProgress[ProgressKey.ProgramGoals] || []);
    }
  }, []);

  const getCheckboxProps = ({ goal, label }: { goal: ProgramGoals; label: string }) => ({
    id: goal,
    label,
    defaultChecked: goals.some((g) => g === goal),
    onCheck: handleGoalSelection,
  });

  const handleGoalSelection = (id: string, selected: boolean): void => {
    if (selected) {
      setGoals([...goals, id as ProgramGoals]);
    } else {
      setGoals([...goals.filter((p) => p !== id)]);
    }
  };

  const handleClick = (): void => {
    storeAnonymousProgress(ProgressKey.ProgramGoals, goals);

    navigateToPage({ targetPage: '/get-started', navigate });
  };

  return (
    <BasicLayout
      title='Welcome to Enara Health!'
      subtitle='Get ready to start your personalized weight loss program'
      buttonProps={{ disabled: !(goals.length > 0), onClick: handleClick }}>
      <Typography variant={'body2'}>What are your health goals? (Select all that apply)</Typography>
      {inputFields.map(({ goal, label }) => (
        <Checkbox key={goal} {...getCheckboxProps({ goal, label })} />
      ))}
    </BasicLayout>
  );
};

export default withProgress(Goals, Page.Goals);
