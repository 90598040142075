import { InsuranceOutcome, User } from '@enaratech/funnel-helper';
import { Dispatch } from 'react';

export type AuthContextValue = {
  authState: AuthState;
  dispatchAuth: Dispatch<AuthActionTypes>;
};

export type AuthState = {
  loaded: boolean;
  isReferral: boolean;
  user: User | null;
  uuid: string;
};

export const CLEAR_AUTH_INFO = '@enara/types/clear-auth-info';
export const SET_USER_UUID = '@enara/types/set-user-uuid';
export const SET_AUTH_INFO = '@enara/types/set-auth-info';
export const SET_USER_INSURANCE_COMPANY = '@enara/types/set-user-insurance-company';
export const SET_HINT_PATIENT_ID = '@enara/types/set-hint-patient-id';
export const SET_INITIAL_APPOINTMENT_ID = '@enara/types/set-initial-appointment-id';
export const SET_USER_INSURANCE_OUTCOME = '@enara/types/set-user-no-match-status';

export type ClearAuthInfoType = {
  type: typeof CLEAR_AUTH_INFO;
  payload?: undefined;
};

export type SetUuidType = {
  type: typeof SET_USER_UUID;
  payload: string;
};

export type SetAuthInfoType = {
  type: typeof SET_AUTH_INFO;
  payload: {
    loaded: boolean;
    isReferral: boolean;
    user: User | null;
  };
};

export type SetUserInsuranceCompanyType = {
  type: typeof SET_USER_INSURANCE_COMPANY;
  payload: string;
};

export type SetUserInsuranceOutcomeType = {
  type: typeof SET_USER_INSURANCE_OUTCOME;
  payload: InsuranceOutcome;
};

export type SetHintPatientIdType = {
  type: typeof SET_HINT_PATIENT_ID;
  payload: string;
};

export type SetInitialAppointmentIdType = {
  type: typeof SET_INITIAL_APPOINTMENT_ID;
  payload: number;
};

export type AuthActionTypes =
  | ClearAuthInfoType
  | SetUuidType
  | SetAuthInfoType
  | SetUserInsuranceCompanyType
  | SetUserInsuranceOutcomeType
  | SetHintPatientIdType
  | SetInitialAppointmentIdType;
