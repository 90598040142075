const LocalCacheObject: {
  [key: string]: undefined | { expiresAt: number; isLoaded: boolean; promise: Promise<any> };
} = {};

const MINUTES_IN_MILLISECONDS = 60 * 1000;

// In minutes
export enum CacheDuration {
  VeryShort = 2,
  Short = 5,
}

const cleanExpiredItems = () => {
  for (const [key, cachedObject] of Object.entries(LocalCacheObject)) {
    if (!cachedObject) {
      continue;
    }

    if (!cachedObject.isLoaded) {
      // Keeping this ref, still waiting for promise
      continue;
    }

    const remainingTime = cachedObject.expiresAt - new Date().getTime();
    if (remainingTime <= 0) {
      // Expired and solved item, can and should be removed
      delete LocalCacheObject[key];
    }
  }
};

export const withCache = async <O>(
  cacheKey: string,
  callback: () => Promise<O>,
  cacheDuration = CacheDuration.VeryShort
) => {
  cleanExpiredItems();

  let cachedObject = LocalCacheObject[cacheKey] || null;
  const isLoaded = cachedObject && cachedObject.isLoaded;

  if (isLoaded) {
    // Object found in local cache
    return (await cachedObject!.promise) as O;
  }

  if (!cachedObject) {
    // Loading new object
    LocalCacheObject[cacheKey] = {
      isLoaded: false,
      promise: callback(),
      expiresAt: new Date().getTime() + cacheDuration * MINUTES_IN_MILLISECONDS,
    };
  }

  const newObject = await LocalCacheObject[cacheKey]!.promise;

  if (!!newObject) {
    // Wait ended, returning cached object
    LocalCacheObject[cacheKey]!.isLoaded = true;
  }

  return newObject as O;
};
