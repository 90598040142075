import {
  ElementTracker,
  ElementTrackerType,
  MixpanelClient,
  inferMembershipType,
  isPhoneValid,
  onboardingClient,
  storeAuth,
  userClient,
} from '@enaratech/funnel-helper';
import { Box, Stack, TextField } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, withProgress } from 'src/components/Common/Progress/IPO/IPOProgress';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useRoutePath } from 'src/hooks/useRoutePath';
import {
  NewMemberProfileStorageForm,
  PhoneVerificationStorageForm,
  ProgressKey,
  getProgress,
  storeProgress,
} from 'src/lib/IPOStorage';
import { IPOPath, navigateToPage } from '../../routes';
import './scss/phoneVerification.scss';

export type PhoneVerificationForm = {
  phone: string;
};

enum InputFields {
  Phone = 'phone',
}

const PhoneVerification: FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<PhoneVerificationForm>({
    phone: '',
  });
  const [errors, setErrors] = useState<NewMemberProfileStorageForm | {}>({});
  const routePath = useRoutePath();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e.target.id;
    const value = e.target.value;
    let errorMessage = '';

    if (!value) {
      errorMessage = 'This field is required';
    } else if (field === InputFields.Phone) {
      errorMessage = !isPhoneValid(value) ? 'Phone number is not valid' : '';
    }

    setErrors({ ...errors, [field]: errorMessage });
    setFormState({ ...formState, [field]: value.replace(/\D/g, '') });
  };

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true);
    storeProgress(ProgressKey.PhoneVerification, formState);

    const data = await onboardingClient.getLeadInfoByPhone(formState.phone);

    if (!data) {
      Toast.notification('error', 'Verification code does not match!');

      return setIsLoading(false);
    }

    const updatedData = {
      city: data.city ?? '',
      dateOfBirth: data.dateOfBirth ?? '',
      email: data.email ?? '',
      firstName: data.firstName ?? '',
      gender: data.gender ?? '',
      groupNumber: data.groupNumber ?? '',
      heightFeet: data.feet?.toString() ?? '',
      heightInches: data.inches?.toString() ?? '',
      insuranceCompany: data.payer ?? '',
      insuranceId: data.insuranceId ?? '',
      insuranceType: data.insurancePlanType ?? '',
      lastName: data.lastName ?? '',
      membershipType: data.coverageType ? inferMembershipType(data.coverageType) : null,
      phone: formState.phone,
      preferredLanguage: data.preferredLanguage ?? '',
      referringProvider: data.referredBy ?? '',
      state: data.state ?? '',
      street: data.street ?? '',
      weightLbs: data.weight?.toString() ?? '',
      zipCode: data.zipCode ?? '',
    };

    if (!(await userClient.checkAvailability(formState.phone))) {
      // The account already exists. Then we have to login
      const loginResponse = await onboardingClient.leadLoginWithoutPassword(formState.phone);
      if (loginResponse) {
        /**
         * Successful login means that we don't have to create the user again but update it in
         * core instead when submitting the new member form
         **/
        storeAuth({ id: loginResponse.userId, token: loginResponse.authToken });
        storeProgress<PhoneVerificationStorageForm>(ProgressKey.PhoneVerification, {
          ...formState,
          leadRegisteredInCore: true,
        });

        MixpanelClient.identifyUser(loginResponse.userId);
        MixpanelClient.setMetadata({ contactMethod: data.contactMethod });
      } else {
        Toast.notification('error', 'The phone number is not associated with a lead');
        setIsLoading(false);
        return;
      }
    }

    storeProgress<NewMemberProfileStorageForm>(ProgressKey.NewMemberProfile, updatedData);
    navigateToPage({ targetPage: IPOPath.FAQ, navigate });
  };

  useEffect(() => {
    const progress = getProgress();

    if (progress) {
      const form = progress[ProgressKey.PhoneVerification];

      setFormState({
        phone: form?.phone ?? '',
      });

      storeProgress(ProgressKey.PhoneVerification, {
        ...progress[ProgressKey.PhoneVerification],
        leadRegisteredInCore: false,
      });
    }
  }, []);

  return (
    <BasicLayout
      title='Enter Verification Code (Phone Number) to Continue Onboarding'
      buttonProps={{
        disabled:
          Object.values(formState).some((p) => !p) ||
          Object.values(errors).some((i) => i && i.length >= 0),

        loading: isLoading,
        onClick: handleSubmit,
      }}
      back>
      <Stack spacing={2}>
        <Box>
          <ElementTracker
            routePath={routePath}
            name='Enter Verification Code (Phone Number)'
            type={ElementTrackerType.Blurrable}>
            <TextField
              id='phone'
              variant='filled'
              type={'text'}
              name='phone'
              value={formState.phone || ''}
              className='verification-code'
              onChange={handleChange}
              error={!!errors[InputFields.Phone as keyof typeof errors]}
              helperText={
                !!errors[InputFields.Phone as keyof typeof errors]
                  ? errors[InputFields.Phone as keyof typeof errors]
                  : ''
              }
            />
          </ElementTracker>
        </Box>
      </Stack>
    </BasicLayout>
  );
};

export default withProgress(PhoneVerification, Page.PhoneVerification);
