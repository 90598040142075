import { ReferralMethod } from '@enaratech/funnel-helper';

type ReferralOption = {
  label: string;
  value: ReferralMethod;
};

const REFERRAL_METHODS: ReferralOption[] = [
  { value: ReferralMethod.Google, label: 'Search on Google' },
  { value: ReferralMethod.Facebook, label: 'on Facebook' },
  { value: ReferralMethod.Instagram, label: 'on Instagram' },
  { value: ReferralMethod.Yelp, label: 'on Yelp' },
  { value: ReferralMethod.Event, label: 'At an Event' },
  { value: ReferralMethod.Email, label: 'From an Email' },
  { value: ReferralMethod.FriendReferral, label: 'From a Friend' },
  { value: ReferralMethod.DoctorReferral, label: 'From another doctor' },
  { value: ReferralMethod.Mankato, label: 'From my doctor at Mankato' },
  { value: ReferralMethod.PhoenixHeart, label: 'From my doctor at Phoenix Heart' },
  { value: ReferralMethod.Kafri, label: 'From my doctor at Kafri' },
  { value: ReferralMethod.Other, label: 'Other' },
];

export const getReferralMethods = (): ReferralOption[] => {
  return REFERRAL_METHODS;
};
