type FAQType = {
  question: string;
  answers: {
    title: string;
    phrases: { phrase: string; items: string[] }[];
  }[];
}[];

export const FAQ_DETAILS: FAQType = [
  {
    question: 'What is Enara?',
    answers: [
      {
        title: 'English',
        phrases: [
          {
            phrase:
              'Enara Health is a health and weight loss program, based on a medical team and an app for your smartphone.',
            items: [],
          },
          {
            phrase:
              'It needs a 1-year commitment and helps you make lasting life changes so you can maintain your weight loss.',
            items: [],
          },
          {
            phrase:
              'Our app is a great tool for support, education and keeping track of your progress during your journey with Enara Health.',
            items: [],
          },
        ],
      },
      {
        title: 'Spanish',
        phrases: [
          {
            phrase:
              'Enara Health es un programa de salud y pérdida de peso basado en un equipo médico y una aplicación para tu teléfono inteligente.',
            items: [],
          },
          {
            phrase:
              'Requiere un compromiso de 1 año y te ayuda a hacer cambios duraderos en tu vida para que puedas mantener tu pérdida de peso.',
            items: [],
          },
          {
            phrase:
              'Nuestra aplicación es una excelente herramienta para brindar apoyo, educación y llevar un seguimiento de tu progreso durante tu viaje con Enara Health.',
            items: [],
          },
        ],
      },
    ],
  },

  {
    question: 'How are we different?',
    answers: [
      {
        title: 'English',
        phrases: [
          {
            phrase:
              'We aim to stop the obesity problem by changing how healthcare helps with weight loss. ',
            items: [],
          },
          {
            phrase:
              'We help our members to finally take control of their health with confidence, dignity and pride.',
            items: [],
          },
          {
            phrase:
              'We do this by giving low-cost access to a personalized team of caring providers, easy-to-use technology, and scientifically-proven methods that really work.',
            items: [],
          },
        ],
      },
      {
        title: 'Spanish',
        phrases: [
          {
            phrase:
              'Nuestro objetivo es detener el problema de la obesidad cambiando la forma en que la atención médica ayuda con la pérdida de peso.',
            items: [],
          },
          {
            phrase:
              'Ayudamos a nuestros miembros a tomar el control de su salud con confianza, dignidad y orgullo.',
            items: [],
          },
          {
            phrase:
              'Lo hacemos ofreciendo acceso a bajo costo a un equipo personalizado de profesionales comprensivos, tecnología fácil de usar y métodos científicamente probados que realmente funcionan.',
            items: [],
          },
        ],
      },
    ],
  },
  {
    question: 'Why join us?',
    answers: [
      {
        title: 'English',
        phrases: [
          {
            phrase:
              'When you join Enara, we begin by understanding all the unique factors about you that influence your weight.',
            items: [],
          },
          {
            phrase:
              'Together, you and your Medical Provider will build a personalized plan just for you.',
            items: [],
          },
        ],
      },
      {
        title: 'Spanish',
        phrases: [
          {
            phrase:
              'Cuando te unes a Enara, comenzamos comprendiendo todos los factores únicos que influyen en tu peso.',
            items: [],
          },
          {
            phrase: 'Juntos, tú y tu proveedor médico crearán un plan personalizado solo para ti.',
            items: [],
          },
        ],
      },
    ],
  },
  {
    question: 'How do we do it?',
    answers: [
      {
        title: 'English',
        phrases: [
          {
            phrase:
              "You'll receive a digital scale at your presential body-composition appointment.",
            items: [],
          },
          {
            phrase: 'When you step on it, it records and syncs your information to the app.',
            items: [],
          },
          {
            phrase:
              'In the app you and your care team can track your progress and see how you’re doing.',
            items: [],
          },
          {
            phrase:
              "You'll meet your care team in video calls through the app, which makes it easy and convenient.",
            items: [],
          },
          {
            phrase: 'Your personalized care team includes:',
            items: [
              'a medical provider (doctor, nurse practitioner, or physician assistant) who you will talk once a month,',
              'a nutrition specialist who you will meet with once a week, and',
              'an exercise specialist you will meet with once a month.',
              'If you need, you have direct access to Member Support, Tech Support, and Billing Support via in-app chat.',
            ],
          },
        ],
      },
      {
        title: 'Spanish',
        phrases: [
          {
            phrase: 'Recibirás una pesa digital en tu cita presencial de composición corporal.',
            items: [],
          },
          {
            phrase:
              'Cuando te subas a la pesa, esta registrará y sincronizará tu información con la aplicación.',
            items: [],
          },
          {
            phrase:
              'En la aplicación, tú y tu equipo de atención podrán seguir tu progreso y ver cómo te va.',
            items: [],
          },
          {
            phrase:
              'Te reunirás con tu equipo de atención en videollamadas a través de la aplicación, lo que facilita y agiliza el proceso.',
            items: [],
          },
          {
            phrase: 'Tu equipo de atención personalizado incluye:',
            items: [
              'un proveedor médico (médico, enfermero/a practicante o asistente médico) con quien hablarás una vez al mes,',
              'un especialista en nutrición con quien te reunirás una vez a la semana, y',
              'un especialista en ejercicio con quien te reunirás una vez al mes.',
              'Si necesitas, tienes acceso directo al Soporte para Miembros, Soporte Técnico y Soporte de Facturación a través del chat en la aplicación.',
            ],
          },
        ],
      },
    ],
  },
  {
    question: 'Most Common FAQs',
    answers: [
      {
        title: 'Who is included in my care team?',
        phrases: [
          {
            phrase: 'You will be matched with:',
            items: [
              'a Medical Provider,',
              'A Nutrition Specialist, and',
              'An Exercise Specialist.',
              'You may also have access to behavioral specialists.',
            ],
          },
        ],
      },
      {
        title: 'Who is eligible to join Enara?',
        phrases: [
          {
            phrase:
              'Enara is a medical weight loss program for adults with a BMI greater than 25. ',
            items: [],
          },
          {
            phrase:
              'Patients with a Medicare or Medi-cal insurance plan must have a BMI greater than 30.',
            items: [],
          },
        ],
      },
      {
        title: 'What insurance companies are covered?',
        phrases: [
          {
            phrase:
              'We are in-network with most PPO insurance plans, including Blue Cross Blue Shield, Anthem Blue Cross, Cigna, United Healthcare, and many more.',
            items: [],
          },
          {
            phrase: 'Our program is also covered by Medicare.',
            items: [],
          },
          {
            phrase: 'Insurance providers may vary per location.',
            items: [],
          },
        ],
      },
      {
        title: 'How long will it take to start seeing results?',
        phrases: [
          {
            phrase: 'Members start to see results around the 30-60 day mark.',
            items: [],
          },
          {
            phrase:
              'However everyone is different and you should not be discouraged if it takes a little longer.',
            items: [],
          },
        ],
      },
      {
        title: 'What happens if I want to cancel?',
        phrases: [
          {
            phrase:
              'We offer a 30-day cancellation period to try out the program and make sure it is the right time and fit for you.',
            items: [],
          },
          {
            phrase:
              'Should you decide the program is not a fit during your first 30 days, you won’t have to pay our early termination fee.',
            items: [],
          },
          {
            phrase:
              'Our Billing and Member Services teams are always available and they can assist you if you decide to cancel.',
            items: [],
          },
        ],
      },
    ],
  },
];
