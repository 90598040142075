import {
  ElementTracker,
  ElementTrackerType,
  FUNNEL_VERSION,
  FeatureManager,
  FunnelErrors,
  LoginUserPayload,
  MixpanelClient,
  MixpanelEvent,
  coreClient,
  deleteAuth,
  getPartners,
  isEmailValid,
  isFunnelError,
  userClient,
} from '@enaratech/funnel-helper';
import {
  Button,
  CircularProgress,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, FC, FormEvent, ReactElement, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ViewIcon from 'src/components/Common/Icons/ViewIcon';
import ViewIconOff from 'src/components/Common/Icons/ViewIconOff';
import Toast from 'src/components/Common/Toast/Toast';
import AuthLayout from 'src/components/Layout/AuthLayout/AuthLayout';
import { useApp } from 'src/contexts/app';
import { useAuth } from 'src/contexts/auth';
import { SET_AUTH_INFO } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { SET_CLINIC_INFO } from 'src/contexts/clinic/types';
import { useExperiment } from 'src/contexts/experiments';
import { SET_USER_METADATA } from 'src/contexts/experiments/types';
import { useOnboarding } from 'src/contexts/onboarding';
import { SET_ONBOARDING_RESULT } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { expandExperimentUser } from 'src/lib/experiments';
import { navigateToPage } from '../routes';
import './scss/login.scss';

const Login: FC = (): ReactElement => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [values, setValues] = useState<LoginUserPayload>({ email: '', password: '' });
  const [error, setError] = useState<{ email: string; login: string }>({ email: '', login: '' });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();

  const { dispatchAuth } = useAuth();
  const { dispatchOnboarding } = useOnboarding();
  const { dispatchClinic } = useClinic();
  const { lockNavigationBetweenPages } = useApp();

  const routePath = useRoutePath();
  const { dispatchExperimentState } = useExperiment();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const validateEmail = (email: string) => {
    if (!isEmailValid(email)) {
      setError({ email: 'Email is not valid', login: '' });
      return false;
    }
    return true;
  };

  const login = async () => {
    const response = await userClient.loginUser(values);

    if (!response) {
      setIsSubmitting(false);
      setError({ email: '', login: 'Email/Password incorrect' });

      return Toast.notification('error', 'Email/Password incorrect');
    }

    try {
      const { user, onboarding, clinic, isReferral } = await coreClient.fetchAll();

      if (user) {
        MixpanelClient.identifyUser(user.id);

        const partners = await getPartners();

        const userMetadata = {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          ...(user.utmSource && { utmSource: user.utmSource }),
          clinicId: `${user.clinicId}`,
          systemSource: `${user.systemSource}`,
          programType: `${user.programType}`,
          membershipType: `${onboarding?.eligibility?.membershipType}`,
        };

        dispatchExperimentState({
          type: SET_USER_METADATA,
          payload: expandExperimentUser(user.id, {
            appVersion: FUNNEL_VERSION,
            custom: userMetadata,
            email: user.email,
            userID: user.uuid,
          }),
        });

        dispatchOnboarding({ type: SET_ONBOARDING_RESULT, payload: onboarding });
        dispatchClinic({ type: SET_CLINIC_INFO, payload: clinic ? { ...clinic, partners } : null });
        dispatchAuth({ type: SET_AUTH_INFO, payload: { loaded: true, isReferral, user } });

        if (onboarding.magicLink) {
          MixpanelClient.trackEvent({
            event: MixpanelEvent.InputData,
            properties: { field: 'MagicLink', source: routePath },
          });
        }

        if (FeatureManager.shouldReferralGoThroughSSB({ isReferral, clinicId: user!.clinicId })) {
          if (!onboarding.eligibility.membershipType) {
            deleteAuth();

            Toast.notification(
              'error',
              'We are still waiting for your insurance coverage to determine your membership alternatives. Please, reach out to our member success team for more information',
              { autoClose: false }
            );

            return setIsSubmitting(false);
          }
        }

        lockNavigationBetweenPages();
        return navigateToPage({ targetPage: '/goals', navigate });
      } else {
        deleteAuth();
      }
    } catch (error) {
      if (isFunnelError(error, FunnelErrors.MembershipNotSet)) {
        MixpanelClient.trackEvent({
          event: MixpanelEvent.InputData,
          properties: { field: 'Membership not set', source: routePath },
        });
      }
    }

    setIsSubmitting(false);

    Toast.notification('error', 'Email/Password incorrect');
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!validateEmail(values?.email || '')) {
      return;
    }

    setIsSubmitting(true);

    login();
  };

  return (
    <AuthLayout>
      <Box mb={4}>
        <Typography variant='h1' color='primary.main'>
          Welcome to Enara
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <FormGroup className='form-group'>
          <Box mb={1}>
            <label htmlFor='email'>Email</label>
          </Box>
          <ElementTracker routePath={routePath} name='Email' type={ElementTrackerType.Blurrable}>
            <TextField
              type='email'
              variant='filled'
              name='email'
              placeholder='Your Email'
              id='email'
              hiddenLabel
              error={!!error?.email || !!error.login}
              helperText={error?.email}
              onChange={handleChange}
            />
          </ElementTracker>
        </FormGroup>
        <FormGroup className='form-group'>
          <Box mb={1}>
            <label htmlFor='password'>Password</label>
          </Box>
          <TextField
            error={!!error.login}
            variant='filled'
            name='password'
            placeholder='Your Password'
            id='password'
            hiddenLabel
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword((prev) => !prev)}
                    onMouseDown={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? <ViewIconOff /> : <ViewIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormGroup>
        <Box mb={4}>
          <ElementTracker
            routePath={routePath}
            name='Forgot password link'
            type={ElementTrackerType.Clickable}>
            <NavLink to='/forgot-password'>
              <Typography variant='subtitle1' component='span' color='primary'>
                Forgot Password
              </Typography>
            </NavLink>
          </ElementTracker>
        </Box>
        <FormGroup className='form-group'>
          <ElementTracker
            routePath={routePath}
            name='Login button'
            type={ElementTrackerType.Clickable}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={!(isEmailValid(values.email) && values.password) || isSubmitting}>
              {isSubmitting ? <CircularProgress /> : 'Sign in'}
            </Button>
          </ElementTracker>
        </FormGroup>
        <Grid container item mb={2} justifyContent='center' alignItems='center'>
          <Typography variant='subtitle2' mr={1}>
            New on Enara?
          </Typography>
          <ElementTracker
            routePath={routePath}
            name='Create account link'
            type={ElementTrackerType.Clickable}>
            <NavLink to='/'>
              <Typography variant='subtitle1' color='primary'>
                Create an Account
              </Typography>
            </NavLink>
          </ElementTracker>
        </Grid>
        {error.login && (
          <Grid container justifyContent='center'>
            <Typography variant='caption' color='error' align='center'>
              {error.login}
            </Typography>
          </Grid>
        )}
      </form>
    </AuthLayout>
  );
};

export default Login;
