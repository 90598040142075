import {
  AgreementsStatus,
  AppointmentBookingMethod,
  MembershipType,
  OnboardingLocationFlow,
  StatsigManager,
} from '@enaratech/funnel-helper';
import { NavigateFunction } from 'react-router-dom';
import { navigateToPage } from 'src/pages/routes';
import { Page } from './Progress';

/**
 * Either no match at all against the eligibility database or
 * the member chose HPSM as insurance (no cost -> directly to MS)
 * so members will have to go through the MS flow.
 *
 * @returns false if the flow was not handled; otherwise, true
 */
export const checkMemberSuccessFlow = ({
  page,
  initialAppointmentId,
  canNavigateBetweenPages,
  navigate,
}: {
  page: Page;
  initialAppointmentId: number;
  canNavigateBetweenPages: boolean;
  navigate: NavigateFunction;
}): boolean => {
  if (!initialAppointmentId) {
    if (page !== Page.MemberSuccessBooking && canNavigateBetweenPages) {
      navigateToPage({ targetPage: '/appointment/ms-booking', navigate });
      return false;
    }
  } else {
    if (StatsigManager.isArrivingFromAdwords()) {
      // Only for users coming from Adwords
      if (page !== Page.ComingCallResult && canNavigateBetweenPages) {
        navigateToPage({ targetPage: '/onboarding-call-result', navigate });
        return false;
      }
    } else {
      if (page !== Page.MemberSuccessBookingResult && canNavigateBetweenPages) {
        navigateToPage({ targetPage: '/appointment/ms-booking/result', navigate });
        return false;
      }
    }
  }

  return true;
};

/**
 * Not all clinics and insurances have the SSB flow enabled by default.
 * This is handled by setting them up as features (feature-api) and the main
 * idea is to allow members to schedule calls with the three specialists available
 * without having to jump on a call with anybody from the Enara team (Make
 * the process smoother).
 *
 * For members coming from the legacy funnel, booking method will always be
 * `ms-call` (SSB was never enabled there), so if there was eligibility match,
 * they need to be able to go through the SSB flow.
 *
 * @returns false if the flow was not handled; otherwise, true
 */
const checkSelfServeBookingFlow = ({
  page,
  bookingMethod,
  canNavigateBetweenPages,
  locationFlow,
  isReferralOrMagicLink,
  navigate,
}: {
  page: Page;
  bookingMethod: AppointmentBookingMethod | null;
  canNavigateBetweenPages: boolean;
  locationFlow: OnboardingLocationFlow | null;
  isReferralOrMagicLink?: boolean;
  navigate: NavigateFunction;
}): boolean => {
  if (
    !bookingMethod ||
    bookingMethod === AppointmentBookingMethod.MSCall ||
    ((isReferralOrMagicLink || locationFlow === OnboardingLocationFlow.Onsite) &&
      bookingMethod !== AppointmentBookingMethod.SelfServed)
  ) {
    if (page !== Page.SelfServeBooking && canNavigateBetweenPages) {
      navigateToPage({ targetPage: '/appointment/self-serve-booking', navigate });
      return false;
    }
  } else {
    if (page !== Page.SelfServeBookingResult && canNavigateBetweenPages) {
      navigateToPage({ targetPage: '/appointment/self-serve-booking/result', navigate });
      return false;
    }
  }

  return true;
};

/**
 * If there was a match against the eligibility db (onboarding-api) members
 * will always have to fill out the payment form but right after that they have
 * the following options:
 *
 * 1) If the clinic/insurance is enabled, directly to the SSB flow
 * 2) If not, MS flow is the only option available for them
 *
 * @returns false if the flow was not handled; otherwise, true
 */
export const checkEligibilityFlow = ({
  page,
  hintId,
  initialAppointmentId,
  bookingMethod,
  locationFlow,
  canNavigateBetweenPages,
  isSelfServeBookingFeatureEnabled,
  navigate,
}: {
  page: Page;
  hintId: string | undefined;
  initialAppointmentId: number;
  bookingMethod: AppointmentBookingMethod | null;
  locationFlow: OnboardingLocationFlow | null;
  canNavigateBetweenPages: boolean;
  isSelfServeBookingFeatureEnabled: boolean;
  navigate: NavigateFunction;
}): boolean => {
  if (!hintId) {
    if (page !== Page.Payment && canNavigateBetweenPages) {
      navigateToPage({ targetPage: '/payment', navigate });
      return false;
    }
  } else {
    if (isSelfServeBookingFeatureEnabled) {
      return checkSelfServeBookingFlow({
        page,
        bookingMethod,
        locationFlow,
        canNavigateBetweenPages,
        navigate,
      });
    } else {
      return checkMemberSuccessFlow({
        page,
        initialAppointmentId,
        canNavigateBetweenPages,
        navigate,
      });
    }
  }

  return true;
};

/**
 * Magic link flow is for those members that went through the MS flow,
 * had the call with the lead owner and then received an SMS with
 * a link containing an encrypted hash. This hash holds information
 * about the member that allows him/her to login to the funnel without
 * having to provide credentials (email/password).
 *
 * Also, there are some clinics whose referrals need to go directly to
 * the SSB flow. It is expected for the member to have all the required
 * data (eg. membership) already completed; otherwise, the app will handle
 * this as an error.
 *
 * @returns false if the flow was not handled; otherwise, true
 */
export const checkMagicLinkOrReferralFlow = ({
  page,
  hintId,
  bookingMethod,
  locationFlow,
  canNavigateBetweenPages,
  navigate,
}: {
  page: Page;
  hintId: string | undefined;
  bookingMethod: AppointmentBookingMethod | null;
  locationFlow: OnboardingLocationFlow | null;
  canNavigateBetweenPages: boolean;
  navigate: NavigateFunction;
}): boolean => {
  if (!hintId) {
    if (page !== Page.Payment && canNavigateBetweenPages) {
      navigateToPage({ targetPage: '/payment', navigate });
      return false;
    }
  } else {
    return checkSelfServeBookingFlow({
      page,
      locationFlow,
      bookingMethod,
      canNavigateBetweenPages,
      navigate,
    });
  }

  return true;
};

/**
 * If members decide to continue without insurance (coverage = cash), they
 * have to first, fill out the payment form and then go to the SSB flow if enabled.
 *
 * @returns false if the flow was not handled; otherwise, true
 */
export const checkNoInsuranceFlow = ({
  page,
  hintId,
  locationFlow,
  bookingMethod,
  canNavigateBetweenPages,
  initialAppointmentId,
  isSelfServeBookingFeatureEnabled,
  navigate,
}: {
  page: Page;
  hintId: string | undefined;
  locationFlow: OnboardingLocationFlow | null;
  bookingMethod: AppointmentBookingMethod | null;
  canNavigateBetweenPages: boolean;
  initialAppointmentId: number;
  isSelfServeBookingFeatureEnabled: boolean;
  navigate: NavigateFunction;
}): boolean => {
  return checkEligibilityFlow({
    page,
    hintId,
    initialAppointmentId,
    locationFlow,
    bookingMethod,
    canNavigateBetweenPages,
    isSelfServeBookingFeatureEnabled,
    navigate,
  });
};

/**
 * If the ops team decides to offer new prices (membership details) to the member,
 * there could be two different scenarios:
 *
 * 1) The member already went through payment page (hint ID and agreements signed), so
 * the only option available is to override signed agreements with the new ones
 * 2) No payment at all yet, so they can safely go through the normal flow (payment page)
 *
 * @returns false if the flow was not handled; otherwise, true
 */
export const checkCustomAgreementsFlow = ({
  page,
  hintId,
  initialAppointmentId,
  status,
  locationFlow,
  bookingMethod,
  canNavigateBetweenPages,
  isSelfServeBookingFeatureEnabled,
  navigate,
}: {
  page: Page;
  hintId: string | undefined;
  initialAppointmentId: number;
  status: AgreementsStatus;
  locationFlow: OnboardingLocationFlow | null;
  bookingMethod: AppointmentBookingMethod | null;
  canNavigateBetweenPages: boolean;
  isSelfServeBookingFeatureEnabled: boolean;
  navigate: NavigateFunction;
}): boolean => {
  if (hintId && status !== AgreementsStatus.Completed) {
    if (page !== Page.CustomAgreements && canNavigateBetweenPages) {
      navigateToPage({ targetPage: '/custom-agreements', navigate });
      return false;
    }
  } else {
    return checkEligibilityFlow({
      page,
      hintId,
      initialAppointmentId,
      locationFlow,
      bookingMethod,
      canNavigateBetweenPages,
      isSelfServeBookingFeatureEnabled,
      navigate,
    });
  }

  return true;
};

/**
 * There are five different scenarios that we need to cover for members created in the legacy
 * funnel that want to finish the onboarding process in the new one:
 *
 * 1) Member did not complete the insurance page. That is addressed by default because the
 * new funnel checks `insuranceCompany` and `membershipType`. Both of them will be `null`.
 * 2) Member completed the insurance page and then dropped out. Now we have `insuranceCompany`
 * and we can/cannot have `membershipType`. Either way, member will continue with the normal flow
 * (Also addressed by default).
 * 3) Member completed the insurance page, booked with `MS` and then dropped out. This is not
 * covered by default and we need to address this by checking `initialAppointmentId` and `hintId`.
 * Last one must not be set to redirect members to the payment page and then continue with the
 * normal flow.
 * 4) Member completed the insurance page, booked with `MS`, filled out the payment page and then
 * dropped out. Not covered by default, we need to be able to sign agreements which is addressed by
 * calling `canSignCustomAgreements` in a different flow.
 * 5) Member completed the insurance page, booked with `MS`, filled out the payment page, signed
 * agreements and then dropped out. When coming to the funnel, if no membership, then `MS` is the
 * way to go, else, member will initiate the `SSB` flow. This is addressed by default.
 *
 * In any scenario, match against the eligibility database plays an important role as the next steps
 * will be determined based on that (normal flow for the funnel).
 *
 * @returns false if the flow was not handled; otherwise, true
 */
export const checkLegacyFunnelMemberFlow = ({
  page,
  initialAppointmentId,
  membershipType,
  canNavigateBetweenPages,
  navigate,
}: {
  page: Page;
  initialAppointmentId: number;
  membershipType: MembershipType | null;
  canNavigateBetweenPages: boolean;
  navigate: NavigateFunction;
}) => {
  if (!membershipType) {
    return checkMemberSuccessFlow({
      page,
      initialAppointmentId,
      canNavigateBetweenPages,
      navigate,
    });
  }

  if (page !== Page.Payment && canNavigateBetweenPages) {
    navigateToPage({ targetPage: '/payment', navigate });
    return false;
  }

  return true;
};
