import {
  AvailableHours,
  ElementTracker,
  ElementTrackerType,
  formatDate,
  useViewport,
} from '@enaratech/funnel-helper';
import { CircularProgress, Link, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import RadioButtons from 'src/components/Common/Inputs/RadioButtons/RadioButtons';
import { useRoutePath } from 'src/hooks/useRoutePath';

type Props = {
  currentDate: DateTime | null;
  options: AvailableHours[];
  isLoading?: boolean;
  onPick: (hour: string) => void;
  onShowCalendar: () => void;
};

const TimePicker: FC<Props> = ({ currentDate, options, isLoading, onPick, onShowCalendar }) => {
  const [selectedHour, setSelectedHour] = useState<string | null>(null);

  const routePath = useRoutePath();
  const { isMobileView, isTabletView } = useViewport();

  const handleHourSelected = (hour: string) => {
    setSelectedHour(hour);
    onPick(hour);
  };

  return (
    <div className='appointment-available-hours'>
      <div className='appointment-date-time'>
        {currentDate && <Typography variant='subtitle1'>{formatDate({ currentDate })}</Typography>}
        {(isMobileView || isTabletView) && (
          <ElementTracker
            routePath={routePath}
            name='Edit calendar on mobile'
            type={ElementTrackerType.Clickable}>
            <Link variant='subtitle1' onClick={onShowCalendar}>
              Edit
            </Link>
          </ElementTracker>
        )}
      </div>
      {isLoading ? (
        <CircularProgress className='circular-progress-availability' />
      ) : options?.length > 0 ? (
        <RadioButtons
          name='Appointment Time'
          options={options}
          onChange={(_, hour) => handleHourSelected(hour)}
          className='radio-buttons-available-times'
          value={selectedHour}
        />
      ) : (
        <Typography variant='h3'>No time available</Typography>
      )}
    </div>
  );
};

TimePicker.defaultProps = {
  isLoading: false,
};

export default TimePicker;
