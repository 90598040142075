import { useViewport } from '@enaratech/funnel-helper';
import { DateTime } from 'luxon';
import { FC } from 'react';
import DesktopDatePicker from './DesktopDatePicker';
import MobileDatePicker from './MobileDatePicker';

type Props = {
  error?: boolean;
  errorMessage?: string;
  value: DateTime | null;
  onChange: (date: DateTime | null) => void;
};

const DatePicker: FC<Props> = ({ error, errorMessage, value, onChange }) => {
  const { isMobileView } = useViewport();

  return isMobileView ? (
    <MobileDatePicker error={error} errorMessage={errorMessage} onChange={onChange} value={value} />
  ) : (
    <DesktopDatePicker
      error={error}
      errorMessage={errorMessage}
      inputProps={{ fullWidth: true }}
      onChange={onChange}
      value={value}
    />
  );
};

export default DatePicker;
