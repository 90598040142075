import {
  MixpanelClient,
  MixpanelEvent,
  Partner,
  UNKNOWN_CLINIC_ID,
  getPartnerById,
  getPartners,
} from '@enaratech/funnel-helper';
import { Autocomplete, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useAuth } from 'src/contexts/auth';
import { useRoutePath } from 'src/hooks/useRoutePath';

type Props = {
  onChange: (value: Partner) => void;
  clinicId: number | null;
};

const PartnerOptions: FC<Props> = ({ onChange, clinicId }) => {
  const [partners, setPartners] = useState<Partner[]>([]);

  const routePath = useRoutePath();

  const { authState } = useAuth();

  const UNKNOWN_CLINIC_OPTION = {
    name: 'No, I am not a patient of these clinics',
    clinicId: UNKNOWN_CLINIC_ID,
    referralClinicCode: null,
    zipCode: 0,
    branding: {},
    urlSlug: '',
  } as Partner;

  const defaultValue =
    clinicId === UNKNOWN_CLINIC_ID ? UNKNOWN_CLINIC_OPTION : getPartnerById(clinicId, partners);

  const trackMixpanelEvent = (field: string, event: MixpanelEvent, value: string) => {
    MixpanelClient.trackEvent({
      event,
      properties: {
        field,
        value,
        source: routePath,
      },
    });
  };

  useEffect(() => {
    (async () => {
      if (partners.length === 0) {
        const isReferralFlow = !!authState!.isReferral;

        const partners = await getPartners({
          d2c: !isReferralFlow,
          referrals: isReferralFlow,
        });

        setPartners([...partners, UNKNOWN_CLINIC_OPTION]);
      }
    })();
  }, []);

  return (
    <Autocomplete
      className='autocomplete'
      options={partners}
      getOptionLabel={(option) => option.name}
      value={defaultValue}
      renderInput={(params) => <TextField {...params} placeholder='Select one clinic' />}
      onChange={(_, value) => {
        if (value) {
          onChange(value);
          trackMixpanelEvent('Clinic ID', MixpanelEvent.Select, `${value.clinicId}`);
        }
      }}
    />
  );
};

export default PartnerOptions;
