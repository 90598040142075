import {
  AppointmentIntentionWithProviderCodes,
  AppointmentIntentionWithProviderCodesAndLocation,
  AvailableLanguages,
  scheduleClient,
  ScheduledAppointment,
  ScheduledAppointments,
  Step,
  StepConfig,
  SupportedAppointmentIntention,
  SupportedLocationType,
} from '@enaratech/funnel-helper';
import Toast from 'src/components/Common/Toast/Toast';
import { SSBFlowState } from 'src/contexts/ssb/types';
import { SET_SSB_INFO } from '../../../../../../contexts/ssb/types';
import { FetchStatusActionParams } from '../types';

export const configureLocationAndSelectorVisibility = ({
  context,
  setters,
}: {
  context: {
    stepConfig: StepConfig;
  };
  setters: {
    setSelectedLocation: React.Dispatch<React.SetStateAction<SupportedLocationType | undefined>>;
  };
}) => {
  const { setSelectedLocation } = setters;

  let resolvedLocation: string | undefined = 'OL';

  const { allowedLocations } = context.stepConfig;

  if (allowedLocations.length > 1) {
    resolvedLocation = undefined;
  }

  resolvedLocation = allowedLocations[0];

  setSelectedLocation(resolvedLocation as SupportedLocationType);
};

export const getPreviousAppointmentByRules = ({
  scheduledAppointments,
  calculatedSteps,
  stepsRef,
  selectedLanguage,
}: {
  scheduledAppointments: ScheduledAppointments | null;
  calculatedSteps?: Step[] | null;
  stepsRef: React.MutableRefObject<Step[] | null>;
  selectedLanguage: string;
}) => {
  const currentSteps = calculatedSteps || stepsRef.current || null;

  if (!currentSteps) {
    return null;
  }

  const currentStep = currentSteps.find((s) => s.active);

  if (!currentStep || !scheduledAppointments) {
    return null;
  }

  let isGCAppointment = false;

  let previousAppointment: ScheduledAppointment | null = null;

  const scheduledAppointmentKeys = Object.keys(scheduledAppointments);

  const previousStep = currentSteps.filter((s) => s.scheduled).pop();

  if (previousStep) {
    const { allowedIntention } = currentStep.config;

    let previousScheduledAppointmentCode = scheduledAppointmentKeys.find((key) => {
      const { allowedIntention, allowedLanguages, allowedLocations, allowedSpecialty } =
        previousStep.config;

      const [intention, specialty, location, language] = key.split('-');

      return (
        intention === allowedIntention &&
        specialty === allowedSpecialty &&
        allowedLocations.includes(location as SupportedLocationType) &&
        allowedLanguages.includes(language.toLowerCase() as AvailableLanguages)
      );
    });

    isGCAppointment = allowedIntention === ('GC' as SupportedAppointmentIntention);

    const medicalAppointmentCodeWithLanguage = `${
      AppointmentIntentionWithProviderCodesAndLocation.InitialMedical
    }-${selectedLanguage?.toUpperCase()}` as AppointmentIntentionWithProviderCodes;

    previousAppointment = isGCAppointment
      ? scheduledAppointments[medicalAppointmentCodeWithLanguage]
      : scheduledAppointments[previousScheduledAppointmentCode as keyof ScheduledAppointments];
  }

  return previousAppointment;
};

export const fetchStatusAction = async ({
  context,
  setters,
  setState,
}: FetchStatusActionParams) => {
  if (!context.selectedLanguage) {
    setState({ type: SET_SSB_INFO, payload: SSBFlowState.InitSchedulingProcess });
    return;
  }

  const previousAppointment = getPreviousAppointmentByRules({
    scheduledAppointments: context.scheduledAppointments,
    stepsRef: context.stepsRef,
    selectedLanguage: context.selectedLanguage,
  });

  context.appointmentsSchedulerRef.current?.resetCalendar(previousAppointment ?? undefined);

  const previousScheduledAppointments =
    (await scheduleClient.getAppointmentsScheduledByUser()) as unknown as ScheduledAppointments;

  if (!previousScheduledAppointments) {
    return Toast.notification(
      'error',
      'There was an error obtaining the information. Please reload the page or wait for a support member to contact you, we are looking for the solution.'
    );
  }

  const currentStep = context.stepsRef.current?.find((s) => s.active);

  if (currentStep) {
    configureLocationAndSelectorVisibility({
      context: { stepConfig: currentStep.config },
      setters,
    });
  }

  setters.setPullingAppointments(false);
  setters.setScheduledAppointments(previousScheduledAppointments);

  setState({ type: SET_SSB_INFO, payload: SSBFlowState.WaitingForUserSelection });
};
