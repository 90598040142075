import { ElementTracker, ElementTrackerType } from '@enaratech/funnel-helper';
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from '@mui/material';
import { ChangeEvent, FC } from 'react';
import CircleSelectionCheckedIcon from 'src/components/Common/Icons/CircleSelectionCheckedIcon';
import CircleSelectionUncheckedIcon from 'src/components/Common/Icons/CircleSelectionUncheckedIcon';
import { useRoutePath } from 'src/hooks/useRoutePath';
import './scss/radio-buttons.scss';

interface RadioButtonsProps extends RadioGroupProps {
  name: string;
  trackingDisabled?: boolean;
  options: (Pick<FormControlLabelProps, 'label' | 'value'> & Partial<FormControlLabelProps>)[];
}

const RadioButton: FC<RadioProps> = (props) => (
  <Radio
    disableRipple
    disableFocusRipple
    checkedIcon={<CircleSelectionCheckedIcon color='primary' />}
    icon={<CircleSelectionUncheckedIcon />}
    className={'radio-button'}
    {...props}
  />
);

const RadioButtons: FC<RadioButtonsProps> = ({
  id,
  onChange,
  options,
  value,
  name,
  className,
  trackingDisabled,
  ...radioGroupProps
}) => {
  const routePath = useRoutePath();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event, event.target.value);
  };

  const renderItems = () => (
    <RadioGroup
      id={id}
      {...radioGroupProps}
      onChange={(e) => handleChange(e)}
      sx={{ gap: radioGroupProps.row ? '10px' : '8px', ...radioGroupProps.sx }}
      value={value}
      className={`radio-group h-full ${className}`}>
      {options.map((optionProps) => (
        <FormControlLabel
          color='primary'
          componentsProps={{ typography: { variant: 'h3' } }}
          control={<RadioButton />}
          data-test={`${id}-${optionProps.value}`}
          key={`${id}-${optionProps.value}`}
          disabled={optionProps?.disabled || false}
          sx={(theme) => ({
            ...(radioGroupProps.row && { width: `calc(${100 / options.length}% - 10px)` }),
            border: `2px solid ${
              value === optionProps.value ? theme.palette.primary.main : theme.palette.warning.main
            }`,
          })}
          {...optionProps}
        />
      ))}
    </RadioGroup>
  );

  return (
    <FormControl fullWidth hiddenLabel variant='outlined' className={`h-full ${className}`}>
      {trackingDisabled ? (
        renderItems()
      ) : (
        <ElementTracker routePath={routePath} name={name} type={ElementTrackerType.Changeable}>
          {renderItems()}
        </ElementTracker>
      )}
    </FormControl>
  );
};

RadioButtons.defaultProps = {
  trackingDisabled: false,
};

export default RadioButtons;
