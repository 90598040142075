import {
  AgreementsHistory,
  AgreementsStatus,
  AppointmentBookingMethod,
  AvailableLanguages,
  EligibilityPricing,
  MemberMagicLink,
  MembershipType,
  OnboardingLocationFlow,
} from '@enaratech/funnel-helper';
import { Dispatch } from 'react';

export type OnboardingContextValue = {
  onboardingState: OnboardingState;
  dispatchOnboarding: Dispatch<OnboardingActionTypes>;
};

export type OnboardingState = {
  member: {
    firstLanguage: AvailableLanguages | null;
  };
  agreements: {
    status: AgreementsStatus;
    agreementsHistory: AgreementsHistory | null;
  };
  eligibility: {
    pricing: EligibilityPricing[] | null;
    membershipType: MembershipType | null;
  };
  bookingMethod: AppointmentBookingMethod | null;
  locationFlow: OnboardingLocationFlow | null;
  magicLink: MemberMagicLink | null;
};

export const CLEAR_ONBOARDING_RESULT = '@enara/types/clear-onboarding-result';
export const SET_ONBOARDING_RESULT = '@enara/types/set-onboarding-result';
export const SET_ELIGIBILITY = '@enara/types/set-eligibility';
export const SET_BOOKING_METHOD = '@enara/types/set-booking-method';
export const SET_FIRST_LANGUAGE = '@enara/types/set-first-language';

export type ClearOnboardingResultType = {
  type: typeof CLEAR_ONBOARDING_RESULT;
};

export type SetOnboardingResultType = {
  type: typeof SET_ONBOARDING_RESULT;
  payload: OnboardingState;
};

export type SetEligibilityType = {
  type: typeof SET_ELIGIBILITY;
  payload: Partial<OnboardingState['eligibility']['membershipType']>;
};

export type SetBookingMethodType = {
  type: typeof SET_BOOKING_METHOD;
  payload: AppointmentBookingMethod;
};

export type SetFirstLanguageType = {
  type: typeof SET_FIRST_LANGUAGE;
  payload: AvailableLanguages;
};

export type OnboardingActionTypes =
  | ClearOnboardingResultType
  | SetOnboardingResultType
  | SetEligibilityType
  | SetBookingMethodType
  | SetFirstLanguageType;
