import { StripeError } from '@stripe/stripe-js';

export const MONTHLY_PLAN_DURATION = 1;
export const ANNUAL_PLAN_DURATION = 12;

export type Data = {
  price: number;
  annualPrice: number;
  location: string;
};

export type ReturnData = {
  discountMonthly: number;
  discountAnnually: number;
  coupon: number;
  duration: number | null;
};

export type PaymentForm = {
  street: string;
  city: string;
  state: string;
  zipCode: string;
};

export type PaymentErrors = {
  street: string;
  city: string;
  state: string;
  zipcode: string;
  cardnumber: string;
  cvc: string;
  expiration: string;
};

export type PaymentMethodResult =
  | { stripePaymentMethod: { id: string }; error?: undefined }
  | { stripePaymentMethod?: undefined; error: StripeError };
