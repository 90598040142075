import {
  deleteAuth,
  ElementTracker,
  ElementTrackerType,
  MixpanelClient,
  MixpanelEvent,
} from '@enaratech/funnel-helper';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect } from 'react';
import MarkAndMelodiePNG from 'src/assets/images/mark-and-melodie.png';
import ErrorLayout from 'src/components/Layout/ErrorLayout/ErrorLayout';
import { useRoutePath } from 'src/hooks/useRoutePath';

const UnhandledError: FC = () => {
  const routePath = useRoutePath();

  const reloadPage = () => {
    window.location.reload();
  };

  const goToLoginPage = () => {
    deleteAuth();
    window.location.href = '/login';
  };

  useEffect(() => {
    const properties = { value: 'Error Page', source: routePath };
    MixpanelClient.trackEvent({ event: MixpanelEvent.PageView, properties });
  }, [routePath]);

  return (
    <ErrorLayout>
      <Box mb={6} justifyContent='center'>
        <Grid container justifyContent='center'>
          <Grid item container my={2}>
            <Typography variant='h1' align='center' width='100%'>
              Whoops! Something went wrong.
            </Typography>
            <Typography component='p' align='center' width='100%'>
              Please try reloading the page or return to the Log In page.
            </Typography>
          </Grid>
          <Grid item container justifyContent='center'>
            <Grid item container xs={12} md={6} gap={2} justifyContent='center'>
              <ElementTracker
                routePath={routePath}
                name='Reload Button'
                type={ElementTrackerType.Clickable}>
                <Button variant='outlined' onClick={reloadPage}>
                  Reload
                </Button>
              </ElementTracker>
              <ElementTracker
                routePath={routePath}
                name='Log In Button'
                type={ElementTrackerType.Clickable}>
                <Button variant='outlined' onClick={goToLoginPage}>
                  Log In
                </Button>
              </ElementTracker>
            </Grid>
          </Grid>
          <Grid item container justifyContent='center' mt={-2}>
            <img src={MarkAndMelodiePNG} alt='Enara Page Error' width={350} />
          </Grid>
        </Grid>
      </Box>
    </ErrorLayout>
  );
};

export default UnhandledError;
