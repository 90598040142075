import {
  ENVIRONMENT,
  FeatureManager,
  HONEYBADGER_KEY,
  MixpanelClient,
} from '@enaratech/funnel-helper';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App/App';
import UnhandledError from './components/Common/UnhandledError/UnhandledError';
import { AppProvider } from './contexts/app';
import { AuthProvider } from './contexts/auth';
import { ClinicProvider } from './contexts/clinic';
import { ExperimentProvider } from './contexts/experiments';
import { OnboardingProvider } from './contexts/onboarding';
import { SSBProvider } from './contexts/ssb';
import './index.css';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

MixpanelClient.init();
FeatureManager.initVariant();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <SSBProvider>
            <ClinicProvider>
              <OnboardingProvider>
                <AuthProvider>
                  <ExperimentProvider>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <HoneybadgerErrorBoundary
                        honeybadger={Honeybadger.configure({
                          apiKey: HONEYBADGER_KEY,
                          environment: ENVIRONMENT,
                        })}
                        ErrorComponent={UnhandledError as any}>
                        <App />
                      </HoneybadgerErrorBoundary>
                    </LocalizationProvider>
                  </ExperimentProvider>
                </AuthProvider>
              </OnboardingProvider>
            </ClinicProvider>
          </SSBProvider>
        </AppProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
