import { ElementTracker, ElementTrackerType } from '@enaratech/funnel-helper';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, HTMLAttributes, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useRoutePath } from 'src/hooks/useRoutePath';
import './scss/modal.scss';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  isOpen: boolean;
  title: ReactNode;
  subtitle: string;
  onClose: () => void;
}

const Modal: FC<Props> = ({
  isOpen,
  title,
  subtitle,
  onClose,
  children,
}): ReactElement<Props> | null => {
  const [isShown, setIsShown] = useState<boolean>(isOpen);
  const routePath = useRoutePath();

  const handleClose = () => {
    setIsShown(false);
    onClose();
  };

  useEffect(() => {
    setIsShown(isOpen);
  }, [isOpen]);

  return isShown ? (
    <Box className='modal-overflow'>
      <Box className='modal'>
        {title}
        <Typography variant='body2' fontSize={16}>
          {subtitle}
        </Typography>
        <Box py={2}>{children}</Box>
        <Box>
          <ElementTracker
            routePath={routePath}
            name='Modal pop-out'
            type={ElementTrackerType.Clickable}
            value='Continue'>
            <Button onClick={handleClose} variant='contained' fullWidth className='modal-button'>
              Continue
            </Button>
          </ElementTracker>
        </Box>
      </Box>
    </Box>
  ) : null;
};

Modal.defaultProps = {
  onClose: () => null,
  isOpen: false,
};

export default Modal;
