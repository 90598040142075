import { InputFieldsType } from '@enaratech/funnel-helper';

export const inputFields: InputFieldsType[] = [
  {
    label: 'Your email',
    field: 'email',
    name: 'Email',
    track: true,
  },
  {
    label: 'Your phone',
    field: 'phone',
    name: 'Phone',
    track: true,
  },
  {
    label: 'Your password',
    field: 'password',
    name: 'Password',
    track: false,
  },
  {
    label: 'Confirm password',
    field: 'confirmPassword',
    name: 'Confirm Password',
    track: false,
  },
];
