import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ViewIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 6C18 6 22 12 22 12C22 12 18 18 12 18C6 18 2 12 2 12C2 12 6 6 12 6ZM3.5 12.3C4 13 4.7 13.6 5.4 14.2C7.4 16 9.6 17 12 17C14.4 17 16.6 16 18.6 14.2C19.3 13.6 20 12.9 20.5 12.3C20.6 12.2 20.7 12.1 20.7 12C20.6 11.9 20.5 11.8 20.5 11.7C19.9 11 19.3 10.4 18.6 9.8C16.6 8 14.4 7 12 7C9.6 7 7.4 8 5.4 9.8C4.7 10.4 4 11 3.5 11.7C3.4 11.8 3.3 11.9 3.3 12C3.3 12.1 3.4 12.2 3.5 12.3ZM14 12C14 13.105 13.105 14 12 14C10.895 14 10 13.105 10 12C10 10.895 10.895 10 12 10C13.105 10 14 10.895 14 12Z'
      fill='#278BA5'
    />
  </SvgIcon>
);

export default ViewIcon;
