import { ArrowBackIcon, useViewport } from '@enaratech/funnel-helper';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApp } from 'src/contexts/app';
import { goBackTo } from '../../../pages/routes';
import './scss/backButton.scss';

const BackButton: FC = () => {
  const navigate = useNavigate();

  const { shouldSkipPartnerPage } = useApp();
  const { isDesktopView } = useViewport();

  const { pathname } = useLocation();

  const handleGoBack = () => {
    goBackTo({ pathname, useSecondaryPath: shouldSkipPartnerPage, navigate });
  };

  return (
    <Box
      alignItems={'center'}
      className='back-button'
      display={'flex'}
      flexDirection={'row'}
      gap={'16px'}
      left={'64px'}
      marginBottom={'16px'}
      onClick={handleGoBack}
      position={isDesktopView ? 'absolute' : undefined}
      data-test-id='common-backButton'>
      <ArrowBackIcon />
      {isDesktopView && <Typography variant='h3'>Back</Typography>}
    </Box>
  );
};

export default BackButton;
