import {
  CoverageInfo,
  ElementTracker,
  ElementTrackerType,
  FeatureManager,
  MembershipType,
  useViewport,
} from '@enaratech/funnel-helper';
import { Box, Collapse, Divider, FormControlLabelProps, Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import ArrowExpandIcon from 'src/components/Common/Icons/ArrowExpandIcon';
import CheckIcon from 'src/components/Common/Icons/CheckIcon';
import EnaraListItemIcon from 'src/components/Common/Icons/EnaraListItemIcon';
import RadioButtons from 'src/components/Common/Inputs/RadioButtons/RadioButtons';
import { PaymentMode } from 'src/components/Common/PaymentType/paymentType.types';
import { useClinic } from 'src/contexts/clinic';
import { useOnboarding } from 'src/contexts/onboarding';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { useMembershipStore } from 'src/store/memberships';
import { MONTHLY_PLAN_DURATION, ReturnData } from './Inputs/Payment.types';
import PromoInput from './Inputs/PromoInput/PromoInput';

type Props = {
  coverage: CoverageInfo;
  planOptions: (Pick<FormControlLabelProps, 'label' | 'value'> & Partial<FormControlLabelProps>)[];
  discount: ReturnData;
  setPromo?: Dispatch<SetStateAction<string>>;
  setDiscount: Dispatch<SetStateAction<ReturnData>>;
};

const CoverageResult: FC<Props> = ({ coverage, planOptions, discount, setPromo, setDiscount }) => {
  const [visible, setVisible] = useState(false);
  const [annualDiscountEnabled, setAnnualDiscountEnabled] = useState<boolean>(false);

  const { isTabletView, isMobileView } = useViewport();
  const { clinicState } = useClinic();
  const routePath = useRoutePath();

  const isMonthlyPlan = useMembershipStore((s) => s.isMonthly);
  const paymentMode = useMembershipStore((s) => s.paymentMode);
  const setPaymentMode = useMembershipStore((s) => s.updatePaymentMode);

  const {
    onboardingState: {
      eligibility: { membershipType },
    },
  } = useOnboarding();

  const handleChangePaymentMode = (paymentMode: PaymentMode): void => {
    setPaymentMode(paymentMode);
  };

  const handleChangePromo = (value: string, data: ReturnData) => {
    data.discountAnnually = Math.trunc(data.discountAnnually);

    setDiscount(data);
    setPromo!(value);
  };

  const getCouponDiscountMessage = (): string => {
    const {
      prices: { monthly },
    } = coverage;

    const { discountMonthly, discountAnnually, duration } = discount;

    const discountedMonthlyAmount = discountMonthly.toFixed(2);
    const discountedAnnualAmount = discountAnnually.toFixed(2);

    if (isMonthlyPlan) {
      let planDurationText = '';
      switch (duration) {
        case MONTHLY_PLAN_DURATION:
          planDurationText = 'month';
          break;
        case null:
          return `You just saved $${discountedMonthlyAmount} on every month of your membership!`;
        default:
          planDurationText = `${duration} months`;
          break;
      }

      return `You just saved $${discountedMonthlyAmount} off your first ${planDurationText}! Each month after that will be at the $${monthly}/month price`;
    }

    return `You just saved $${discountedAnnualAmount} off your yearly price!`;
  };

  useEffect(() => {
    (async () => {
      setAnnualDiscountEnabled(
        await FeatureManager.isAnnualDiscountEnabled(
          clinicState!.details.clinicId,
          membershipType ?? MembershipType.Cash
        )
      );
    })();
  }, []);

  const asIntOrDecimal = (num: number) => (Number.isInteger(num) ? num : num.toFixed(2));
  const discountedMonthlyPrice = asIntOrDecimal(coverage.prices.monthly - discount.discountMonthly);
  const discountedAnnualPrice = asIntOrDecimal(coverage.prices.annual - discount.discountAnnually);

  return (
    <>
      {discount.coupon !== 0 && (
        <div className='details-discount-wrapper'>
          <div className='details-discount-message'>{getCouponDiscountMessage()}</div>
        </div>
      )}
      <div className='details-payment'>
        {annualDiscountEnabled && (
          <p className='details-title-payment'>Choose Annual or Monthly Payment</p>
        )}
        <Box
          display='flex'
          justifyContent='center'
          flexWrap='wrap'
          columnGap={3}
          className='plan-payment'>
          {annualDiscountEnabled && (
            <>
              <div className='coverage-bubble'>{isMonthlyPlan ? 'Save 20%' : 'Best choice'}</div>
              <RadioButtons
                name='Payment Plan'
                id='payment-planType'
                onChange={(e, value) => handleChangePaymentMode(value as PaymentMode)}
                options={planOptions}
                value={paymentMode}
              />
            </>
          )}

          {setPromo && (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <p
                className='details-title-payment'
                style={{ marginTop: '20px', marginBottom: '5px' }}>
                Coupon Code
              </p>
              <Grid item xs={12} sm={12} key={'promo-payment'} className='inputs-payment'>
                <PromoInput
                  handleChange={handleChangePromo}
                  data={{
                    price: coverage.prices.monthly,
                    annualPrice: coverage.prices.annual,
                    location: clinicState!.details!.locationId,
                  }}
                />
              </Grid>
            </div>
          )}
        </Box>
        <h3 className={`coverage-price ${discount.coupon ? 'coverage-discount' : ''}`}>
          {' '}
          {isMonthlyPlan ? `$${coverage.prices.monthly}/Month` : `$${coverage.prices.annual}/Year`}
        </h3>

        {discount.coupon > 0 && (
          <h3 className='coverage-discount-new-price'>
            {isMonthlyPlan ? `$${discountedMonthlyPrice}/Month` : `$${discountedAnnualPrice}/Year`}
          </h3>
        )}

        {(isTabletView || isMobileView) && (
          <>
            <Divider />
            <ElementTracker
              routePath={routePath}
              name='Plan coverage details'
              type={ElementTrackerType.Clickable}
              value={visible ? 'close' : 'open'}>
              <div className='coverage-open' onClick={() => setVisible(!visible)}>
                <ArrowExpandIcon
                  color='primary'
                  className={visible ? 'arrow-collapse' : 'arrow-expand'}
                />{' '}
                Membership includes
              </div>
            </ElementTracker>
          </>
        )}

        <Collapse in={isTabletView || isMobileView ? visible : true}>
          <div className='coverage-list-payment coverage-container-payment'>
            {!(isTabletView || isMobileView) && <h3>What includes:</h3>}
            <div
              onClick={() => {
                if (isTabletView || isMobileView) setVisible(!visible);
              }}>
              {coverage.items.map((item, i) => (
                <li key={`coverage-result-item-${i}`}>
                  <>
                    {item.coveredBy === 'enara' ? <EnaraListItemIcon /> : <CheckIcon />}
                    <span>{item.text}</span>
                  </>
                </li>
              ))}
            </div>
          </div>
          <Divider />
          <div className='coverage-legend-payment coverage-container-payment'>
            <li>
              <CheckIcon />
              <div>
                <span>Covered by your insurance </span>
                <span className='coverage-details'>(copays and deductibles may apply)</span>
              </div>
            </li>
            <li>
              <EnaraListItemIcon />
              <span>Included in your Enara Membership</span>
            </li>
            <li>
              <span className='coverage-details'>
                * Care credit is used to cover copays and deductibles for medical services. Care
                credit can not be used for lab deductibles.
              </span>
            </li>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default CoverageResult;
