import {
  ElementTracker,
  ElementTrackerType,
  isValidPassword,
  userClient,
} from '@enaratech/funnel-helper';
import {
  Button,
  CircularProgress,
  Divider,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, FC, FormEvent, ReactElement, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import ViewIcon from 'src/components/Common/Icons/ViewIcon';
import ViewIconOff from 'src/components/Common/Icons/ViewIconOff';
import AuthLayout from 'src/components/Layout/AuthLayout/AuthLayout';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { navigateToPage } from '../routes';
import './scss/recoveryPassword.scss';

const PASSWORD_HINT = 'Must contain at least 1 uppercase, 1 lowercase and 1 number';
const INITIAL_VALUES = { password: '', password2: '' };
type Values = {
  password: string;
  password2: string;
};

const RecoveryPassword: FC = (): ReactElement => {
  const [values, setValues] = useState<Values>(INITIAL_VALUES);
  const [error, setError] = useState<Values>(INITIAL_VALUES);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<Record<string, boolean>>({
    first: false,
    second: false,
  });

  const navigate = useNavigate();

  const routePath = useRoutePath();

  const validatePassword = (password: string) => {
    if (!isValidPassword(password)) {
      setError((prev) => ({ ...prev, password: PASSWORD_HINT }));
      return false;
    }

    setError(INITIAL_VALUES);
    return true;
  };

  const validateSecondPassword = (password: string) => {
    if (password !== values.password) {
      setError((prev) => ({ ...prev, password2: 'Passwords do not match' }));
      return false;
    }

    setError(INITIAL_VALUES);
    return true;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setError(INITIAL_VALUES);
  };

  const changePassword = async (): Promise<boolean> => {
    const response = await userClient.setPassword({
      password: values!.password,
      passwordConfirmation: values!.password2,
    });
    setIsSubmitting(false);

    return !!response;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!validatePassword(values?.password)) {
      return;
    }

    if (!validateSecondPassword(values?.password2)) {
      return;
    }

    setIsSubmitting(true);
    if (await changePassword()) {
      navigateToPage({ targetPage: '/login', navigate });
    } else {
      setError({
        password: 'Error',
        password2: 'Error',
      });
    }
  };

  return (
    <AuthLayout>
      <Box mb={1} mt={6}>
        <Typography variant='h3'>Set Password</Typography>
      </Box>
      <Box mb={3}>
        <Typography variant='h4'>Choose a new Password.</Typography>
      </Box>
      <Box mb={4}>
        <Divider />
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={3}>
          <FormGroup className='form-group'>
            <Box mb={1}>
              <label htmlFor='password'>New password</label>
            </Box>
            <TextField
              error={!!error.password}
              variant='filled'
              name='password'
              placeholder='Your new password'
              id='password'
              hiddenLabel
              onChange={handleChange}
              type={showPassword.first ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword((prev) => ({ ...prev, first: !prev.first }))}
                      onMouseDown={() =>
                        setShowPassword((prev) => ({ ...prev, first: !prev.first }))
                      }>
                      {showPassword ? <ViewIconOff /> : <ViewIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant='caption'>{PASSWORD_HINT}</Typography>
          </FormGroup>
        </Box>
        <Box mb={6}>
          <FormGroup className='form-group'>
            <Box mb={1}>
              <label htmlFor='password2'>Confirm new password</label>
            </Box>
            <TextField
              error={!!error.password2}
              variant='filled'
              name='password2'
              placeholder='Confirm your password'
              id='password2'
              hiddenLabel
              onChange={handleChange}
              helperText={error.password2}
              type={showPassword.last ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword((prev) => ({ ...prev, last: !prev.last }))}
                      onMouseDown={() =>
                        setShowPassword((prev) => ({ ...prev, last: !prev.last }))
                      }>
                      {showPassword ? <ViewIconOff /> : <ViewIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormGroup>
        </Box>
        <FormGroup className='form-group'>
          <ElementTracker
            routePath={routePath}
            name='Recovery password button'
            type={ElementTrackerType.Clickable}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={!values.password || !values.password2}>
              {isSubmitting ? <CircularProgress /> : 'Confirm'}
            </Button>
          </ElementTracker>
        </FormGroup>
        <Grid container item mt={4} justifyContent='center' alignItems='center'>
          <ElementTracker
            routePath={routePath}
            name='Back to login link'
            type={ElementTrackerType.Clickable}>
            <NavLink to='/login'>
              <Typography variant='subtitle1' color='primary'>
                Back to Login
              </Typography>
            </NavLink>
          </ElementTracker>
        </Grid>
      </form>
    </AuthLayout>
  );
};

export default RecoveryPassword;
