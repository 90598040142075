import {
  AvailableLanguages,
  MixpanelClient,
  MixpanelEvent,
  onboardingClient,
} from '@enaratech/funnel-helper';
import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import RadioButtons from 'src/components/Common/Inputs/RadioButtons/RadioButtons';
import Modal from 'src/components/Common/Modal/Modal';
import { useOnboarding } from 'src/contexts/onboarding';
import { SET_FIRST_LANGUAGE } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { ChangeLanguageLink } from './ChangeLanguageLink';

type Props = {
  onSelect: (language: AvailableLanguages) => void;
  onClickLink: (isSelectorOpen: boolean) => void;
  isLanguageSelectorOpen: boolean;
  noAvailableAppointments?: boolean;
  selectedLanguage: AvailableLanguages | null;
  allowedLanguages: AvailableLanguages[];
};

export const getLanguageName = (language: AvailableLanguages) => {
  switch (language) {
    case AvailableLanguages.Arabic:
      return 'Arabic';
    case AvailableLanguages.Spanish:
      return 'Spanish';
    default:
      return 'English';
  }
};

const LanguageSelector: FC<Props> = ({
  selectedLanguage,
  isLanguageSelectorOpen,
  noAvailableAppointments,
  onSelect,
  allowedLanguages,
  onClickLink,
}) => {
  const pathname = useRoutePath();
  const [language, setLanguage] = useState<AvailableLanguages>(
    selectedLanguage ?? AvailableLanguages.English
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(isLanguageSelectorOpen);
  const [isFirstMount, setIsFirstMount] = useState<boolean>(true);
  const { dispatchOnboarding } = useOnboarding();

  const updateUserLanguage = (language: AvailableLanguages) => {
    onboardingClient.updateMemberStatus({ firstLanguage: language! });

    dispatchOnboarding({
      type: SET_FIRST_LANGUAGE,
      payload: language!,
    });
  };

  const handleClose = () => {
    MixpanelClient.trackEvent({
      event: MixpanelEvent.Click,
      properties: {
        field: 'Select Language',
        value: language,
        source: pathname,
      },
    });
    onSelect(language);
    updateUserLanguage(language);
    setIsModalOpen(false);
  };

  const getLanguageOptions = () => {
    return allowedLanguages.map((language) => ({
      label: getLanguageName(language),
      value: language,
    }));
  };

  useEffect(() => {
    setIsFirstMount(false);
  }, []);

  useEffect(() => {
    setIsModalOpen(isLanguageSelectorOpen);
  }, [isLanguageSelectorOpen]);

  return (
    <>
      {isModalOpen ? (
        <Modal
          isOpen={isModalOpen || isFirstMount}
          title={
            <Typography variant='h3' mb={2}>
              {noAvailableAppointments
                ? `There are not available providers for this language. Please try selecting another one.`
                : 'Please choose your preferred language for your appointment.'}
            </Typography>
          }
          subtitle='You can change your selection later.'
          onClose={handleClose}>
          <RadioButtons
            name='Language'
            value={language}
            onChange={(e) => setLanguage(e.target.value as AvailableLanguages)}
            trackingDisabled
            options={getLanguageOptions()}
          />
        </Modal>
      ) : (
        <ChangeLanguageLink
          language={getLanguageName(language)}
          onClick={() => {
            onClickLink(true);
          }}
        />
      )}
    </>
  );
};

export default LanguageSelector;
