import { ProgramType, Step, useViewport } from '@enaratech/funnel-helper';
import { Box, Typography } from '@mui/material';
import { useAuth } from 'src/contexts/auth';
import SchedulingSteps from './SchedulingSteps/SchedulingSteps';

const SSBTitle = ({
  pullingAppointments,
  programType,
  steps,
}: {
  pullingAppointments: boolean;
  programType: ProgramType;
  steps: Step[];
}) => {
  const {
    authState: { user },
  } = useAuth();

  const { isMobileView, isTabletView } = useViewport();

  let titleDescription = null;

  const currentStep = steps.find((s) => s.active);

  const canRenderTitleDescription = !pullingAppointments && programType && currentStep;

  if (canRenderTitleDescription) {
    titleDescription = `${user!.firstName}, let's set up your ${
      currentStep.details.description
    } appointment`;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <SchedulingSteps steps={steps} />
      {canRenderTitleDescription && (
        <Typography
          variant={'h1'}
          sx={{
            display: 'flex',
            textAlign: 'center',
            width: isMobileView || isTabletView ? '80%' : '50%',
          }}>
          {titleDescription}
        </Typography>
      )}
    </Box>
  );
};

export default SSBTitle;
