import {
  ContactMethod,
  ENVIRONMENT,
  FUNNEL_VERSION,
  LeadActivityType,
  STATSIG_TOKEN,
  StatsigManager,
  onboardingClient,
} from '@enaratech/funnel-helper';
import {
  FC,
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { expandExperimentUser } from '../../lib/experiments';
import { useAuth } from '../auth';
import { useOnboarding } from '../onboarding';
import {
  ExperimentActionTypes,
  ExperimentContextValue,
  ExperimentState,
  SET_USER_INITIALIZATION,
  SET_USER_METADATA,
} from './types';

const ExperimentContext = createContext<ExperimentContextValue | undefined>(undefined);

const initialState: ExperimentState = {
  isInitialized: false,
  user: {},
};

const experimentReducer = (
  prevState: ExperimentState,
  action: ExperimentActionTypes
): ExperimentState => {
  const { type, payload } = action;

  switch (type) {
    case SET_USER_METADATA:
      return { ...prevState, user: { ...prevState.user, ...payload } };

    case SET_USER_INITIALIZATION:
      return { ...prevState, isInitialized: payload };

    default:
      return prevState;
  }
};

export const ExperimentProvider: FC<{ children: ReactNode }> = ({ children }): ReactElement => {
  const [params] = useSearchParams();
  const {
    authState: { uuid, user, loaded },
  } = useAuth();

  const {
    onboardingState: { eligibility },
  } = useOnboarding();

  const [experimentState, dispatchExperimentState] = useReducer(experimentReducer, initialState);

  const loadCustomUserData = useCallback(() => {
    if (!user) {
      return {
        userID: uuid,
        appVersion: FUNNEL_VERSION,
        custom: {
          utmSource: params.get('utm_source') || '',
        },
      };
    }

    return expandExperimentUser(user.id, {
      userID: user.uuid,
      appVersion: FUNNEL_VERSION,
      custom: {
        ...user,
        systemSource: user.systemSource || ContactMethod.Funnel,
        clinicId: user.clinicId,
        membershipType: eligibility?.membershipType as string,
        utmSource: params.get('utm_source') || '',
        insuranceOutcome: user.insuranceOutcome || '',
        programType: user.programType || '',
      },
      email: user.email,
    });
  }, [user, uuid, eligibility?.membershipType, params]);

  const sendSummaryToCrm = async () => {
    await onboardingClient.emitCrmActivity({
      activityType: LeadActivityType.ExperimentEvent,
      activityNote: StatsigManager.getExperimentsSummaryAsString(),
    });
  };

  useEffect(() => {
    if ((!uuid && !!!user) || !loaded) {
      // Can not init experiment yet
      return;
    }

    const userData = { ...loadCustomUserData(), ...experimentState.user };

    (async () => {
      if (experimentState.isInitialized) {
        await StatsigManager.setUser(userData);

        sendSummaryToCrm();
      } else {
        await StatsigManager.initialize({
          isEnabled: !!STATSIG_TOKEN,
          token: STATSIG_TOKEN,
          environment: ENVIRONMENT === 'beta' ? 'production' : ENVIRONMENT,
          user: userData,
        });

        dispatchExperimentState({
          type: SET_USER_INITIALIZATION,
          payload: true,
        });

        sendSummaryToCrm();
      }
    })();
  }, [user, uuid, loaded, loadCustomUserData, experimentState]);

  return (
    <ExperimentContext.Provider value={{ experimentState, dispatchExperimentState }}>
      {children}
    </ExperimentContext.Provider>
  );
};

export const useExperiment = (): ExperimentContextValue => {
  const experimentContext = useContext(ExperimentContext);

  if (!experimentContext) {
    throw new Error('useExperiment must be used within a ExperimentContext.Provider');
  }

  return experimentContext;
};
