import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';
import LoadingIndicatorIcon from 'src/components/Common/Icons/LoadingIndicatorIcon';
import './scss/circularProgress.scss';

const LoadingIndicator: FC = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        marginTop: '100px',
      }}>
      <CircularProgress
        size={285}
        sx={{
          position: 'absolute',
        }}
      />
      <LoadingIndicatorIcon />
    </Box>
  );
};

export default LoadingIndicator;
