import { getSuffixForDate, scheduleCoreClient } from '@enaratech/funnel-helper';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import EnaraInfo from 'src/components/Common/EnaraInfo/EnaraInfo';
import DatePickerIcon from 'src/components/Common/Icons/DatePickerIcon';
import LoadingIndicator from 'src/components/Common/LoadingIndicator/LoadingIndicator';
import NextSteps from 'src/components/Common/NextSteps/NextSteps';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth/index';
import { useOnboarding } from 'src/contexts/onboarding';
import './scss/msCongrats.scss';

const MemberSuccessBookingResult: FC = () => {
  const [appointmentInfo, setAppointmentInfo] = useState<{
    scheduledDate: string;
    providerName: string;
  } | null>(null);
  const [pullingAppointment, setPullingAppointment] = useState<boolean>(true);

  const {
    authState: { user },
  } = useAuth();

  const {
    onboardingState: { eligibility },
  } = useOnboarding();

  useEffect(() => {
    (async () => {
      const memberSuccessSchedule = await scheduleCoreClient.getAppointmentByAppointmentId(
        user!.initialAppointmentId
      );

      if (memberSuccessSchedule) {
        const dateTime = DateTime.fromISO(memberSuccessSchedule.datetime);

        setAppointmentInfo({
          scheduledDate: `${dateTime.toFormat(
            `LLLL dd'${getSuffixForDate(memberSuccessSchedule.datetime)}', yyyy`
          )} at ${dateTime.toFormat('hh:mm a')}`,
          providerName: memberSuccessSchedule.calendar,
        });
      }

      setPullingAppointment(false);
    })();
  }, []);

  return (
    <BasicLayout
      title={`Congratulations, ${user?.firstName}!  You achieved your first goal`}
      {...(!pullingAppointment && {
        subtitle: `We’ve confirmed your appointment with our Member Success Specialist, ${appointmentInfo?.providerName}:`,
      })}
      className='remove-line-separator'>
      {pullingAppointment ? (
        <Stack display='flex' flexDirection={'column'} alignItems={'center'}>
          <LoadingIndicator />
        </Stack>
      ) : (
        <>
          <Box className='scheduled-appointment'>
            <DatePickerIcon />
            <Typography className='scheduled-appointment-label' variant='h3'>
              {appointmentInfo?.scheduledDate}
            </Typography>
          </Box>
          {eligibility.membershipType ? <NextSteps isSSB={false} /> : <EnaraInfo />}
        </>
      )}
    </BasicLayout>
  );
};

export default withProgress(MemberSuccessBookingResult, Page.MemberSuccessBookingResult);
