import { PaymentMode } from 'src/components/Common/PaymentType/paymentType.types';
import {
  ANNUAL_PLAN_DURATION,
  MONTHLY_PLAN_DURATION,
} from 'src/pages/Payment/Inputs/Payment.types';
import { create } from 'zustand';

type State = {
  paymentMode: PaymentMode;
  isMonthly: boolean;
  duration: number;
};

type Action = {
  updatePaymentMode: (mode: PaymentMode) => void;
};

export const useMembershipStore = create<State & Action>((set) => ({
  paymentMode: PaymentMode.Monthly,
  isMonthly: true,
  duration: MONTHLY_PLAN_DURATION,
  updatePaymentMode: (mode) =>
    set(() => ({
      paymentMode: mode,
      isMonthly: mode === PaymentMode.Monthly,
      duration: mode === PaymentMode.Monthly ? MONTHLY_PLAN_DURATION : ANNUAL_PLAN_DURATION,
    })),
}));
