export interface DiscountInfo {
  discountMonthly: number;
  discountAnnually: number;
  coupon: number;
  duration: number | null;
}

export enum PaymentMode {
  Annual = 'annual',
  Monthly = 'monthly',
}

export enum PaymentModeMapping {
  'annual' = 12,
  'monthly' = 1,
}
