import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { FC, ReactNode, useState } from 'react';
import { useAuth } from 'src/contexts/auth';
import { useClinic } from 'src/contexts/clinic';
import { useOnboarding } from 'src/contexts/onboarding';

type Props = {
  onLogout: () => void;
};

const UserMenu: FC<Props> = ({ onLogout }) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const { authState } = useAuth();
  const { clinicState } = useClinic();
  const { onboardingState } = useOnboarding();

  const handleClose = () => {
    setAnchorElement(null);
  };

  const renderStaticMenuEntry = (text: string, value: string | null): ReactNode => {
    return (
      <Box
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        padding={0.5}
        gap={0.5}
        flexWrap={'wrap'}>
        <Typography variant={'h6'}>{text}</Typography>
        <Typography variant='caption'>{value ?? 'Unknown'}</Typography>
      </Box>
    );
  };

  const { isReferral, user } = authState;
  const { details, program } = clinicState!;
  const { eligibility, magicLink } = onboardingState;

  return (
    <>
      <IconButton
        onClick={({ currentTarget }: React.MouseEvent<HTMLElement>) =>
          setAnchorElement(currentTarget)
        }
        size='small'
        className='logout-progress-bar'>
        <Avatar />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={anchorElement !== null}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            maxWidth: 220,
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <Typography variant={'h6'} margin={0.5}>
          Personal Info
        </Typography>

        <Divider />
        {renderStaticMenuEntry('Member ID', `${user!.id}`)}
        {renderStaticMenuEntry('Referral', `${isReferral}`)}
        {renderStaticMenuEntry('Magic Link', `${!!magicLink}`)}
        {renderStaticMenuEntry('Email', user!.email)}
        {renderStaticMenuEntry('Phone', user!.phone)}
        {renderStaticMenuEntry('Full Name', `${user!.firstName} ${user!.lastName}`)}
        {renderStaticMenuEntry('Date of Birth', user!.dateOfBirth)}
        {renderStaticMenuEntry('Insurance Company', user!.insuranceCompany)}
        <Divider />

        <Typography variant={'h6'} margin={0.5}>
          Clinic Info
        </Typography>

        <Divider />
        {renderStaticMenuEntry('Clinic Id', `${details.clinicId}`)}
        {renderStaticMenuEntry('Zip code', details.zipCode)}
        {renderStaticMenuEntry('Program Type', user?.programType ?? null)}
        <Divider />

        <Typography variant={'h6'} margin={0.5}>
          Membership Info
        </Typography>

        <Divider />
        {renderStaticMenuEntry('Membership Type', eligibility.membershipType)}
        <Divider />

        <MenuItem onClick={onLogout}>
          <Typography variant={'h6'}>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
