import { ElementTracker, ElementTrackerType } from '@enaratech/funnel-helper';
import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, withProgress } from 'src/components/Common/Progress/IPO/IPOProgress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { IPOPath, navigateToPage } from '../../routes';
import { FAQ_DETAILS } from './assets/questions';
import './scss/faq.scss';

const FAQ: FC = () => {
  const navigate = useNavigate();
  const [mode, setMode] = useState<boolean[]>(FAQ_DETAILS.map(() => false));
  const routePath = useRoutePath();

  const handleClick = () => {
    navigateToPage({ targetPage: IPOPath.NewMemberProfile, navigate });
  };

  return (
    <BasicLayout
      title=''
      buttonProps={{
        onClick: handleClick,
      }}
      back>
      <>
        {FAQ_DETAILS.map((faq, index) => (
          <div key={`faq-${index}`}>
            <ElementTracker
              routePath={routePath}
              name={faq.question.replace('?', '')}
              type={ElementTrackerType.Clickable}>
              <Typography
                variant={'body1'}
                className={'typography-question'}
                onClick={() =>
                  setMode((prevState) => {
                    let updated = [...prevState];
                    updated[index] = !updated[index];
                    return updated;
                  })
                }>
                {faq.question}
              </Typography>
            </ElementTracker>
            <Box mb={4}>
              {mode[index] &&
                faq.answers &&
                faq.answers.map((answer, answerIndex) => (
                  <ul className='ul-disc ul-answer' key={`answer-${answerIndex}`}>
                    <li>{answer.title}</li>
                    {answer.phrases.map((phrase, phraseIndex) => (
                      <ul className='ul-circle' key={`phrase-${phraseIndex}`}>
                        <li>{phrase.phrase}</li>
                        <ul className='ul-square'>
                          {phrase.items.map((item, itemIndex) => (
                            <li key={`item-${itemIndex}`}>{item}</li>
                          ))}
                        </ul>
                      </ul>
                    ))}
                  </ul>
                ))}
            </Box>
          </div>
        ))}
      </>
    </BasicLayout>
  );
};

export default withProgress(FAQ, Page.FAQ);
