import { FC, ReactElement, ReactNode, createContext, useContext, useReducer } from 'react';
import { ClinicActionTypes, ClinicContextValue, ClinicState, SET_CLINIC_INFO } from './types';

const ClinicContext = createContext<ClinicContextValue | undefined>(undefined);

const initialState: ClinicState = null;

const clinicReducer = (prevState: ClinicState, action: ClinicActionTypes): ClinicState => {
  const { type, payload } = action;

  switch (type) {
    case SET_CLINIC_INFO:
      return payload;

    default:
      return prevState;
  }
};

export const ClinicProvider: FC<{ children: ReactNode }> = ({ children }): ReactElement => {
  const [clinicState, dispatchClinic] = useReducer(clinicReducer, initialState);

  return (
    <ClinicContext.Provider value={{ clinicState, dispatchClinic }}>
      {children}
    </ClinicContext.Provider>
  );
};

export const useClinic = (): ClinicContextValue => {
  const clinicContext = useContext(ClinicContext);

  if (!clinicContext) {
    throw new Error('useClinic must be used within a ClinicContext.Provider');
  }

  return clinicContext;
};
