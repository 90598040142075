import { MEMBERSHIP_SPECIALIST_PHONE_NUMBER } from '@enaratech/funnel-helper';
import { Typography } from '@mui/material';
import { FC } from 'react';
import EnaraInfo from 'src/components/Common/EnaraInfo/EnaraInfo';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';

const LowBMI: FC = () => {
  return (
    <BasicLayout
      title='Thanks for your interest in Enara Health!'
      subtitle={
        <Typography variant={'h4'}>
          According to the responses provided you do not meet the medical criteria for our weight
          loss program.{' '}
          <span className='low-bmi-box'>
            If you think we got this wrong, please call{' '}
            <a href={`tel:${MEMBERSHIP_SPECIALIST_PHONE_NUMBER}`}>
              {MEMBERSHIP_SPECIALIST_PHONE_NUMBER}
            </a>{' '}
            to speak to a Member Success Specialist
          </span>
        </Typography>
      }
      back>
      <EnaraInfo />
    </BasicLayout>
  );
};

export default withProgress(LowBMI, Page.LowBMI);
