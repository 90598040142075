import { UserBase } from '@enaratech/funnel-helper';
import { Dispatch } from 'react';
import { StatsigUser } from 'statsig-js';

export type ExperimentContextValue = {
  experimentState: ExperimentState;
  dispatchExperimentState: Dispatch<ExperimentActionTypes>;
};

export type ExperimentState = {
  isInitialized: boolean;
  user: Partial<UserBase> | StatsigUser;
};

export const SET_USER_METADATA = '@enara/types/set-user-metadata';
export const SET_USER_INITIALIZATION = '@enara/types/set-user-initialization';

export type SetUserMetadataType = {
  type: typeof SET_USER_METADATA;
  payload: UserBase | StatsigUser;
};

export type SetUserInitializationType = {
  type: typeof SET_USER_INITIALIZATION;
  payload: boolean;
};

export type ExperimentActionTypes = SetUserInitializationType | SetUserMetadataType;
