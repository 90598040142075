import { ElementTracker, ElementTrackerType } from '@enaratech/funnel-helper';
import { TextField, TextFieldProps } from '@mui/material';
import {
  DateTimePickerProps,
  DesktopDatePicker as MuiDesktopDatePicker,
} from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { FC } from 'react';
import DatePickerIcon from 'src/components/Common/Icons/DatePickerIcon';
import { useRoutePath } from 'src/hooks/useRoutePath';

interface Props extends Pick<DateTimePickerProps<DateTime, DateTime>, 'onChange' | 'value'> {
  error?: TextFieldProps['error'];
  errorMessage?: TextFieldProps['helperText'];
  inputProps: TextFieldProps;
}

const DesktopDatePicker: FC<Props> = ({ error, errorMessage, inputProps, onChange, value }) => {
  const routePath = useRoutePath();

  return (
    <MuiDesktopDatePicker
      components={{ OpenPickerIcon: DatePickerIcon }}
      disableFuture
      InputAdornmentProps={{ position: 'start' }}
      InputProps={{ disableUnderline: true, sx: { borderRadius: '4px' } }}
      inputFormat='MM/dd/yyyy'
      onChange={onChange}
      openTo='day'
      renderInput={(params) => (
        <ElementTracker routePath={routePath} name='Birthdate' type={ElementTrackerType.Blurrable}>
          <TextField
            id='desktopDatePicker'
            {...inputProps}
            {...params}
            error={error}
            helperText={error && errorMessage}
            hiddenLabel
            inputProps={{ ...params.inputProps, placeholder: 'MM/DD/YYYY' }}
            InputProps={{ ...params.InputProps }}
            onChange={(e) => onChange(DateTime.fromFormat(e.target.value, 'MM/dd/yyyy'))}
            variant='filled'
            sx={(theme) => ({
              '.MuiFilledInput-root': {
                backgroundColor: theme.palette.warning.light,
              },
            })}
          />
        </ElementTracker>
      )}
      value={value}
    />
  );
};

export default DesktopDatePicker;
