import { InputFieldsType } from '@enaratech/funnel-helper';

export const inputFields: InputFieldsType[] = [
  {
    label: 'Your password',
    field: 'password',
    name: 'Password',
    track: false,
  },
  {
    label: 'Confirm password',
    field: 'confirmPassword',
    name: 'Confirm Password',
    track: false,
  },
];

export const PLAN_OPTIONS = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Annual',
    value: 'annual',
  },
];
