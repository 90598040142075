import {
  clinicClient,
  ElementTracker,
  ElementTrackerType,
  FunnelErrors,
  getAnonymousLeadProgress,
  getAnonymousProgress,
  getPartnerByZipCode,
  getPartners,
  isFunnelError,
  isZipCodeValid,
  notifyIfNoAvailablePartner,
  onboardingClient,
  Partner,
  ProgramType,
  ProgressKey,
  reportErrorToHoneybadger,
  storeAnonymousProgress,
  UNKNOWN_CLINIC_ID,
} from '@enaratech/funnel-helper';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { createRef, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useClinic } from 'src/contexts/clinic';
import { SET_CLINIC_INFO } from 'src/contexts/clinic/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { navigateToPage } from '../routes';
import PartnerOptions from './PartnerOptions/PartnerOptions';
import './scss/partners.scss';

const ZIP_CODE_FIELD_NAME = 'zipCode';

const Partners: FC = () => {
  const paperRef = createRef<HTMLDivElement>();
  const [clinicId, setClinicId] = useState<number | null>(null);
  const [formState, setFormState] = useState<{ zipCode: string }>({ zipCode: '' });
  const [errors, setErrors] = useState<{ zipCode: string } | {}>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [partners, setPartners] = useState<Partner[]>([]);

  const navigate = useNavigate();

  const { dispatchClinic } = useClinic();
  const routePath = useRoutePath();

  useEffect(() => {
    (async () => {
      const partners = await getPartners();

      setPartners(partners);

      const anonymousProgress = getAnonymousProgress();

      if (anonymousProgress) {
        const form = anonymousProgress[ProgressKey.Partner];

        if (form) {
          const partner = getPartnerByZipCode(form.zipCode, partners);

          setClinicId(partner?.clinicId ?? UNKNOWN_CLINIC_ID);
          setFormState({ zipCode: partner?.zipCode.toString() ?? form.zipCode });
        }
      }
    })();
  }, []);

  const validateForm = (field: string, value: string) => {
    let errorMessage = '';
    if (!value) {
      errorMessage = `This field is required`;
    } else if (field === 'zipCode' && value && !isZipCodeValid(value)) {
      errorMessage = 'Please enter a valid zip code';
    }

    setErrors({ ...errors, [field]: errorMessage });
  };

  const handleChangePartner = async (partner: Partner) => {
    setClinicId(partner.clinicId);
    setFormState({ zipCode: partner.zipCode?.toString() ?? '' });

    if (partner.clinicId === UNKNOWN_CLINIC_ID) {
      paperRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClick = async (): Promise<void> => {
    setIsSubmitting(true);

    try {
      const clinicInfo = await clinicClient.fetchClosestClinicByZipCode(formState.zipCode, false);

      storeAnonymousProgress(ProgressKey.Partner, { zipCode: formState.zipCode, clinicId });

      dispatchClinic({ type: SET_CLINIC_INFO, payload: { ...clinicInfo, partners } });

      if (clinicInfo.program === ProgramType.NotCovered) {
        navigateToPage({ targetPage: '/not-available', navigate });
      } else {
        notifyIfNoAvailablePartner({ rawZipCode: formState.zipCode, clinic: clinicInfo });

        const leadData = getAnonymousLeadProgress();
        if (leadData) {
          onboardingClient.upsertLead(leadData);
        }

        navigateToPage({ targetPage: '/create-account', navigate });
      }
    } catch (error) {
      if (
        isFunnelError(error, FunnelErrors.BadZipCode) ||
        isFunnelError(error, FunnelErrors.NoClinicInfo)
      ) {
        Toast.notification('error', (error as Error).message);
      } else {
        reportErrorToHoneybadger({ error });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BasicLayout
      title='Are you already a patient of one our partner clinics?'
      buttonProps={{
        disabled: !clinicId || !isZipCodeValid(formState.zipCode),
        loading: isSubmitting,
        onClick: handleClick,
      }}
      back>
      <Stack spacing='24px'>
        <Box className='radio-box'>
          <PartnerOptions clinicId={clinicId} onChange={handleChangePartner} />
        </Box>
        <Stack spacing='24px' />
        <div ref={paperRef}>
          <Box
            data-test-id='partners-combobox'
            display={clinicId === UNKNOWN_CLINIC_ID ? 'block' : 'none'}>
            <Typography paragraph variant={'h4'}>
              What is your Zip Code?
            </Typography>
            <Typography paragraph variant={'body2'}>
              This will help us find your nearest care team
            </Typography>
            <ElementTracker
              routePath={routePath}
              name='Zip Code'
              type={ElementTrackerType.Blurrable}>
              <TextField
                id={'zip-code-filled-basic'}
                label={'Enter your Zip Code'}
                variant={'filled'}
                type={'text'}
                value={formState.zipCode || ''}
                className='zip-code-text-field w-full'
                autoComplete={ZIP_CODE_FIELD_NAME}
                onChange={(e) => {
                  setFormState({ ...formState, [ZIP_CODE_FIELD_NAME]: e.target.value });
                  validateForm(ZIP_CODE_FIELD_NAME, e.target.value);
                }}
                onBlur={(e) => {
                  validateForm(ZIP_CODE_FIELD_NAME, e.target.value);
                }}
                error={!!errors[ZIP_CODE_FIELD_NAME as keyof typeof errors]}
                helperText={
                  !!errors[ZIP_CODE_FIELD_NAME as keyof typeof errors]
                    ? errors[ZIP_CODE_FIELD_NAME as keyof typeof errors]
                    : ''
                }
              />
            </ElementTracker>
          </Box>
        </div>
      </Stack>
    </BasicLayout>
  );
};

export default withProgress(Partners, Page.Partners);
