import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EnaraListItemIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox='0 0 18 18' style={{ width: 11, height: 13 }} {...props}>
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='M12.4497 10.186C11.9131 11.162 11.0402 11.9149 9.96708 12.3193C8.20708 12.9816 6.21098 12.5563 4.8874 11.2526L4.5726 
        10.9389L16.0198 6.65829L15.4832 5.29881C13.9521 1.42256 9.47342 -0.522542 5.49553 0.962425C1.51764 2.45436 -0.478453 
        6.81863 1.0526 10.6949C2.58366 14.5711 7.06236 16.5162 11.0402 15.0243C12.7359 14.3899 14.1167 13.2117 14.9895 
        11.7058L12.4497 10.186ZM3.52805 7.7877C3.61391 5.95415 4.8087 4.33672 6.5687 3.68138C8.3287 3.01907 10.3248 3.44434 
        11.6484 4.74805L11.9632 5.06177L3.50659 8.22691L3.52805 7.7877Z'
      fill='#278BA5'
    />
  </SvgIcon>
);

export default EnaraListItemIcon;
