import {
  AvailableHours,
  AvailableTimeSlotData,
  DatesWithPrioritizedProviders,
  getNextDays,
  MixpanelClient,
  MixpanelEvent,
  reportErrorToHoneybadger,
  ScheduledAppointment,
  useViewport,
} from '@enaratech/funnel-helper';
import { Box, Typography } from '@mui/material';
import { CalendarPicker, PickersDay } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { DEFAULT_DAYS_TO_SKIP_BETWEEN_APPOINTMENTS } from '../../SelfServeBooking/constants';
import { getTimeSlotKey } from '../../SelfServeBooking/rules/appointments';
import { AvailableProvider } from '../../SelfServeBooking/rules/ssbFlow/types';
import TimePicker from '../TimePicker/TimePicker';
import './scss/appointmentsScheduler.scss';

export type AppointmentsSchedulerRef = {
  resetCalendar: (previousAppointment?: ScheduledAppointment | null) => void;
};

export type SchedulePerDay = {
  [date: string]: AvailableHours[];
};

const MAX_RETRIES_TO_GET_MONTH_AVAILABILITY = 4;

type Props = {
  initialDaysToSkip: number;
  previousAppointment?: ScheduledAppointment | null;
  onSelectHour: (selectedAvailability: string | null) => void;
  getCurrentAvailability: (
    currentDate: DateTime,
    availableProvider: AvailableProvider[]
  ) => Promise<AvailableTimeSlotData[] | null>;
  getAvailableDatesInMonth?: (
    month: string | null
  ) => Promise<DatesWithPrioritizedProviders | null> | null;
  onNoAvailabilityFound: (noAvailability: boolean) => void;
};

const AppointmentsScheduler: ForwardRefRenderFunction<AppointmentsSchedulerRef, Props> = (
  {
    initialDaysToSkip,
    previousAppointment,
    onSelectHour,
    getCurrentAvailability,
    getAvailableDatesInMonth,
    onNoAvailabilityFound,
  },
  ref: ForwardedRef<AppointmentsSchedulerRef>
) => {
  const [currentDate, setCurrentDate] = useState<DateTime | null>(null);
  const [availableTimes, setAvailableTimes] = useState<AvailableHours[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMonth, setLoadingMonth] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState<boolean>(true);

  const daysOffRef = useRef<string[]>([]);
  const availabilityByDatesRef = useRef<DatesWithPrioritizedProviders | null>(null);

  const routePath = useRoutePath();
  const { isDesktopView, isMobileView, isTabletView } = useViewport();

  const resetCalendar = async (previousAppointment?: ScheduledAppointment | null) => {
    daysOffRef.current = [];
    availabilityByDatesRef.current = null;
    setCurrentDate(null);
    setAvailableTimes(null);
    setShowCalendar(true);

    // TODO: Move this logic outside the component (it should calculate days off outside)
    calculateDaysOff(previousAppointment);

    // TODO: Move this logic outside the component
    if (previousAppointment) {
      const startDate = DateTime.fromISO(previousAppointment.datetime).plus({
        day: DEFAULT_DAYS_TO_SKIP_BETWEEN_APPOINTMENTS,
      });
      await getAvailableDatesByMonth(startDate);
    } else {
      const startDate = DateTime.local();
      await getAvailableDatesByMonth(startDate);
    }
  };

  /**
   * @description Gets the available time slots for the given date
   */
  const getAvailabilityByDate = useCallback(
    async (pickedDate: DateTime | undefined | null) => {
      if (!pickedDate || !availabilityByDatesRef.current) {
        return;
      }

      // Mark as loading
      setLoading(true);

      // Clean available times
      setAvailableTimes(null);

      // Set current date selected
      setCurrentDate(pickedDate);

      const selectedDate = pickedDate.toISODate();

      MixpanelClient.trackEvent({
        event: MixpanelEvent.InputData,
        properties: {
          source: routePath,
          field: 'Appointment Calendar',
          value: selectedDate,
        },
      });

      const providersAvailableForSelectedDate = availabilityByDatesRef.current[selectedDate];

      if (!providersAvailableForSelectedDate) {
        setLoading(false);
        return;
      }

      const currentAvailability = await getCurrentAvailability(
        pickedDate,
        providersAvailableForSelectedDate
      );

      if (!currentAvailability) {
        setLoading(false);
        return;
      }

      const availableTimesAsOptions = currentAvailability.map((slot) => ({
        label: slot.localDateTime,
        value: getTimeSlotKey(slot),
      }));

      setAvailableTimes(availableTimesAsOptions as AvailableHours[]);

      setLoading(false);
    },
    [routePath, getCurrentAvailability]
  );

  /**
   * @description Gets the available dates in the given month
   */
  const getAvailableDatesByMonth = useCallback(
    async (date: DateTime | undefined | null) => {
      if (!date || !getAvailableDatesInMonth || !date.isValid || loadingMonth) {
        return;
      }

      const month = date.toFormat('yyyy-MM');

      onSelectHour(null);

      setAvailableTimes(null);

      const thereAreAvailableDatesCached = availabilityByDatesRef.current?.[date.toISODate()];

      let availableDates: string[];

      let firstAvailableDateInMonth: string | undefined;

      if (thereAreAvailableDatesCached) {
        availableDates = Object.keys(availabilityByDatesRef.current ?? {});

        // Verify that it is not a day deactivated by rules and also belongs to the cached month
        firstAvailableDateInMonth = availableDates.find(
          (availableDate) =>
            !daysOffRef.current.includes(availableDate) && availableDate.includes(month)
        );
      }

      let monthToFetch = date;

      let retries = 1;

      setLoadingMonth(true);
      do {
        const response = await getAvailableDatesInMonth(monthToFetch.toFormat('yyyy-MM'));

        availableDates = Object.keys(response ?? {});

        availabilityByDatesRef.current = { ...availabilityByDatesRef.current, ...response };

        firstAvailableDateInMonth = availableDates.find(
          (availableDate) => !daysOffRef.current.includes(availableDate)
        );

        if (!firstAvailableDateInMonth) {
          monthToFetch = monthToFetch.plus({ month: 1 });
          retries += 1;
        }
      } while (!firstAvailableDateInMonth && retries <= MAX_RETRIES_TO_GET_MONTH_AVAILABILITY);

      setLoadingMonth(false);

      if (!firstAvailableDateInMonth) {
        onNoAvailabilityFound(true);

        return reportErrorToHoneybadger({
          error: `No appointments available after maximum retries. Last month tried: ${monthToFetch}`,
        });
      }

      onNoAvailabilityFound(false);

      getAvailabilityByDate(DateTime.fromISO(firstAvailableDateInMonth));
    },
    [
      loadingMonth,
      availabilityByDatesRef,
      getAvailableDatesInMonth,
      getAvailabilityByDate,
      onSelectHour,
    ]
  );

  /**
   * @description Gets the days disabled according to certain rules
   */
  const disableDay = (timestamp: DateTime | undefined) => {
    if (!timestamp || !availabilityByDatesRef.current) {
      return true;
    }

    const currentDate = timestamp.toISODate();

    const isAnAvailableDate = Object.keys(availabilityByDatesRef.current).includes(currentDate);

    const isADisabledDate = daysOffRef.current.includes(currentDate);

    if (!isAnAvailableDate || isADisabledDate) {
      return true;
    }

    return false;
  };

  /**
   * @description Precalculation of deactivated days
   */
  const calculateDaysOff = (previousAppointment: ScheduledAppointment | null | undefined) => {
    const today = new Date(DateTime.local().toFormat('yyyy-MM-dd'));

    const dateToCompare = previousAppointment
      ? new Date(previousAppointment.datetime.substring(0, 10))
      : today;

    const diffOfDays = Math.ceil(
      Math.abs(Number(dateToCompare) - Number(today)) / (1000 * 60 * 60 * 24)
    );

    daysOffRef.current = getNextDays(initialDaysToSkip + diffOfDays);
  };

  useImperativeHandle(ref, () => ({
    resetCalendar,
  }));

  // When you load the component for the first time, we need to know the availability
  useEffect(() => {
    (async () => {
      await resetCalendar(previousAppointment);
    })();
  }, []);

  useEffect(() => {
    if (isMobileView || isTabletView) {
      if (currentDate) {
        setShowCalendar(false);
      }
    }

    if (isDesktopView) {
      setShowCalendar(true);
    }
  }, [isMobileView, isDesktopView, isTabletView, currentDate]);

  return (
    <Box display='flex' flexDirection='column' rowGap={3}>
      <Typography variant='h4'>Please select the date and time:</Typography>
      <div className='appointment-container'>
        {showCalendar && (
          <CalendarPicker
            disabled={loading || loadingMonth}
            className='appointment-date-picker'
            disablePast
            openTo='day'
            date={currentDate}
            views={['day', 'month']}
            renderDay={(day, _, props) => (
              <PickersDay {...props}>
                <span className='custom-day'>{day.toFormat('dd')}</span>
              </PickersDay>
            )}
            onMonthChange={getAvailableDatesByMonth}
            onChange={getAvailabilityByDate}
            shouldDisableDate={disableDay}
          />
        )}
        {(isMobileView || isTabletView ? !showCalendar : true) && (
          <TimePicker
            currentDate={currentDate}
            options={availableTimes ?? []}
            onPick={onSelectHour}
            isLoading={loading || loadingMonth}
            onShowCalendar={() => setShowCalendar(true)}
          />
        )}
      </div>
    </Box>
  );
};

export default forwardRef(AppointmentsScheduler);
