import { ElementTracker, ElementTrackerType } from '@enaratech/funnel-helper';
import { Box, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { useRoutePath } from 'src/hooks/useRoutePath';

type Props = {
  onClick: () => void;
  language: string;
};

export const ChangeLanguageLink: FC<Props> = ({ language, onClick }) => {
  const pathname = useRoutePath();

  return (
    <Box>
      <Typography variant='body2' component='span' mt={3}>
        {language} Speaking Professionals.{' '}
      </Typography>
      <ElementTracker
        routePath={pathname}
        name='Change'
        type={ElementTrackerType.Clickable}
        value='Change'>
        <Link variant='body2' onClick={onClick} color='primary.main'>
          Change
        </Link>
      </ElementTracker>
    </Box>
  );
};
