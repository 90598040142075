import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CalendarIcon = (props: SvgIconProps) => (
  <SvgIcon style={{ width: 25, height: 28 }} viewBox='0 0 25 28' {...props}>
    <path
      d='M8.5 12.667H5.83333V15.3337H8.5V12.667ZM13.8333 12.667H11.1667V15.3337H13.8333V12.667ZM19.1667 12.667H16.5V15.3337H19.1667V12.667ZM21.8333 3.33366H20.5V0.666992H17.8333V3.33366H7.16667V0.666992H4.5V3.33366H3.16667C1.68667 3.33366 0.513333 4.53366 0.513333 6.00033L0.5 24.667C0.5 26.1337 1.68667 27.3337 3.16667 27.3337H21.8333C23.3 27.3337 24.5 26.1337 24.5 24.667V6.00033C24.5 4.53366 23.3 3.33366 21.8333 3.33366ZM21.8333 24.667H3.16667V10.0003H21.8333V24.667Z'
      fill='#278BA5'
    />
  </SvgIcon>
);

export default CalendarIcon;
