import { Box, Grid, Typography } from '@mui/material';
import AlertIcon from '../../../assets/images/alert-icon.png';

type Props = {
  open: boolean;
  onClose: (open: boolean) => void;
};

export const SchedulingSuggestion = ({ open = false, onClose }: Props) => {
  return (
    <Box mt={8}>
      <Box className='booking-modal'>
        <Box className='booking-modal-close'>
          <Typography variant='h5' onClick={() => onClose(false)}>
            X
          </Typography>
        </Box>
        <Grid container className='booking-modal-container'>
          <Grid item display='flex' xs={12} md={1} pr={1} mb={1} alignItems='center'>
            <img src={AlertIcon} alt='Alert icon' width='30px' />
          </Grid>
          <Grid item xs={12} md={11}>
            <Typography variant='subtitle2' fontSize={14}>
              We encourage you to select the earliest appointment available. Members who schedule
              their initial medical appointment early  are more likely to achieve their weight loss
              goals
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
