import {
  ElementTracker,
  ElementTrackerType,
  EligibilityResult,
  EligibilitySource,
  InsuranceCardFace,
  InsuranceCardFile,
  InsuranceOutcome,
  InsuranceStageStatus,
  LeadOnboardingStage,
  MixpanelClient,
  MixpanelEvent,
  eligibilityClient,
  onboardingClient,
  useViewport,
  userClient,
} from '@enaratech/funnel-helper';
import { Button, Stack, Typography } from '@mui/material';
import { FC, ReactElement, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from 'src/components/Common/LoadingIndicator/LoadingIndicator';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { SET_USER_INSURANCE_COMPANY, SET_USER_INSURANCE_OUTCOME } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { useOnboarding } from 'src/contexts/onboarding';
import { SET_ELIGIBILITY } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { navigateToPage } from '../routes';
import FileUpload from './FileUpload/FileUpload';

const Insurance: FC = (): ReactElement => {
  const [frontFaceImage, setFrontFaceImage] = useState<string>('');
  const [backFaceImage, setBackFaceImage] = useState<string>('');
  const [step, setStep] = useState<InsuranceCardFace | 'checking-eligibility'>(
    InsuranceCardFace.Front
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const frontCardUploadResult = useRef<InsuranceCardFile | null>(null);
  const backCardUploadResult = useRef<InsuranceCardFile | null>(null);

  const eligibilityResult = useRef<EligibilityResult & { done: boolean }>({
    done: false,
    membershipType: null,
    memberInsuranceId: undefined,
    payerName: undefined,
  });

  const routePath = useRoutePath();

  const { isMobileView } = useViewport();
  const { dispatchOnboarding } = useOnboarding();
  const { clinicState } = useClinic();
  const {
    authState: { user, isReferral },
    dispatchAuth,
  } = useAuth();

  const navigate = useNavigate();

  const nextButtonIsDisabled =
    (step === InsuranceCardFace.Front && !frontFaceImage) ||
    (step === InsuranceCardFace.Back && !backFaceImage);

  const resetFiles = () => {
    setStep(InsuranceCardFace.Front);
    setBackFaceImage('');
    setFrontFaceImage('');

    frontCardUploadResult.current = null;
    backCardUploadResult.current = null;
  };

  const handleFileSelection = (base64: string) => {
    if (step === InsuranceCardFace.Front) {
      setFrontFaceImage(base64);
    }

    if (step === InsuranceCardFace.Back) {
      setBackFaceImage(base64);
    }
  };

  const checkEligibility = async (files: {
    insuranceCardFront: InsuranceCardFile;
    insuranceCardBack: InsuranceCardFile;
  }): Promise<void> => {
    const parsingResult = await eligibilityClient.parseUploadedInsuranceCardByStrategiesV2({
      source: EligibilitySource.Funnel,
      programType: user!.programType,
      files,
    });

    const { membershipType, memberInsuranceId, payerName } = parsingResult || {
      membershipType: null,
      memberInsuranceId: undefined,
      payerName: undefined,
    };

    eligibilityResult.current = { done: true, membershipType, memberInsuranceId, payerName };

    MixpanelClient.setMetadata({
      eligibility: !!eligibilityResult.current.membershipType,
    });

    if (eligibilityResult.current.membershipType) {
      const { membershipType, memberInsuranceId, payerName } = eligibilityResult.current;

      MixpanelClient.setMetadata({
        ...(payerName && { insuranceCarrier: payerName }),
        membershipType,
      });

      dispatchOnboarding({ type: SET_ELIGIBILITY, payload: membershipType });

      if (payerName) {
        dispatchAuth({ type: SET_USER_INSURANCE_COMPANY, payload: payerName });
      }

      userClient.updateUser({
        ...(memberInsuranceId && { insuranceId: memberInsuranceId }),
        ...(payerName && { insuranceCompany: payerName }),
      });

      onboardingClient.updateMemberStatus({
        onboardingStage: LeadOnboardingStage.Insurance,
        crm: { insuranceStatus: InsuranceStageStatus.Both },
      });

      navigateToPage({ targetPage: '/coverage', navigate });
    } else {
      userClient.updateUser({ insuranceOutcome: InsuranceOutcome.NotMatched });

      onboardingClient.updateMemberStatus({
        onboardingStage: LeadOnboardingStage.Insurance,
        crm: { insuranceStatus: InsuranceStageStatus.None },
      });

      dispatchAuth({
        type: SET_USER_INSURANCE_OUTCOME,
        payload: InsuranceOutcome.NotMatched,
      });

      navigateToPage({ targetPage: '/appointment/ms-booking', navigate });
    }
  };

  const continueToCheckEligibilityStep = async () => {
    MixpanelClient.setMetadata({ insuranceOption: 'picture-upload' });

    setStep('checking-eligibility');

    if (frontCardUploadResult.current && backCardUploadResult.current) {
      await checkEligibility({
        insuranceCardFront: frontCardUploadResult.current,
        insuranceCardBack: backCardUploadResult.current,
      });
    }
  };

  const continueToUploadBackSideStep = () => setStep(InsuranceCardFace.Back);

  const uploadInsuranceCard = async (
    insuranceCardFace: InsuranceCardFace,
    file: string
  ): Promise<InsuranceCardFile | null> => {
    setIsUploading(true);

    const uploadResult = await eligibilityClient.uploadInsuranceCardV2({
      face: insuranceCardFace,
      base64: file,
    });

    MixpanelClient.trackEvent({
      event: MixpanelEvent.InputData,
      properties: {
        field: `Upload image ${insuranceCardFace} face`,
        value: true,
        source: routePath,
      },
    });

    if (!uploadResult) {
      Toast.notification(
        'error',
        `Could not process the ${insuranceCardFace} from your insurance card`
      );

      resetFiles();

      eligibilityResult.current = {
        done: true,
        membershipType: null,
        memberInsuranceId: undefined,
        payerName: undefined,
      };

      return null;
    }

    const sideCapitalize = insuranceCardFace[0].toUpperCase() + insuranceCardFace.slice(1);

    Toast.notification('success', `${sideCapitalize} side uploaded successfully`);

    setIsUploading(false);

    return uploadResult;
  };

  const onClickNextButton = async () => {
    MixpanelClient.trackEvent({
      event: MixpanelEvent.Click,
      properties: {
        field: `Next Button (${step})`,
        source: routePath,
        value: step,
      },
    });

    if (step === InsuranceCardFace.Front) {
      frontCardUploadResult.current = await uploadInsuranceCard(
        InsuranceCardFace.Front,
        frontFaceImage.replace(/^data:image\/\w+;base64,/, '')
      );
      return continueToUploadBackSideStep();
    }

    if (step === InsuranceCardFace.Back) {
      backCardUploadResult.current = await uploadInsuranceCard(
        InsuranceCardFace.Back,
        backFaceImage.replace(/^data:image\/\w+;base64,/, '')
      );
      return continueToCheckEligibilityStep();
    }
  };

  const getTitle = (): string => {
    if (step === 'checking-eligibility') {
      return 'Checking Eligibility';
    }

    if (step === InsuranceCardFace.Front) {
      return `${user?.firstName}, let's figure out your coverage`;
    }

    return "Great! Let's figure out your coverage";
  };

  const renderFileUploader = (face: InsuranceCardFace): ReactElement => (
    <FileUpload
      face={face}
      text={`${
        isMobileView ? 'Tap' : 'Click'
      } to take a picture of the ${face} of your Insurance Card`}
      onSelectFile={handleFileSelection}
    />
  );

  return (
    <BasicLayout
      title={getTitle()}
      {...(step !== 'checking-eligibility' && {
        subtitle: `Please upload the ${step.toUpperCase()} of your insurance card`,
        buttonProps: {
          disabled: nextButtonIsDisabled,
          onClick: onClickNextButton,
          loading: isUploading,
        },
      })}>
      <Stack display='flex' flexDirection={'column'} alignItems={'center'} spacing={3}>
        {step === 'checking-eligibility' ? (
          <LoadingIndicator />
        ) : (
          <>
            {step === InsuranceCardFace.Front && renderFileUploader(InsuranceCardFace.Front)}

            {step === InsuranceCardFace.Back && renderFileUploader(InsuranceCardFace.Back)}

            {step === InsuranceCardFace.Front && !frontFaceImage && (
              <>
                <ElementTracker
                  routePath={routePath}
                  name='Enter details manually'
                  type={ElementTrackerType.Clickable}>
                  <Button
                    className='border'
                    variant='outlined'
                    data-test='insurance-button-enterDetailsManually'
                    onClick={() => {
                      MixpanelClient.setMetadata({ insuranceOption: 'manually' });

                      navigateToPage({ targetPage: '/manual-insurance', navigate });
                    }}>
                    Enter Details Manually
                  </Button>
                </ElementTracker>
                <Typography paragraph variant={'h4'}>
                  Or
                </Typography>
                <ElementTracker
                  routePath={routePath}
                  name='Set account without insurance'
                  type={ElementTrackerType.Clickable}>
                  <Button
                    variant='text'
                    onClick={() => {
                      MixpanelClient.setMetadata({ insuranceOption: 'without-insurance' });

                      if (!isReferral) {
                        dispatchAuth({
                          type: SET_USER_INSURANCE_OUTCOME,
                          payload: InsuranceOutcome.ContinuedWithoutInsurance,
                        });
                      }

                      navigateToPage({ targetPage: '/coverage', navigate });
                    }}
                    data-test='insurance-button-withoutInsurance'>
                    Create Account Without Insurance
                  </Button>
                </ElementTracker>
              </>
            )}
          </>
        )}
      </Stack>
    </BasicLayout>
  );
};

export default withProgress(Insurance, Page.Insurance);
