import { Grid } from '@mui/material';
import { FC, HTMLAttributes } from 'react';
import EnaraLogoIcon from 'src/components/Common/Icons/EnaraLogoIcon';
import './scss/errorLayout.scss';

type Props = HTMLAttributes<HTMLDivElement>;

const ErrorLayout: FC<Props> = ({ children }) => {
  return (
    <Grid container className='error-layout vh-full'>
      <Grid container item justifyContent='center'>
        <Grid container item xs={12} justifyContent='center' mt={6}>
          <EnaraLogoIcon />
        </Grid>
        {children}
      </Grid>
    </Grid>
  );
};

export default ErrorLayout;
