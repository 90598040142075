import { MembershipType } from '@enaratech/funnel-helper';

export enum ProgressKey {
  NewMemberProfile = 'new-member-profile',
  PhoneVerification = 'phone-verification',
}

export type NewMemberProfileStorageForm = {
  city: string;
  dateOfBirth: string;
  email: string;
  firstName: string;
  gender: string;
  groupNumber: string;
  heightFeet: string;
  heightInches: string;
  insuranceCompany: string;
  insuranceId: string;
  insuranceType: string;
  lastName: string;
  membershipType: MembershipType | null;
  phone: string;
  preferredLanguage: string;
  referringProvider: string;
  state: string;
  street: string;
  weightLbs: string;
  zipCode: string;
};

export type PhoneVerificationStorageForm = {
  phone: string;
  leadRegisteredInCore?: boolean;
};

export type AllProgress = {
  [ProgressKey.NewMemberProfile]: NewMemberProfileStorageForm;
  [ProgressKey.PhoneVerification]: PhoneVerificationStorageForm;
};

const IPO_PROGRESS_KEY = 'ipo-progress';

// TODO: need to re-use the functions that are in the funnel helper lib/storage.ts
export const storeProgress = <T>(entryKey: ProgressKey, progress: T): void => {
  localStorage.setItem(
    IPO_PROGRESS_KEY,
    JSON.stringify({
      ...JSON.parse(localStorage.getItem(IPO_PROGRESS_KEY) || '{}'),
      [entryKey]: progress,
    })
  );
};

export const getProgress = (): AllProgress | null => {
  const progress = localStorage.getItem(IPO_PROGRESS_KEY);
  return progress ? (JSON.parse(progress) as AllProgress) : null;
};

export const wipeOutProgress = (): void => {
  localStorage.removeItem(IPO_PROGRESS_KEY);
};
