import { ElementTracker, ElementTrackerType } from '@enaratech/funnel-helper';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FC, ReactElement, useRef } from 'react';
import Webcam from 'react-webcam';
import CircleCancelIcon from 'src/components/Common/Icons/CircleCancelIcon';
import TakePhotoIcon from 'src/components/Common/Icons/TakePhotoIcon';
import Toast from 'src/components/Common/Toast/Toast';
import { useRoutePath } from 'src/hooks/useRoutePath';

type Props = {
  onCaptureImage: (base64: string) => void;
  onClose: () => void;
};

const Camera: FC<Props> = ({ onCaptureImage, onClose }): ReactElement => {
  const webcamRef = useRef<Webcam>(null);

  const routePath = useRoutePath();

  const handleCaptureImage = (): void => {
    if (!webcamRef.current) {
      return Toast.notification('error', 'No access to the camera');
    }

    const imageSource = webcamRef.current.getScreenshot();

    if (imageSource) {
      onCaptureImage(imageSource);
    } else {
      Toast.notification('error', 'No source image available');
    }
  };

  return (
    <Dialog onClose={onClose} open fullWidth>
      <DialogTitle textAlign={'right'}>
        <ElementTracker
          routePath={routePath}
          name='Close camera'
          type={ElementTrackerType.Clickable}>
          <CircleCancelIcon onClick={onClose} />
        </ElementTracker>
      </DialogTitle>
      <DialogContent className='camera-content'>
        <Webcam
          ref={webcamRef}
          audio={false}
          mirrored={false}
          screenshotFormat='image/jpeg'
          screenshotQuality={1}
          videoConstraints={{ facingMode: 'environment' }}
          imageSmoothing={false}
          forceScreenshotSourceSize={true}
        />
      </DialogContent>
      <DialogActions className='camera-bottom-bar'>
        <ElementTracker routePath={routePath} name='Take photo' type={ElementTrackerType.Clickable}>
          <TakePhotoIcon onClick={handleCaptureImage} />
        </ElementTracker>
      </DialogActions>
    </Dialog>
  );
};

export default Camera;
