import {
  deleteAuth,
  ElementTracker,
  ElementTrackerType,
  FeatureManager,
  getPartnerById,
  getPartners,
  isProductionEnv,
  MixpanelClient,
} from '@enaratech/funnel-helper';
import { Fab, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import EnaraLogoIcon from 'src/components/Common/Icons/EnaraLogoIcon';
import LogoutIcon from 'src/components/Common/Icons/LogoutIcon';
import { useAuth } from 'src/contexts/auth';
import { SET_AUTH_INFO, SET_USER_UUID } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { SET_CLINIC_INFO } from 'src/contexts/clinic/types';
import { useOnboarding } from 'src/contexts/onboarding';
import { CLEAR_ONBOARDING_RESULT } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { navigateToPage } from 'src/pages/routes';
import { Page } from './Progress';
import './scss/progress.scss';
import UserMenu from './UserMenu';

type Props = {
  currentPage: Page;
  allowUsersToLogin?: boolean;
};

const ProgressBar: FC<Props> = ({ currentPage, allowUsersToLogin = true }) => {
  const {
    authState: { loaded, isReferral, user },
    dispatchAuth,
  } = useAuth();

  const { dispatchOnboarding } = useOnboarding();
  const { clinicState, dispatchClinic } = useClinic();

  const routePath = useRoutePath();

  const navigate = useNavigate();

  const allPages = Object.keys(Page).filter((p) => !isNaN(Number(p)));
  const percentage = [
    Page.NotAvailable,
    Page.LowBMI,
    Page.MagicLink,
    Page.Referrals,
    Page.SelfServeBookingResult,
    Page.MemberSuccessBookingResult,
  ].includes(currentPage)
    ? 100
    : ((allPages.indexOf(currentPage.toString()) + 3) * 100) / allPages.length;

  const handleLogout = (): void => {
    // 1) Delete auth from storage
    deleteAuth();

    // 2) Reset mixpanel so we don't keep tracking events for this user
    MixpanelClient.resetIdentity();

    // 3) Let the entire app knows that there's no user anymore
    dispatchAuth({ type: SET_AUTH_INFO, payload: { loaded: true, isReferral: false, user: null } });
    dispatchAuth({ type: SET_USER_UUID, payload: '' });

    dispatchOnboarding({
      type: CLEAR_ONBOARDING_RESULT,
    });

    dispatchClinic({ type: SET_CLINIC_INFO, payload: null });

    navigateToPage({ targetPage: '/login', navigate });
  };

  const renderBranding = (): ReactNode => {
    (async () => {
      const clinicId = clinicState?.details?.clinicId ?? null;

      if (!clinicId || !FeatureManager.shouldUseBrandingForClinic({ clinicId, isReferral })) {
        return null;
      }

      const partners = await getPartners();

      const partner = getPartnerById(clinicId, partners);

      if (!partner) {
        return null;
      }

      const {
        branding: { logoSrc, description },
      } = partner;

      return (
        <div className='partner-logo-progress-bar'>
          <img src={logoSrc} alt={description} />
        </div>
      );
    })();

    return null;
  };

  if (!loaded) {
    return null;
  }

  return (
    <Stack justifyContent={'stretch'} alignItems={'center'} className='container-progress-bar'>
      <Paper elevation={0} square className='logo-progress-bar'>
        {renderBranding()}
        <EnaraLogoIcon />
        {allowUsersToLogin ? (
          user ? (
            !isProductionEnv() ? (
              <UserMenu onLogout={handleLogout} />
            ) : (
              <Tooltip
                data-test-id='common-logoutButton'
                title={`Logout ${user.email}`}
                placement={'left-start'}
                className='logout-progress-bar'>
                <Fab color='primary' size={'small'}>
                  <ElementTracker
                    routePath={routePath}
                    name='Logout button'
                    type={ElementTrackerType.Clickable}>
                    <LogoutIcon onClick={handleLogout} />
                  </ElementTracker>
                </Fab>
              </Tooltip>
            )
          ) : (
            <div className='login-progress-bar'>
              <Typography variant={'h5'}>
                Already have an account?{' '}
                <ElementTracker
                  routePath={routePath}
                  name='Sign in link'
                  type={ElementTrackerType.Clickable}>
                  <NavLink to={'/login'}>Sign in</NavLink>
                </ElementTracker>
              </Typography>
            </div>
          )
        ) : null}
      </Paper>
      <Paper elevation={0} square className='progress-bar'>
        <div style={{ width: `${percentage}%` }} className='bar-progress-bar'></div>
      </Paper>
    </Stack>
  );
};

export default ProgressBar;
