import { ElementTracker, ElementTrackerType } from '@enaratech/funnel-helper';
import { Checkbox, FormControlLabel } from '@mui/material';
import React, { FC, useState } from 'react';
import { useRoutePath } from 'src/hooks/useRoutePath';
import './scss/checkbox.scss';

type Props = {
  id: string;
  label: string;
  defaultChecked: boolean;
  onCheck: (id: string, selected: boolean) => void;
};

const StyledCheckbox: FC<Props> = ({ id, label, defaultChecked, onCheck }) => {
  const [checked, setCheck] = useState<boolean>(false);

  const routePath = useRoutePath();

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const checked = e.target.checked;

    setCheck(checked);
    onCheck(id, checked);
  };

  return (
    <FormControlLabel
      className={checked ? 'checked' : 'unchecked'}
      control={
        <ElementTracker routePath={routePath} type={ElementTrackerType.Checkable} name={label}>
          <Checkbox onChange={handleCheck} checked={defaultChecked} />
        </ElementTracker>
      }
      label={label}
      componentsProps={{ typography: { fontWeight: 700, overflow: 'hidden' } }}
    />
  );
};

export default StyledCheckbox;
