import { ElementTracker, ElementTrackerType, useViewport } from '@enaratech/funnel-helper';
import { Grid, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { useClinic } from 'src/contexts/clinic';
import { useRoutePath } from 'src/hooks/useRoutePath';
import AppMockupWeightsImage from '../../../assets/images/app-mockup-weights.png';
import AppleStoreMobileLogo from '../../../assets/images/apple-store-mobile.png';
import AppleStoreLogo from '../../../assets/images/apple-store.png';
import GooglePlayMobileLogo from '../../../assets/images/google-play-mobile.png';
import GooglePlayLogo from '../../../assets/images/google-play.png';
import HighlightBox from '../HighlightBox/HighlightBox';
import './scss/nextSteps.scss';

type Props = {
  isSSB: boolean;
};

const NextSteps: FC<Props> = ({ isSSB }) => {
  const routePath = useRoutePath();

  const { isDesktopView } = useViewport();
  const { clinicState } = useClinic();

  const renderDefaultStepItems = () => {
    return (
      <>
        <ul className='list-container'>
          <li>
            <Typography variant='subtitle2'>
              Download our mobile app to start your weight loss journey.
            </Typography>
          </li>
        </ul>

        <Typography variant='subtitle2' className='title steps'>
          Have more questions?
        </Typography>

        <ul className='list-container'>
          <li>
            <Typography variant='subtitle2'>
              Learn How Enara Works or visit our{' '}
              <a href='https://enarahealth.com/for-members/faqs' target='_blank' rel='noreferrer'>
                FAQ page
              </a>
            </Typography>
          </li>
        </ul>
      </>
    );
  };

  const renderSelfServeBookingStepItems = () => {
    return (
      <>
        <ul className='list-container'>
          <li>
            <Typography variant='subtitle2'>
              Don't forget to complete your pre-visit questionnaire to help us understand your needs
              better. Find it in the app or check your email for the link.
            </Typography>
          </li>
          <li>
            <Typography variant='subtitle2'>
              Learn How Enara Works or visit our{' '}
              <a href='https://enarahealth.com/for-members/faqs' target='_blank' rel='noreferrer'>
                FAQ page
              </a>
            </Typography>
          </li>
        </ul>

        <Typography variant='subtitle2' className='title steps'>
          Have more questions?
        </Typography>

        <ul className='list-container'>
          <li>
            <Typography variant='subtitle2'>
              If you wish to speak with a live person, book a 20-minute questions call with our
              Member Success team:{' '}
              <ElementTracker
                routePath={routePath}
                name='Link for questions call with MS'
                type={ElementTrackerType.Clickable}>
                <a href='https://enarahealth.as.me/questions' target='_blank' rel='noreferrer'>
                  https://enarahealth.as.me/questions
                </a>
              </ElementTracker>
            </Typography>
          </li>

          <li>
            <Typography variant='subtitle2'>Chat with Member Support via the Enara app</Typography>
          </li>
          <li>
            <Typography variant='subtitle2'>Email members@enarahealth.com</Typography>
          </li>
        </ul>
      </>
    );
  };

  const renderDesktopNextSteps = () => {
    return (
      <HighlightBox variant='spaced-horizontal'>
        <Grid container className='app-mockup-container'>
          <Grid xs={6} className='app-logos-container all-height'>
            <Typography className='title' variant={'h4'} align='left' marginTop={3}>
              What's next?
            </Typography>

            <Typography
              className='subtitle'
              variant='subtitle2'
              align='left'
              marginTop={1}
              marginBottom={3}>
              Download the Enara app
            </Typography>

            <Grid className='horizontal-logos'>
              <ElementTracker
                routePath={routePath}
                name='Apple Store Download'
                type={ElementTrackerType.Clickable}>
                <Link
                  align='center'
                  className='inline-block'
                  href='https://apps.apple.com/us/app/enara-health-weight-loss/id1134890235'
                  title='Apple store'
                  target={'_blank'}>
                  <img
                    className='download-image--small'
                    src={isDesktopView ? AppleStoreLogo : AppleStoreMobileLogo}
                    alt='Apple store'
                  />
                </Link>
              </ElementTracker>

              <ElementTracker
                routePath={routePath}
                name='Google Play Download'
                type={ElementTrackerType.Clickable}>
                <Link
                  align='center'
                  className='inline-block'
                  href='https://play.google.com/store/apps/details?id=com.enara&hl=en_US'
                  title='Google play'
                  target={'_blank'}>
                  <img
                    className='download-image--small'
                    src={isDesktopView ? GooglePlayLogo : GooglePlayMobileLogo}
                    alt='Google play'
                  />
                </Link>
              </ElementTracker>
            </Grid>

            {isSSB ? renderSelfServeBookingStepItems() : renderDefaultStepItems()}
          </Grid>

          <Grid xs={6} className='app-mockup-container all-height'>
            <img alt='app-mockup' className='app-mockup-image' src={AppMockupWeightsImage} />
          </Grid>
        </Grid>
      </HighlightBox>
    );
  };

  const renderMobileNextSteps = () => {
    return (
      <HighlightBox variant='spaced'>
        <Grid container className='app-mockup-container'>
          <Grid xs={6} className='app-logos-container'>
            <Typography className='title mobile' variant={'h4'} align='center' marginTop={3}>
              What's next?
            </Typography>

            <Typography
              className='subtitle'
              variant='subtitle2'
              align='center'
              marginTop={1}
              marginBottom={3}>
              Download the Enara app
            </Typography>

            <ElementTracker
              routePath={routePath}
              name='Apple Store Download'
              type={ElementTrackerType.Clickable}>
              <Link
                align='center'
                className='inline-block'
                href='https://apps.apple.com/us/app/enara-health-weight-loss/id1134890235'
                title='Apple store'
                target={'_blank'}>
                <img
                  className='download-image--small'
                  src={isDesktopView ? AppleStoreLogo : AppleStoreMobileLogo}
                  alt='Apple store'
                />
              </Link>
            </ElementTracker>

            <ElementTracker
              routePath={routePath}
              name='Google Play Download'
              type={ElementTrackerType.Clickable}>
              <Link
                align='center'
                className='inline-block'
                href='https://play.google.com/store/apps/details?id=com.enara&hl=en_US'
                title='Google play'
                target={'_blank'}>
                <img
                  className='download-image--small'
                  src={isDesktopView ? GooglePlayLogo : GooglePlayMobileLogo}
                  alt='Google play'
                />
              </Link>
            </ElementTracker>
          </Grid>

          <Grid xs={6}>
            <img alt='app-mockup' className='app-mockup-image' src={AppMockupWeightsImage} />
          </Grid>
        </Grid>

        {isSSB ? renderSelfServeBookingStepItems() : renderDefaultStepItems()}
      </HighlightBox>
    );
  };

  if (isDesktopView) {
    return renderDesktopNextSteps();
  } else {
    return renderMobileNextSteps();
  }
};

export default NextSteps;
