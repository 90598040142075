import { Dispatch } from 'react';

export enum SSBFlowState {
  InitSchedulingProcess = 'init-scheduling-process',
  WaitingForUserSelection = 'waiting-for-user-selection',
  FetchAppointmentsAvailability = 'fetching-appointments-availability',
  CreateAppointment = 'create-appointment',
  AllScheduled = 'all-scheduled',
  GoToNextStep = 'go-to-next-step',
}

export type SSBState = SSBFlowState | null;

export type SSBContextValue = {
  ssbState: SSBState;
  dispatchSSB: Dispatch<SSBActionTypes>;
};

export const SET_SSB_INFO = '@enara/types/set-ssb-info';

export type SetSSBInfoType = {
  type: typeof SET_SSB_INFO;
  payload: SSBState;
};

export type SSBActionTypes = SetSSBInfoType;
