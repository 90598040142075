import { Box } from '@mui/material';
import { FC, ReactElement } from 'react';
import './scss/highlight-box.scss';

interface PropsWithChildren {
  variant?: 'spaced' | 'spaced-horizontal';
  children: ReactElement | ReactElement[];
}

const HighlightBox: FC<PropsWithChildren> = (props) => {
  return <Box className={`highlight-box ${props.variant || 'spaced'}`}>{props.children}</Box>;
};

export default HighlightBox;
