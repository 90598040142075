import { createTheme } from '@mui/material/styles';

const StyledTheme = {
  black: '#424242',

  blueSapphire: '#278ba5',
  aliceBlue: '#dee5ec',
  lightBlue: '#aec8ce',
  grayWeb: '#7d7c7a',
  whiteSmoke: '#f9f9f9',
  white: '#ffffff',
  philippineSilver: '#b6b6b6',
  spanishGray: '#9d9d9d',
  cultured: '#f4f7f4',

  fontProximaNova: "'Proxima Nova', 'Source Serif Pro'",

  xs: '414px',
  sm: '540px',
  md: '790px',
  lg: '1280px',
  xl: '1920px',
};

const palette = {
  primary: {
    dark: '#134c5b',
    light: '#5BB0C6',
    main: '#278ba5',
  },
  secondary: {
    dark: '#ff5f41',
    light: '#ffa696',
    main: '#f37962',
  },
  // Negative
  error: {
    dark: '#ff2800',
    light: '#ffd1d1',
    main: '#ff5535',
  },
  // Tertiary
  warning: {
    dark: '#81919f',
    light: '#fafafa',
    main: '#dee5ec',
  },
  // Neutral
  info: {
    dark: '#424242',
    light: '#bdbdbd',
    main: '#6f6d6b',
  },
  // Positive
  success: {
    dark: '#4aab28',
    light: '#c3f196',
    main: '#8ed457',
  },
};

const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 320,
      sm: 480,
      md: 768,
      lg: 960,
      xl: 1024,
    },
  },
  palette,
  typography: {
    fontFamily: ['Proxima Nova', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '32px',
      color: palette.info.dark,
    },
    h3: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '28px',
      color: palette.info.dark,
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '28px',
      color: palette.info.dark,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '20px',
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '20px',
      color: palette.info.dark,
    },
    subtitle1: {
      color: StyledTheme.black,
      fontSize: 16,
      fontWeight: 700,
    },
    body1: {
      color: palette.info.dark,
      fontSize: 20,
      fontWeight: 600,
    },
    body2: {
      color: StyledTheme.grayWeb,
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '20px',
    },
    button: {
      fontWeight: 700,
      fontSize: 20,
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${StyledTheme.aliceBlue}`,
          padding: 0,
          paddingRight: 10,

          '&.Mui-checked': {
            color: `${StyledTheme.blueSapphire}`,
          },

          '&:hover': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          boxShadow: 'unset',
          minWidth: 320,
          padding: 8,
          borderRadius: 4,

          '&:hover': {
            boxShadow: 'unset',
          },

          '@media (max-width: 380px)': {
            minWidth: 300,
          },

          '@media (max-width: 300px)': {
            minWidth: 200,
          },

          '&.Mui-disabled': {
            color: 'white',
            backgroundColor: StyledTheme.lightBlue,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: StyledTheme.black,
          background: StyledTheme.whiteSmoke,
          borderRadius: 4,
          border: `1px solid ${StyledTheme.cultured}`,

          '&::before': {
            border: 'none',
          },

          '&:after': {
            border: `none`,
          },

          '&.Mui-error': {
            border: `1px solid ${palette.error.dark}`,
          },

          '&:hover': {
            background: StyledTheme.whiteSmoke,

            '&:not(.Mui-disabled):before': {
              borderBottom: 'unset',
            },
          },
        },
        input: {
          fontSize: 16,
          fontWeight: 400,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            fontWeight: 500,
            margin: '6px 0px 0px 0px',
            fontSize: '15px',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.warning.main,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          color: StyledTheme.spanishGray,
          top: 2,
          fontWeight: 400,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 30,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 18,
          color: StyledTheme.black,
          padding: 10,
          maxWidth: 200,
          borderRadius: 10,
          boxShadow: 'rgba(0,0,0,0.8) 0 0 10px',
          backgroundColor: StyledTheme.white,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          fontSize: 16,
          fontWeight: 500,
        },
        root: {
          '.MuiInputBase-root': {
            '&.Mui-error': {
              border: `1px solid ${palette.error.dark}`,
            },
          },
        },
      },
    },
  },
});

export default theme;
