import { getPartnerById } from '@enaratech/funnel-helper';
import { Paper, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';
import EnaraLogoIcon from 'src/components/Common/Icons/EnaraLogoIcon';
import { useClinic } from 'src/contexts/clinic';
import '../scss/progress.scss';
import { Page } from './IPOProgress';

type Props = {
  currentPage: Page;
  allowUsersToLogin?: boolean;
};

const IPOProgressBar: FC<Props> = ({ currentPage, allowUsersToLogin = true }) => {
  const { clinicState } = useClinic();

  // TODO: For Now setting percentage to 100
  const percentage = 100;

  const renderBranding = (): ReactNode => {
    const clinicId = clinicState?.details?.clinicId ?? null;
    if (!clinicId) {
      return null;
    }
    const partner = getPartnerById(clinicId, clinicState!.partners);

    if (!partner) {
      return null;
    }

    const {
      branding: { logoSrc, description },
    } = partner;

    return (
      <div className='partner-logo-progress-bar partner-logo-ipo-progress-bar'>
        <img src={logoSrc} alt={description} />
      </div>
    );
  };

  return (
    <Stack justifyContent={'stretch'} alignItems={'center'} className='container-progress-bar'>
      <Paper elevation={0} square className='logo-progress-bar'>
        {renderBranding()}
        <EnaraLogoIcon />
      </Paper>
      <Paper elevation={0} square className='progress-bar'>
        <div style={{ width: `${percentage}%` }} className='bar-progress-bar'></div>
      </Paper>
    </Stack>
  );
};

export default IPOProgressBar;
