import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CircleSelectionUncheckedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z'
      fill='#DEE5EC'
    />
    <circle cx='12' cy='12' r='9' fill='#FAFAFA' />
  </SvgIcon>
);

export default CircleSelectionUncheckedIcon;
