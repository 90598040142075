import { StripeElement } from '@enaratech/funnel-helper';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { ChangeEvent, useEffect, useState } from 'react';
import StripeInput from './StripeInput';

interface Props<T extends StripeElement>
  extends Omit<TextFieldProps, 'onChange' | 'inputComponent' | 'inputProps'> {
  inputProps?: React.ComponentProps<T>;
  field: string;
  labelErrorMessage?: string;
  onChange?: React.ComponentProps<T>['onChange'];
  stripeElement?: T;
  onFieldEmpty: (field: string, stripe: boolean, value: boolean) => void;
}

export const StripeTextField = <T extends StripeElement>(props: Props<T>) => {
  const {
    helperText,
    field,
    InputLabelProps,
    InputProps = {},
    inputProps,
    stripeElement,
    onFieldEmpty,
    ...other
  } = props;

  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [hasError, setError] = useState<boolean>(false);
  const [labelErrorMessage, setErrorMessage] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { empty, complete, error } = event as unknown as {
      empty: boolean;
      complete: boolean;
      error: { message: string };
    };

    setIsComplete(complete);

    if (empty) {
      onFieldEmpty(field, true, false);
    }
    if (complete) {
      onFieldEmpty(field, true, true);
    }
    if (error?.message) {
      onFieldEmpty(field, true, false);
    }

    setIsEmpty(empty);
    setIsComplete(complete);
    setError(!!error);
    setErrorMessage(error?.message);
  };

  useEffect(() => {
    onFieldEmpty(field, true, isComplete);
  }, []);

  return (
    <TextField
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      error={hasError}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
        },
        inputComponent: StripeInput,
      }}
      onChange={handleChange}
      onBlur={() => onFieldEmpty(field, true, isComplete)}
      helperText={hasError ? labelErrorMessage : helperText}
      {...(other as any)}
      variant='filled'
    />
  );
};
