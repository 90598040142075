import {
  AppointmentBookingMethod,
  LeadOnboardingStage,
  onboardingClient,
  StatsigEventName,
  StatsigManager,
  userClient,
} from '@enaratech/funnel-helper';
import { SET_BOOKING_METHOD } from 'src/contexts/onboarding/types';
import { isIPOPath, navigateToPage } from 'src/pages/routes';
import { AllScheduledActionParams } from '../types';

export const allScheduledAction = ({ context, setters, setState }: AllScheduledActionParams) => {
  const { user, routePath, navigate } = context;

  const { dispatchOnboarding } = setters;

  // Booking result
  onboardingClient.updateMemberStatus({
    onboardingStage: LeadOnboardingStage.SelfServeBooking,
    bookingMethod: AppointmentBookingMethod.SelfServed,
  });

  userClient.updateUser({ lead: false });

  dispatchOnboarding({
    type: SET_BOOKING_METHOD,
    payload: AppointmentBookingMethod.SelfServed,
  });

  // Log event into Statsig when a user schedules all their appointments with the
  // specialist providers. So the user is considered a lead conversion
  StatsigManager.logEvent({
    eventName: StatsigEventName.LeadConversion,
    metadata: {
      memberId: `${user!.id}`,
      memberUuid: user!.uuid,
    },
  });

  if (isIPOPath(routePath)) {
    return navigateToPage({ targetPage: '/schedule-appointments/result', navigate });
  }

  return navigateToPage({ targetPage: '/appointment/self-serve-booking/result', navigate });
};
