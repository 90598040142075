import { AvailableLanguages } from '@enaratech/funnel-helper';

/**
 * TODO: Create an endpoint to get appointment types
 */
type SchedulingPlatformEnvironments = 'staging' | 'production';

export const APPOINTMENT_TYPE_ID_QUERY_PARAM = 'appointmentTypeId';

export const calendarIDsByClinicForIPO: {
  [key in SchedulingPlatformEnvironments]: { [key: string]: number };
} = {
  staging: {
    'chula-vista': 3075686, // Testing provider
    mankato: 3075686, // Testing provider
    phoenix: 3075686, // Testing provider
  },
  production: {
    'chula-vista': 8206677, // Melissa
    mankato: 8317577, // Denise
    phoenix: 8262550, // Natalie
  },
};

export const checkEligibilityAppointmentTypeByLanguageForIPO: {
  [key in SchedulingPlatformEnvironments]: { [key in AvailableLanguages]: number };
} = {
  staging: {
    [AvailableLanguages.Arabic]: 33243230, // IN-MS-OL-EN
    [AvailableLanguages.English]: 33243230, // IN-MS-OL-EN
    [AvailableLanguages.Spanish]: 33243230, // IN-MS-OL-EN
  },
  production: {
    [AvailableLanguages.Arabic]: 33477604, // IN-MS-IP-AR
    [AvailableLanguages.English]: 33477581, // IN-MS-IP-EN
    [AvailableLanguages.Spanish]: 33477611, // IN-MS-IP-ES
  },
};

export function getSchedulingPlatformEnvironment(): SchedulingPlatformEnvironments {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
    case 'beta':
      return 'production';
    case 'staging':
    case 'development':
    default:
      return 'staging';
  }
}
