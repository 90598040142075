import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CheckIcon = (props: SvgIconProps) => (
  <SvgIcon style={{ width: 12, height: 10 }} viewBox='0 0 12 10' {...props}>
    <path
      d='M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z'
      fill='#278BA5'
    />
  </SvgIcon>
);

export default CheckIcon;
