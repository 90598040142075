import {
  InputDateOfBirth,
  MixpanelClient,
  MixpanelEvent,
  ReferralLandingInfo,
  ReferralRawInfo,
  getPartnerByReferralCode,
  getPartners,
  referralsClient,
} from '@enaratech/funnel-helper';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, ReactNode, useEffect, useState } from 'react';
import DatePicker from 'src/components/Common/Inputs/DatePicker/DatePicker';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { AuthState, SET_AUTH_INFO } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { ClinicWithAvailablePartners, SET_CLINIC_INFO } from 'src/contexts/clinic/types';
import { useRoutePath } from 'src/hooks/useRoutePath';

type Props = {
  clinicReferralId: string;
  landingInfo: ReferralLandingInfo;
  onValidateDob: (rawInfo: ReferralRawInfo, dob: string) => void;
};

const Landing: FC<Props> = ({ clinicReferralId, landingInfo, onValidateDob }) => {
  const [dob, setDob] = useState<InputDateOfBirth>({ value: null });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { dispatchClinic } = useClinic();
  const { dispatchAuth } = useAuth();

  const routePath = useRoutePath();

  useEffect(() => {
    (async () => {
      // Fake this just to be able to display the appropriate branding in the progress bar.
      // Both states will be properly set once the member creates the account
      const partners = await getPartners();

      const partner = getPartnerByReferralCode(landingInfo.clinicCode, partners);
      let clinicId: number = partner ? partner.clinicId : 0;

      dispatchClinic({
        type: SET_CLINIC_INFO,
        payload: { details: { clinicId } } as ClinicWithAvailablePartners,
      });

      dispatchAuth({
        type: SET_AUTH_INFO,
        payload: { isReferral: true } as AuthState,
      });
    })();
  }, [dispatchClinic, dispatchAuth, landingInfo.clinicCode]);

  const handleClick = async (): Promise<void> => {
    setIsSubmitting(true);

    const formattedDob = dob.value!.toFormat('yyyy-MM-dd');

    const rawInfo = await referralsClient.validateDOB(clinicReferralId, formattedDob);

    if (rawInfo?.status) {
      return onValidateDob(rawInfo, formattedDob);
    }

    setIsSubmitting(false);

    Toast.notification('error', 'The date of birth entered is incorrect');

    MixpanelClient.trackEvent({
      event: MixpanelEvent.InputData,
      properties: {
        field: 'Wrong DOB',
        value: formattedDob,
        source: routePath,
      },
    });
  };

  const getPageSubtitle = (): ReactNode | string => {
    const copy = `You have been referred by {{providerInfo}} from ${referringProvider.clinicName} to join their Enara program`;

    return copy.replace(
      '{{providerInfo}}',
      referringProvider.lastName ? `Dr ${referringProvider.lastName}` : 'a doctor'
    );
  };

  const { member, referringProvider } = landingInfo;

  return (
    <BasicLayout
      title={`Hi ${member.firstName}`}
      subtitle={getPageSubtitle()}
      buttonProps={{
        disabled: isSubmitting || !dob.value || dob.error,
        loading: isSubmitting,
        onClick: handleClick,
      }}>
      <Typography variant={'h4'}>
        Please add your date of birth to continue with the process
      </Typography>
      <DatePicker
        error={dob.error}
        errorMessage={dob.errorMessage}
        onChange={(date: DateTime | null) => setDob({ value: date })}
        value={dob.value}
      />
    </BasicLayout>
  );
};

export default Landing;
