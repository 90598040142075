/**
 * TODO: Suggested improvement -- Create fields dynamically based on a config file, in order to
 * determine if they're required, the type of the field, options (if they're dropdowns), etc.
 * This might reduce # lines of code of the NewMemberProfile page.
 */

export enum InputFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phone',
  DateOfBirth = 'dateOfBirth',
  Gender = 'gender',
  PreferredLanguage = 'preferredLanguage',
  HeightFeet = 'heightFeet',
  HeightInches = 'heightInches',
  WeightLbs = 'weightLbs',
  InsuranceType = 'insuranceType',
  InsuranceCompany = 'insuranceCompany',
  InsuranceId = 'insuranceId',
  GroupNumber = 'groupNumber',
  ReferringProvider = 'referringProvider',
}

// To make a field optional in the lead form just change its corresponding value to false in the following list
export const MANDATORY_FIELDS: { [key in InputFields]: boolean } = {
  [InputFields.FirstName]: true,
  [InputFields.LastName]: true,
  [InputFields.Email]: false,
  [InputFields.Phone]: true,
  [InputFields.DateOfBirth]: true,
  [InputFields.Gender]: true,
  [InputFields.PreferredLanguage]: true,
  [InputFields.HeightFeet]: true,
  [InputFields.HeightInches]: true,
  [InputFields.WeightLbs]: true,
  [InputFields.InsuranceType]: true,
  [InputFields.InsuranceCompany]: true,
  [InputFields.InsuranceId]: true,
  [InputFields.GroupNumber]: true,
  [InputFields.ReferringProvider]: true,
};
