import {
  ElementTracker,
  ElementTrackerType,
  PROMO_CODE_TIMEOUT,
  promoCodeClient,
} from '@enaratech/funnel-helper';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import CircleCancelIcon from 'src/components/Common/Icons/CircleCancelIcon';
import CircleSelectionCheckedIcon from 'src/components/Common/Icons/CircleSelectionCheckedIcon';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { ReturnData } from '../Payment.types';
import './scss/promoInput.scss';

type Data = {
  price: number;
  annualPrice: number;
  location: string;
};

type Props = {
  handleChange: (value: string, data: ReturnData) => void;
  data: Data;
};

const PromoInput: FC<Props> = ({ handleChange, data }) => {
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [valid, setValid] = useState<string | boolean | null>(null);
  const [timeoutVar, setTimeoutVar] = useState<NodeJS.Timeout | null>(null);

  const routePath = useRoutePath();

  const invalidPromoCodeMsg = 'You entered an invalid promo code';

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = typeof event === 'string' ? event : event.target.value;
    value = value.toUpperCase();

    setValue(value);

    clearTimeout(timeoutVar!);

    setValid('loading');

    if (!value) {
      setError('');
      setValid(null);
      return;
    }

    setTimeoutVar(
      setTimeout(async () => {
        try {
          const result = await promoCodeClient.getPromoCodeDetails({
            originalPrice: data.price,
            originalAnnualPrice: data.annualPrice,
            locationId: data.location,
            promoCode: value,
          });

          if (result!.status === 'ok') {
            setValid(true);
            setError('');
            handleChange(value, {
              discountMonthly: result!.discounted_price_monthly,
              discountAnnually: result!.discounted_price_yearly,
              coupon: result!.coupon!.value,
              duration: result!.coupon!.duration_months,
            });
          } else {
            setValid(false);
            setError(invalidPromoCodeMsg);
            handleChange('', {
              discountMonthly: 0,
              discountAnnually: 0,
              coupon: 0,
              duration: 0,
            });
          }
        } catch (err) {
          setValid(false);
          setError(invalidPromoCodeMsg);
          handleChange('', {
            discountMonthly: 0,
            discountAnnually: 0,
            coupon: 0,
            duration: 0,
          });
        }
      }, PROMO_CODE_TIMEOUT)
    );
  };

  return (
    <ElementTracker routePath={routePath} name='promo' type={ElementTrackerType.Blurrable}>
      <TextField
        label='Promo'
        variant='filled'
        id='promo-input'
        name='promo'
        type='text'
        className='promo-code'
        value={value}
        onChange={onChange}
        error={!!error}
        helperText={error}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {valid === true ? (
                <CircleSelectionCheckedIcon color='success' />
              ) : valid === false ? (
                <CircleCancelIcon color='error' />
              ) : valid === 'loading' ? (
                <CircularProgress size={24} className='circular-progress-promo' />
              ) : (
                <></>
              )}
            </InputAdornment>
          ),
        }}
      />
    </ElementTracker>
  );
};

export default PromoInput;
