// TODO: this is hardcoded for now to get the clinics referred_by_options and will be removed once the endpoint (OP-308) is ready
export const CLINICS = [
  {
    name: 'Test Clinic Connected to Staging Instance 2',
    id: 14,
    referred_by_options: [],
  },
  {
    name: 'WellHealth Las Colinas',
    id: 5,
    referred_by_options: [],
  },
  {
    name: 'Kafri Chula Vista',
    id: 11,
    referred_by_options: [
      'Hassan Kafri, MD',
      'Kimberly Johnson, NP',
      'Priscilla La Berge-Inda, PA-C',
      'Stephen Yau, MD',
      'Athmar Yaldo, NP',
      'OTHER',
    ],
  },
  {
    name: 'Enara Health - San Mateo',
    id: 1,
    referred_by_options: [],
  },
  {
    name: 'WellHealth - Frisco',
    id: 2,
    referred_by_options: [],
  },
  {
    name: 'EHG Texas',
    id: 10,
    referred_by_options: [],
  },
  {
    name: 'Phoenix Heart',
    id: 13,
    referred_by_options: [
      'Fred Cucher, MD, FACC',
      'Rajul Patel, MD, FACC',
      'Kristine Sellberg, MD, FACC',
      'Reshmaal Gomes, MD, FACC, MCRP',
      'Shakeel Khan, MD, FACC',
      'Punit Parasher, MD, FACC',
      'Moustafa Banna, MD, FACC, RPVI',
      'Vinny Ram, MD',
      'Alfred Rossum, MD, MS',
      'Kenneth Dizon, , D.O, FACC',
      'Wasiq Zaidi, MD',
      'Ramy Doss, MD, FACC, FSCAI',
      'Andrew Kaplan,, MD',
      'Dana Hock, NP-C',
      'Shawna Muchow, NP-C',
      'Kimberly Schnettler, MSN, FNP-C',
      'Patricia Veleta, DNP, AG ACNP-BC',
      'Donnamarie Burris, RN, MSN, CVRN-BC, NP-C',
      'Carolina Castro, PA-C',
      'Carissa Buckey, FNP-BC',
      'Alexis Jensen, NP',
      'Lori Klaski, PA-C',
      'Wendallynn Briske, MSN-Ed, ACNPC-AG',
      'Yoojin Jung, NP',
      'Elissa Adat Ya, MD',
      'OTHER',
    ],
  },
  {
    name: 'Kafri El Cajon',
    id: 12,
    referred_by_options: [
      'Hassan Kafri, MD',
      'Kimberly Johnson, NP',
      'Priscilla La Berge-Inda, PA-C',
      'Stephen Yau, MD',
      'Athmar Yaldo, NP',
      'OTHER',
    ],
  },
  {
    name: 'Dr. Yazen Joudeh Clinic - Orland Park',
    id: 3,
    referred_by_options: [],
  },
  {
    name: 'Mankato Clinic - Mankato',
    id: 9,
    referred_by_options: [
      'McKenzie Adams, PT, DPT',
      'Rebecca Ahlman, RN',
      'Kimberly Aho, MD',
      'Nidal Alkurdy, MD',
      'Tracy Altmann, AuD',
      'Craig Anderson, MD',
      'Daniel Anderson, MD',
      'Katie Anderson, MD',
      'Rylee Anderson, MA, CCC-SLP',
      'Ifechi Anyadioha, MD',
      'Heather Ardner, PT, DPT',
      'Margie Bach, APRN, CNP, FNP',
      'Caroline Baerg, MD',
      'Brett Baldwin, DO',
      'Paul Bandelin, DO',
      'Abby Barlament, MSW, LICSW',
      'Scott Barnacle, MD',
      'Sue Beavis, APRN, DNP, CNM',
      'Amber Becker, AAS, SLPA',
      'John Benson, MD',
      'James Benzmiller, MD',
      'Phillip Benzmiller, MD',
      'Jeremy Berger, MD',
      'Susan Bianchi-Olson, APRN, CNP',
      'Stephanie Blume, MS, CCC-SLP',
      'Amy Boles, MD',
      'Hannah Brandts, MD',
      'Erin Braun, PA-C, RD',
      'Leah Breit, MD',
      'Tom Brennan, MD',
      'Kelly Bryan, DO',
      'April Burns, PA-C',
      'Cassy Burton, APRN, CNP, PMHNP-BC',
      'Heather Carlson, DO',
      'Matthew Carns, MD',
      'Pauline Chen, DO',
      'Natasha Choiniere, PT, DPT',
      'Larissa Christ, MOTR/L',
      'Maggie Christ, APRN, DNP, FNP-C',
      'Timothy Christiansen, MD',
      'Rebecca Corrow, APRN, DNP',
      'Ashley Dahline, PA-C',
      'Brenna Danko, PA-C',
      'Nataliya Danylkova, MD',
      'Lisa Davidson, MD',
      'Catherine Davis, MD',
      'Donald Davis, MD',
      'Amy Deehr, DO',
      'Tara Denke, MD',
      'Jessica Denn, PT, DPT',
      'Suresh Devineni, MD',
      'Ben Dexter, MD',
      'Lindsay Dexter, PT, DPT',
      'Sagan Dobie, PA-C',
      'Sara Pitzer, APRN, CNP',
      'Abby Drummer, RN-BSN',
      'Crystal Dubbelde, MSW, LICSW',
      'Brittany Duncan, APRN, CNP, WHNP',
      'James Dungan, MD',
      'Holly Ellison, RD, LD',
      'Berkley Erickson, APRN, CNP',
      'Samantha Erickson, MA, CCC-SLP',
      'Elizabeth Evers, PA-C',
      'Nathan Evers, PharmD, BCACP',
      'Kendra Finn, DO',
      'Thomas Finn, DO',
      'Nicole Fischbach, APRN, CNP, FNP',
      'Ashley Forstner, RN-BSN, CDCES',
      'Michael Fraley, MD',
      'Julie Freyberg, APRN, CNP, CWOCN',
      'Robert Gazzola, MD',
      'Carla Goerish, MD',
      'Gary Goldberg, MD',
      'Erin Gonzalez, MS, RD, LD',
      'Nathaniel Graham, OD',
      'Lynn Gustafson, DPM',
      'Cheryl Hagen, PA-C',
      'Karen Hansen, PA-C',
      'Travis Hansen, MD',
      'Hope Hartig, APRN, CNP, CDCES',
      'Steven Haugen, MD',
      'Alisa Heiling, MOTR/L',
      'Mitchell Heun, MD',
      'Galen Hilgendorf, PA-C',
      'Season Hoffman, APRN, CNP',
      'Timothy Huisken, MD',
      'Darcie Jacobs, PsyD, LP',
      'Jill Jacobson, MA, OTR/L',
      'Hannah JavensGreer, PA-C',
      'Marlene Jilek, APRN, CNP, PNP',
      'Anna Johanson, MSW, LICSW',
      'Abby Johnson, PT, DPT',
      'Manpreet Kanwar, MD',
      'Katie Keepers, MD',
      'Jessica Kelly, RN',
      'Susan Klenk, MD',
      'Beth Knoll-Fleming, MS, CCC-SLP',
      'Kathy Kurth, APRN, CNP',
      'Elizabeth MLandgraff, MS, CCC-SLP',
      'Michele Langer, RN',
      'Robert Larson, MD',
      'Jessica Lawrence, PT, DPT',
      'Kristin Lehne, PT',
      'Megan Linder, PT, DPT',
      'Kristin Lingen, PT, DPT',
      'Stephanie Litsheim, APRN, DNP, PMHNP-BC',
      'Faith Ludwigs, APRN, CNP',
      'Andrew Lundquist, DPM',
      'Nevada Maertens, MS, CCC-SLP',
      'Lora Magers, PA-C',
      'Danielle Magness-Wellmann, DO',
      'Jennie Marble, APRN, CNP',
      'Travis Mattson, PT, DPT',
      'Tami Meehan, PT, DPT',
      'Connie Meyer, MSW, LICSW',
      'Laura Meyer, APRN, CNP',
      'J AndrewMiller, MD',
      'Jenna Miller, LSW',
      'Jennifer Mittelstaedt, RN',
      'Jessica Monsen, PA-C',
      'Lisa Montag, NP',
      'Lindsey Mork, PT, DPT',
      'Michael Much, PT, DPT',
      'Michael Muchiri, APRN, CNP',
      'James Nack, DPM',
      'Nancy Nelson, MD',
      'Jake Nienow, PT, DPT',
      'Ashley Nuetzman, RDN, LD',
      'Robert Olson, MD',
      'Julia Omtvedt, AuD, CCC-A',
      'David Orcutt, MD',
      'Paul Otteson, PA-C',
      'Megan Oudekerk, APRN, CNP',
      'Claire Patterson, RN-BSN',
      'Richard Peller, MD',
      'Susan Peller, APRN, CNP',
      'Lindsay Peterson, MS, OTR/L',
      'Steve Peterson, MS, LP',
      'Lindsey Pfarr, MS, OTR/L',
      'Magda Pietka, MD',
      'Emily Potter, APRN, CNP, FNP',
      'Bryan Pucik, MD',
      'SriniVasan Ramanuja, MD',
      'Leah Rasmussen, APRN, DNP, FNP-C',
      'Bithika Ray, MD',
      'Stacy Regnier, PA-C',
      'Karl Roos, MD',
      'Vicki Rose, PA-C',
      'Sheila Ruschmeyer, MD',
      'Edward Sathoff, MD',
      'Amanda Scharfe, MS, CCC-SLP',
      'Dar Schauer, PA-C',
      'Deb Schilling, PA-C',
      'Jennifer Schneider, PA-C',
      'Amanda Schultz, MA, OTR/L',
      'Katlyn Schweder, APRN, CNP, FNP',
      'Angie Scott, CCC-SLP',
      'Aimie Seehafer, PA-C',
      'Amanda JSenal, MA, OTR/L',
      'Sara Sieberg, MS, OTR/L',
      'Katie Smentek, MD',
      'Jesy Smisek, RN',
      'Karie Soost, PA-C',
      'Amy JoSorensen, DO',
      'Sreelatha Spieker, MD',
      'Joni Stadtherr, MSW, LICSW',
      'Heather Stehr, APRN, CNP, FNP',
      'Jason Steinle, MD',
      'Alyssa Stitt, MD',
      'Gavin Stormont, MD',
      'Eric Storvick, MD',
      'Kara Sullivan, MD',
      'Katie Swanson, PT, DPT',
      'Penelope Swanson, MD',
      'Mark Taylor, MD',
      'Nikki Thilges, RN',
      'Emily Thompson, MSW, LICSW',
      'Katie Thompson, DO',
      'Ashley Thorson, MD',
      'Mischa Tursich, PhD, LP',
      'Mary VanRavenhorst, OTR/L, BCN',
      'Megan Wajer, APRN, CNP',
      'Colin Weerts, DO',
      'Jeffrey Weideman, PA-C',
      'Danielle Wendinger, APRN, CNP, FNP, CWCN-AP',
      'Wendy White, PT, DPT',
      'Brian Williams, MD',
      'Katie Wojtalewicz, PsyD, LP',
      'Shelby Wolf, PhD, LP',
      'Darla Wrage, MD',
      'OTHER',
    ],
  },
];
