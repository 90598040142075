import {
  CoverageInfo,
  crmClient,
  ElementTracker,
  ElementTrackerType,
  FeatureManager,
  getHintPlanIdForMembershipType,
  getParsedCoverageInfo,
  hintClient,
  isTechMembership,
  isValidPassword,
  LeadOnboardingStage,
  MixpanelClient,
  onboardingClient,
  PaymentOption,
  userClient,
  VARIANT_VERIFICATION_CODE,
} from '@enaratech/funnel-helper';
import { Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Agreements, { RefAgreements } from 'src/components/Common/Agreements/Agreements';
import CheckCircleIcon from 'src/components/Common/Icons/CheckCircleIcon';
import ViewIcon from 'src/components/Common/Icons/ViewIcon';
import ViewIconOff from 'src/components/Common/Icons/ViewIconOff';
import PaymentType from 'src/components/Common/PaymentType/PaymentType';
import {
  DiscountInfo,
  PaymentMode,
  PaymentModeMapping,
} from 'src/components/Common/PaymentType/paymentType.types';
import { Page, withProgress } from 'src/components/Common/Progress/IPO/IPOProgress';
import { PaymentForm } from 'src/components/Common/StripePayment/Inputs/input.types';
import StripePayment from 'src/components/Common/StripePayment/StripePayment';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { SET_HINT_PATIENT_ID } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { useOnboarding } from 'src/contexts/onboarding';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { getProgress, ProgressKey } from 'src/lib/IPOStorage';
import { PaymentMethodResult } from 'src/pages/Payment/Inputs/Payment.types';
import { navigateToPage } from 'src/pages/routes';
import { inputFields, PLAN_OPTIONS } from './Inputs/inputFields';
import { PaymentMethodErrors } from './paymentMethod.types';
import './scss/paymentMethod.scss';

const PaymentMethod: FC = () => {
  const [coverage, setCoverage] = useState<CoverageInfo | null>(null);
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>('');
  const [paymentMode, setPaymentMode] = useState<PaymentMode>(PaymentMode.Monthly);
  const [stripePaymentMethod, setStripePaymentMethod] = useState<PaymentMethodResult | undefined>(
    undefined
  );
  const [discount, setDiscount] = useState<DiscountInfo>({
    discountMonthly: 0,
    discountAnnually: 0,
    coupon: 0,
    duration: 0,
  });
  const [agreementsLoaded, setAgreementsLoaded] = useState<boolean>(false);
  const [allAgreementsSelected, setAllAgreementsSelected] = useState<boolean>(false);
  const [errors, setErrors] = useState<Partial<PaymentMethodErrors> | null>(null);
  const [passwordFormState, setPasswordFormState] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState<{
    password: boolean;
    confirmPassword: boolean;
  }>({
    password: false,
    confirmPassword: false,
  });

  const agreementsRef = useRef<RefAgreements>(null);
  const navigate = useNavigate();
  const routePath = useRoutePath();

  const {
    authState: { user },
    dispatchAuth,
  } = useAuth();

  const {
    onboardingState: {
      eligibility: { membershipType },
    },
  } = useOnboarding();

  const { clinicState } = useClinic();

  const [formState, setFormState] = useState<PaymentForm>({
    street: getProgress()?.[ProgressKey.NewMemberProfile]?.street ?? '',
    city: user?.city ?? '',
    state: user?.state ?? '',
    zipCode: user?.zipCode ?? '',
  });

  const hint = clinicState?.hint;

  const getInitialPrice = () => {
    if (!coverage) {
      return '';
    }

    return paymentMode === PaymentMode.Monthly
      ? `${coverage.prices.monthly}`
      : `${coverage.prices.annual}`;
  };

  const getDiscountPrice = () => {
    return paymentMode === PaymentMode.Monthly
      ? `${discount.discountMonthly}`
      : `${discount.discountAnnually}`;
  };

  const validatePasswordForm = (field: string, value: string) => {
    const updatedFormState = { ...passwordFormState, [field]: value };

    if (!updatedFormState[field as keyof typeof updatedFormState]) {
      setErrors((prev) => ({
        ...prev,
        [field]: [
          `The ${
            field === 'confirmPassword' ? 'confirm password' : field.toLowerCase()
          } is required`,
        ],
      }));
    }

    if (updatedFormState[field as keyof typeof updatedFormState].length > 0) {
      setErrors((prev) => ({ ...prev, [field]: [] }));
    }

    if (['password', 'confirmPassword'].includes(field)) {
      if (!isValidPassword(updatedFormState.password as string)) {
        setErrors((prev) => ({
          ...prev,
          password: [
            'A lowercase letter (a-z)',
            'An uppercase letter (A-Z)',
            'A Number (0-9)',
            'Being at least 8 characters',
          ],
        }));
      } else if (updatedFormState.confirmPassword.length > 0) {
        setErrors((prev) => ({
          ...prev,
          confirmPassword:
            updatedFormState.confirmPassword !== updatedFormState.password
              ? ['Passwords do not match']
              : [],
        }));
      }
    }

    setPasswordFormState(updatedFormState);
  };

  const handleSubmit = async () => {
    const { firstName, lastName, email } = user!;

    const IDs: {
      patient: string | null;
      membership: string | null;
      card: string | null;
      techPatient: string | null;
      techMembership: string | null;
      techCard: string | null;
    } = {
      patient: null,
      membership: null,
      card: null,
      techPatient: null,
      techMembership: null,
      techCard: null,
    };

    if (stripePaymentMethod?.error) {
      return;
    }

    setIsSubmitting(true);

    try {
      // Update the password
      await userClient.setPassword({
        password: passwordFormState.password,
        passwordConfirmation: passwordFormState.confirmPassword,
      });
    } catch (err) {
      console.error(err);
      Toast.notification('error', 'Unable to set password');
      setIsSubmitting(false);
      return;
    }

    try {
      // Then we have to login again, as the token might have changed
      await userClient.loginUser({
        email: user!.email ?? user!.phone,
        password: passwordFormState.password,
      });
    } catch (err) {
      console.error(err);
      Toast.notification('error', 'There was an issue authenticating you');
      setIsSubmitting(false);
      return;
    }

    const isTech = isTechMembership(membershipType);

    try {
      const patient = await hintClient.createPatient({
        email,
        first_name: firstName,
        last_name: lastName,
        lead_source_id: hint!.leadSource,
        location_id: hint!.location,
        practitioner_id: hint!.supervisingMd,
        hint_code: hint!.hintCode,
      });

      IDs.patient = patient.id;

      if (!isTech) {
        const card = await hintClient.createPaymentMethod({
          patient_id: patient.id,
          stripe_id: stripePaymentMethod!.stripePaymentMethod!.id,
          hint_code: hint!.hintCode,
        });

        IDs.card = card?.id ?? null;
      }

      const membership = await hintClient.createMembership({
        patient_id: patient.id,
        plan_id: getHintPlanIdForMembershipType({
          clinic: clinicState!,
          membershipType: membershipType!,
          insuranceOutcome: user!.insuranceOutcome,
        }),
        period_in_months: PaymentModeMapping[paymentMode],
        hint_code: hint!.hintCode,
      });

      IDs.membership = membership?.id ?? null;

      if (isTech) {
        const techPatient = await hintClient.createPatient({
          email,
          first_name: firstName,
          last_name: lastName,
          lead_source_id: hint!.otherSubscription!.leadSource,
          location_id: hint!.otherSubscription!.location,
          practitioner_id: hint!.otherSubscription!.supervisingMd,
          hint_code: hint!.otherSubscription!.hintCode,
        });

        IDs.techPatient = techPatient.id;

        const [techCard, techMembership] = await Promise.all([
          hintClient.createPaymentMethod({
            patient_id: techPatient.id,
            stripe_id: stripePaymentMethod!.stripePaymentMethod!.id,
            hint_code: hint!.otherSubscription!.hintCode,
          }),
          hintClient.createMembership({
            patient_id: techPatient.id,
            plan_id: getHintPlanIdForMembershipType({
              clinic: clinicState!,
              membershipType: membershipType!,
              isAppSubscription: true,
              insuranceOutcome: user!.insuranceOutcome,
            }),
            period_in_months: PaymentModeMapping[paymentMode],
            hint_code: hint!.otherSubscription!.hintCode,
          }),
        ]);

        IDs.techCard = techCard?.id ?? null;
        IDs.techMembership = techMembership?.id ?? null;
      }

      const paymentOption: PaymentOption =
        paymentMode === PaymentMode.Annual ? 'yearly' : 'monthly';

      crmClient.updateLead({
        uuid: user!.uuid,
        shownPrice: getInitialPrice(),
        finalPrice: discount.coupon !== 0 ? getDiscountPrice() : getInitialPrice(),
        billingFrequency: paymentOption,
        couponCode: promoCode,
      });

      userClient.updateUser({
        address: formState.street,
        city: formState.city,
        state: formState.state,
        zipCode: formState.zipCode,
        hintId: IDs.patient,
      });

      dispatchAuth({ type: SET_HINT_PATIENT_ID, payload: IDs.patient });

      if (agreementsRef.current) {
        agreementsRef.current.signAgreements();
      }

      MixpanelClient.setMetadata({ paymentOption });

      onboardingClient.updateMemberStatus({
        onboardingStage: LeadOnboardingStage.Payment,
      });

      navigateToPage({ targetPage: '/schedule-appointments', navigate });
    } catch (error) {
      Toast.notification('error', (error as any).message);

      await hintClient.rollbackHintSetup({
        common: {
          hintCode: hint!.hintCode,
          patientId: IDs.patient,
          membershipId: IDs.membership,
          cardId: IDs.card,
        },
        appSubscription: {
          hintCode: hint!.otherSubscription!.hintCode,
          patientId: IDs.techPatient,
          membershipId: IDs.techMembership,
          cardId: IDs.techCard,
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGetCoverage = async () => {
    const coverageResponse = await getParsedCoverageInfo({
      membershipType,
      insuranceCompany: user!.insuranceCompany,
      programType: user!.programType,
      clinicId: user!.clinicId,
    });

    setCoverage(coverageResponse);
  };

  useEffect(() => {
    handleGetCoverage();
  }, []);

  return (
    <BasicLayout
      title={
        <Typography variant={'h1'} align='center'>
          Welcome to Enara, {user!.firstName}!
        </Typography>
      }
      buttonProps={{
        text: 'Next',
        disabled:
          !canContinue ||
          !agreementsLoaded ||
          !allAgreementsSelected ||
          Object.values(formState).every((i) => i!.length === 0) ||
          Object.values(passwordFormState).some((i) => i.length === 0) ||
          (!!errors && Object.values(errors).some((i) => i.length > 0)),
        loading: isSubmitting,
        onClick: handleSubmit,
      }}
      className='container-payment-method'
      back>
      <Grid container direction={'row'} justifyContent={'space-between'}>
        <Grid container direction={'column'} gap={'2em'} lg={8} xs={12} p={'1em'}>
          <Typography variant={'h4'}>Let's finalize setting up your account</Typography>
          <Grid container direction={'row'} justifyContent={'space-between'} spacing={3}>
            {inputFields.map(({ label, field, name }) => (
              <Grid item md={6} className='payment-method-credentials-field'>
                <ElementTracker
                  routePath={routePath}
                  name={name}
                  type={ElementTrackerType.Blurrable}
                  value={!!errors?.[field as keyof typeof errors] === false}>
                  <TextField
                    data-test={`createAccount-input-${field}`}
                    name={name}
                    label={label}
                    fullWidth
                    variant='filled'
                    type={
                      !['confirmPassword', 'password'].includes(field)
                        ? 'text'
                        : showPassword[field as keyof typeof showPassword]
                        ? 'text'
                        : 'password'
                    }
                    // @ts-ignore
                    FormHelperTextProps={{ component: 'span' }}
                    InputProps={{
                      endAdornment: ['confirmPassword', 'password'].includes(field) ? (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={() =>
                              setShowPassword({
                                ...showPassword,
                                [field]: !showPassword[field as keyof typeof showPassword],
                              })
                            }>
                            {showPassword[field as keyof typeof showPassword] ? (
                              <ViewIcon />
                            ) : (
                              <ViewIconOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ) : 'phone' === field &&
                        FeatureManager.isVariantEnabled(VARIANT_VERIFICATION_CODE) ? (
                        <InputAdornment position='end'>
                          <IconButton onClick={() => {}} name={'Send Code'}>
                            <CheckCircleIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                    autoComplete={field}
                    onChange={(e) => validatePasswordForm(field, e.target.value)}
                    error={!!errors?.[field as keyof typeof errors]?.length}
                    helperText={
                      !!errors?.[field as keyof typeof errors] && (
                        <ul>
                          {(Object.values(errors[field as keyof typeof errors]!) as string[]).map(
                            (entry, i) => (
                              <li key={`password-hint-${i}`}>{entry}</li>
                            )
                          )}
                        </ul>
                      )
                    }
                  />
                </ElementTracker>
              </Grid>
            ))}
          </Grid>
          <Grid item>
            <StripePayment
              formState={formState}
              setCanContinue={setCanContinue}
              setFormState={setFormState}
              setStripePaymentMethod={setStripePaymentMethod}
              user={{ firstName: user!.firstName, lastName: user!.lastName }}
            />
            <Agreements
              ref={agreementsRef}
              agreementsMode={'needs-auth'}
              address={formState.street}
              onLoaded={useCallback(() => setAgreementsLoaded(true), [])}
              onSelect={useCallback((all) => setAllAgreementsSelected(all), [])}
            />
          </Grid>
        </Grid>
        <Grid container lg={4} xs={12} p={'1em'} alignItems={'flex-end'}>
          {coverage && (
            <PaymentType
              prices={coverage.prices}
              setPaymentMode={setPaymentMode}
              paymentMode={paymentMode}
              items={coverage.items}
              planOptions={PLAN_OPTIONS}
              discount={discount}
              setDiscount={setDiscount}
              setPromo={setPromoCode}
              data={{
                price: coverage.prices.monthly,
                annualPrice: coverage.prices.annual,
                location: clinicState!.details!.locationId,
              }}
            />
          )}
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default withProgress(PaymentMethod, Page.PaymentMethod);
