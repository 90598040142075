import {
  CoverageInfo,
  CoverageType,
  ElementTracker,
  FeatureManager,
  getParsedCoverageInfo,
  getPlanCostCopy,
  InsuranceOutcome,
  LeadOnboardingStage,
  MembershipType,
  onboardingClient,
  StatsigEventName,
  StatsigManager,
  userClient,
} from '@enaratech/funnel-helper';
import { Grid, Link, List, ListItem, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'src/components/Common/Dropdown/Dropdown';
import { PaymentMode } from 'src/components/Common/PaymentType/paymentType.types';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { SET_USER_INSURANCE_OUTCOME } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { useExperiment } from 'src/contexts/experiments';
import { useOnboarding } from 'src/contexts/onboarding';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { useMembershipStore } from 'src/store/memberships';
import { navigateToPage } from '../routes';
import CoverageCard from './CoverageCard';
import './scss/coverage.scss';

const Coverage: FC = () => {
  const { clinicState } = useClinic();
  const routePath = useRoutePath();
  const [coverage, setCoverage] = useState<CoverageInfo | null>(null);
  const [annualDiscountEnabled, setAnnualDiscountEnabled] = useState<boolean>(false);

  const isMonthly = useMembershipStore((s) => s.isMonthly);
  const updatePaymentMode = useMembershipStore((s) => s.updatePaymentMode);

  const {
    onboardingState: {
      agreements: { agreementsHistory, status },
      eligibility: { membershipType },
      magicLink,
      locationFlow,
    },
  } = useOnboarding();

  const {
    authState: { isReferral, user },
    dispatchAuth,
  } = useAuth();

  const navigate = useNavigate();

  // Experiment facing changes
  const {
    experimentState: { isInitialized },
  } = useExperiment();

  const isArrivingFromAdwords = useMemo(() => {
    if (!isInitialized) {
      return false;
    }

    return StatsigManager.isArrivingFromAdwords();
  }, [isInitialized]);

  const handleGetCoverage = async () => {
    const coverageResponse = await getParsedCoverageInfo({
      membershipType: membershipType ?? MembershipType.Private,
      insuranceCompany: user!.insuranceCompany,
      programType: user!.programType,
      clinicId: user!.clinicId,
    });
    setCoverage(coverageResponse);
  };

  useEffect(() => {
    handleGetCoverage();
  }, []);

  const handleClick = () => {
    onboardingClient.updateMemberStatus({
      onboardingStage: LeadOnboardingStage.Coverage,
    });

    // Member comes from the legacy funnel, already booked with `MS` but
    // has not gone through the payment page yet (CC) OR this is a referral
    // from Kafri
    if (
      FeatureManager.qualifiesToContinueWithOnboardingProcess({
        initialAppointmentId: user!.initialAppointmentId,
        hintId: user?.hintId,
      }) ||
      FeatureManager.shouldReferralGoThroughSSB({ isReferral, clinicId: user!.clinicId })
    ) {
      return navigateToPage({ targetPage: '/payment', navigate });
    }

    // Member was offered a new membership (prices) by the ops team, hence,
    // agreements must be signed again to keep track of these changes
    if (
      FeatureManager.canSignCustomAgreements({
        agreementsHistory,
        agreementsStatus: status,
        membershipType,
        hintId: user!.hintId,
      })
    ) {
      return navigateToPage({ targetPage: '/custom-agreements', navigate });
    }

    // Referrals with no magic link must always follow the MS flow
    if (
      !magicLink &&
      !FeatureManager.shouldForceGoToSSBForReferralHubFlow(locationFlow!) &&
      !FeatureManager.shouldReferralGoThroughSSB({ isReferral, clinicId: user!.clinicId })
    ) {
      return navigateToPage({ targetPage: '/appointment/ms-booking', navigate });
    }

    // Member decided to continue without insurance (Cash by default). Cash as membership
    // type is just to be able to set the correct coverage type in crm, it does
    // not affect the rest of the flow
    if (!membershipType) {
      userClient.updateUser({
        insuranceOutcome: InsuranceOutcome.ContinuedWithoutInsurance,
        coverageType: CoverageType.Cash,
      });

      dispatchAuth({
        type: SET_USER_INSURANCE_OUTCOME,
        payload: InsuranceOutcome.ContinuedWithoutInsurance,
      });

      if (isArrivingFromAdwords) {
        // Members coming from an Adwords campaign should be redirected to the congrats
        // screen instead Member Experience Booking flow since they already scheduled an
        // appointment for Question Talk
        StatsigManager.logEvent({
          eventName: StatsigEventName.CongratulationScreenDisplayed,
          metadata: {
            memberId: `${user!.id}`,
            memberUuid: user!.uuid,
          },
        });
        return navigateToPage({ targetPage: '/onboarding-call-result', navigate });
      }
    }

    navigateToPage({ targetPage: '/payment', navigate });
  };

  const getTitle = (): string => {
    if (!coverage) {
      return '';
    }

    if (!membershipType) {
      return getPlanCostCopy(coverage);
    }

    return coverage.title;
  };

  const getMembershipName = useCallback(() => {
    if (!coverage) {
      return '';
    }

    return coverage.displayName;
  }, [coverage]);

  const getPriceAccordingToPaymentRecurrence = useCallback(
    (price: { monthly: number; annual: number } | undefined) => {
      if (!price) {
        return '';
      }

      if (isMonthly) {
        return `$${price.monthly}`;
      }
      return `$${price.annual}`;
    },
    [isMonthly]
  );

  const handlePaymentModeToggle = () => {
    if (isMonthly) {
      updatePaymentMode(PaymentMode.Annual);
    } else {
      updatePaymentMode(PaymentMode.Monthly);
    }
  };

  useEffect(() => {
    (async () => {
      setAnnualDiscountEnabled(
        await FeatureManager.isAnnualDiscountEnabled(
          clinicState!.details.clinicId,
          membershipType ?? MembershipType.Cash
        )
      );
    })();
  }, []);

  return (
    <BasicLayout
      title={getTitle()}
      subtitle={
        <>
          <p>{`It's time to invest in yourself and become a healthier and stronger YOU! ${
            annualDiscountEnabled
              ? 'Save 20% by purchasing your annual membership in full or pay monthly'
              : ''
          }`}</p>
        </>
      }
      buttonProps={{ text: "I'm Ready to Change My Life!", onClick: handleClick }}
      back={!membershipType && !magicLink}
      className='coverage'>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} display='flex' minHeight='10rem'>
          <CoverageCard title='Membership'>
            <Typography variant='subtitle2' textAlign='center'>
              Your Enara membership
            </Typography>
            <Typography fontWeight='600' fontSize={24} textAlign='center'>
              {getMembershipName()}
            </Typography>
          </CoverageCard>
        </Grid>
        <Grid item xs={12} md={6} display='flex' minHeight='10rem'>
          <CoverageCard title='Pricing'>
            <Typography fontWeight='700' fontSize={24} textAlign='center'>
              {getPriceAccordingToPaymentRecurrence(coverage?.prices)}
            </Typography>
            <Typography variant='subtitle2' textAlign='center'>
              {isMonthly ? 'Monthly' : 'Yearly'} payments
            </Typography>
            {annualDiscountEnabled && (
              <Typography variant='subtitle2' textAlign='center'>
                <ElementTracker name='Switch payment' routePath={routePath}>
                  <Link onClick={handlePaymentModeToggle}>
                    switch to {isMonthly ? 'yearly' : 'monthly'}
                  </Link>
                </ElementTracker>
              </Typography>
            )}
            <Typography variant='caption' textAlign='center' display='block'>
              *additional costs may be incurred
            </Typography>
          </CoverageCard>
        </Grid>
      </Grid>

      <Typography variant='h3' marginY={4}>
        By becoming an Enara member you'll have access to:
      </Typography>

      <Stack direction='column'>
        {coverage?.items.map((section, index) => (
          <Dropdown key={`coverage-accordion-${index}`} fontSize={18} text={section.text}>
            <List>
              {section.items!.map((item, key) => (
                <ListItem key={`coverage-accordion-item-${key}`}>
                  <Typography fontSize={16} fontWeight={400} textAlign='justify'>
                    {item}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Dropdown>
        ))}
      </Stack>
    </BasicLayout>
  );
};

export default withProgress(Coverage, Page.Coverage);
