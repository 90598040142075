import { ElementTracker, ElementTrackerType, useViewport } from '@enaratech/funnel-helper';
import { Button, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { FC, ReactNode, isValidElement, useEffect, useState } from 'react';
import BackButton from 'src/components/Common/BackButton/BackButton';
import LoadingIndicator from 'src/components/Common/LoadingIndicator/LoadingIndicator';
import { useRoutePath } from 'src/hooks/useRoutePath';
import './scss/basicLayout.scss';

const KEYBOARD_HEIGHT = 300;
const TABLET_MIN_HEIGHT = 700;

type ButtonProps = {
  text?: string;
  disabled?: boolean;
  loading?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  onClick: () => void;
};

type Props = {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  children: ReactNode;
  className?: string;
  back?: boolean;
  buttonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  loading?: boolean;
};

const BasicLayout: FC<Props> = ({
  title,
  subtitle,
  back,
  className,
  children,
  buttonProps,
  secondaryButtonProps,
  loading = false,
}) => {
  const [isKeyboardOpen, setKeyboardOpen] = useState(false);

  const { isMobileView, isDesktopView, isTabletView } = useViewport();
  const routePath = useRoutePath();

  const handleClick = (): void => {
    buttonProps!.onClick();
  };
  const handleSecondaryButtonClick = (): void => {
    secondaryButtonProps!.onClick();
  };

  useEffect(() => {
    const validateIfKeyboardIsOpen = () => {
      if (isDesktopView && isKeyboardOpen) {
        setKeyboardOpen(false);
      }

      if (isMobileView || isTabletView) {
        const isKeyboardDisplayed =
          window.screen.height - KEYBOARD_HEIGHT >= window.visualViewport!.height;

        if (isKeyboardOpen !== isKeyboardDisplayed) {
          setKeyboardOpen(
            window.visualViewport!.height >= TABLET_MIN_HEIGHT ? false : isKeyboardDisplayed
          );
        }
      }
    };
    window.visualViewport!.addEventListener('resize', validateIfKeyboardIsOpen);

    return () => window.visualViewport!.removeEventListener('resize', validateIfKeyboardIsOpen);
  }, [isDesktopView, isMobileView, isTabletView, isKeyboardOpen]);

  return (
    <Stack
      justifyContent={'stretch'}
      alignItems={'center'}
      className={`wrapper-basic-layout ${className}`}>
      <Paper elevation={0} className='container-basic-layout'>
        <Stack justifyContent={'stretch'} alignItems={'center'}>
          <Paper
            elevation={0}
            square
            className='title-basic-layout'
            style={subtitle ? { paddingBottom: 28 } : { paddingBottom: 16 }}>
            {back && <BackButton />}
            {isValidElement(title) && title ? (
              title
            ) : (
              <Typography variant={'h1'}>{title}</Typography>
            )}
            {isValidElement(subtitle) ? (
              subtitle
            ) : (
              <Typography variant={'h4'}>{subtitle}</Typography>
            )}
          </Paper>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <Paper elevation={0} square className='line-basic-layout'>
              {children}
            </Paper>
          )}
        </Stack>
      </Paper>
      {(buttonProps || secondaryButtonProps) && (
        <Paper
          elevation={0}
          square
          className={`bottom-bar-basic-layout w-full ${
            secondaryButtonProps ? 'bottom-bar-buttons' : ''
          }`}
          sx={{
            position: (isMobileView || isTabletView) && isKeyboardOpen ? 'absolute' : 'fixed',
          }}>
          {secondaryButtonProps && (
            <ElementTracker
              routePath={routePath}
              name='Close Button'
              type={ElementTrackerType.Clickable}
              value={secondaryButtonProps?.text || 'Close'}>
              <Button
                name='Close Button'
                variant={secondaryButtonProps?.variant ?? 'outlined'}
                onClick={handleSecondaryButtonClick}
                disabled={secondaryButtonProps.disabled || secondaryButtonProps.loading}>
                {secondaryButtonProps.loading ? (
                  <CircularProgress color='primary' />
                ) : (
                  secondaryButtonProps.text || 'Close'
                )}
              </Button>
            </ElementTracker>
          )}
          {buttonProps && (
            <ElementTracker
              routePath={routePath}
              name='Next Button'
              type={ElementTrackerType.Clickable}
              value={buttonProps?.text || 'Next'}>
              <Button
                name='Next Button'
                variant='contained'
                onClick={handleClick}
                disabled={buttonProps.disabled || buttonProps.loading}>
                {buttonProps.loading ? (
                  <CircularProgress color='primary' />
                ) : (
                  buttonProps.text || 'Next'
                )}
              </Button>
            </ElementTracker>
          )}
        </Paper>
      )}
    </Stack>
  );
};

export default BasicLayout;
