import {
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  AuthActionTypes,
  AuthContextValue,
  AuthState,
  CLEAR_AUTH_INFO,
  SET_AUTH_INFO,
  SET_HINT_PATIENT_ID,
  SET_INITIAL_APPOINTMENT_ID,
  SET_USER_INSURANCE_COMPANY,
  SET_USER_INSURANCE_OUTCOME,
  SET_USER_UUID,
} from './types';

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

const initialState: AuthState = {
  loaded: false,
  isReferral: false,
  user: null,
  uuid: '',
};

const authReducer = (prevState: AuthState, action: AuthActionTypes): AuthState => {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_AUTH_INFO:
      return { ...initialState };

    case SET_USER_UUID:
      localStorage.setItem('uuid', payload);
      return { ...prevState, uuid: payload };

    case SET_AUTH_INFO:
      localStorage.setItem('uuid', payload.user?.uuid || prevState.uuid);
      return { ...prevState, uuid: payload.user?.uuid || prevState.uuid, ...payload };

    case SET_USER_INSURANCE_COMPANY:
      return { ...prevState, user: { ...prevState.user!, insuranceCompany: payload } };

    case SET_HINT_PATIENT_ID:
      return { ...prevState, user: { ...prevState.user!, hintId: payload } };

    case SET_INITIAL_APPOINTMENT_ID:
      return { ...prevState, user: { ...prevState.user!, initialAppointmentId: payload } };

    case SET_USER_INSURANCE_OUTCOME:
      return { ...prevState, user: { ...prevState.user!, insuranceOutcome: payload } };

    default:
      return prevState;
  }
};

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }): ReactElement => {
  const [authState, dispatchAuth] = useReducer(authReducer, initialState);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    let uuid = localStorage.getItem('uuid');

    if (!uuid) {
      uuid = uuidv4();
    }

    dispatchAuth({ type: SET_USER_UUID, payload: uuid });
  }, []);

  return (
    <AuthContext.Provider value={{ authState, dispatchAuth }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValue => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuth must be used within a AuthContext.Provider');
  }

  return authContext;
};
