import { FC, ReactNode, useEffect, useState } from 'react';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import '../scss/progress.scss';
import IPOProgressBar from './IPOProgressBar';

export enum Page {
  Welcome,
  FAQ,
  PaymentMethod,
  PhoneVerification,
  NewMemberProfile,
  ScheduleAppointments,
  ScheduleAppointmentsResult,
  IPOCoverage,
  IPOEligibilityCheckBooking,
}

type Props = {
  page: Page;
  children: ReactNode;
};

const IPOProgress: FC<Props> = ({ page, children }) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className='indicator-container w-full'>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <>
      <IPOProgressBar currentPage={page} />
      {children}
    </>
  );
};

export const withProgress =
  (WrappedComponent: FC, page: Page): FC =>
  () => {
    return (
      <IPOProgress page={page}>
        <WrappedComponent />
      </IPOProgress>
    );
  };
