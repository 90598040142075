import {
  MixpanelClient,
  MixpanelEvent,
  coreClient,
  getPartners,
  useViewport,
} from '@enaratech/funnel-helper';
import { FC, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from 'src/contexts/auth';
import { SET_AUTH_INFO } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { SET_CLINIC_INFO } from 'src/contexts/clinic/types';
import { useOnboarding } from 'src/contexts/onboarding';
import { SET_ONBOARDING_RESULT } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { allRoutes, getAppDefaultPath, goBackTo } from '../../pages/routes';
import '../../scss/main.scss';

const App: FC = () => {
  const navigate = useNavigate();

  const { dispatchOnboarding } = useOnboarding();
  const { dispatchClinic } = useClinic();
  const { dispatchAuth } = useAuth();

  const { isMobileView } = useViewport();
  const routePath = useRoutePath();

  useEffect(() => {
    const handlePopState = (event: PopStateEvent): void => {
      event.preventDefault();
      window.history.pushState(null, '', window.location.href);

      goBackTo({ pathname: window.location.pathname, navigate });
    };

    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    MixpanelClient.trackEvent({
      event: MixpanelEvent.PageView,
      properties: {
        value: routePath,
      },
    });
  }, [routePath]);

  useEffect(() => {
    (async () => {
      const { user, clinic, onboarding, isReferral } = await coreClient.fetchAll();

      const partners = await getPartners();

      dispatchOnboarding({ type: SET_ONBOARDING_RESULT, payload: onboarding });
      dispatchClinic({ type: SET_CLINIC_INFO, payload: clinic ? { ...clinic, partners } : null });
      dispatchAuth({ type: SET_AUTH_INFO, payload: { loaded: true, isReferral, user } });
    })();
  }, [dispatchOnboarding, dispatchClinic, dispatchAuth]);

  return (
    <>
      <Routes>
        {Object.keys(allRoutes).map((path) => {
          const { Component } = allRoutes[path as keyof typeof allRoutes];
          return <Route key={path} path={path} element={<Component />} />;
        })}
        <Route path='*' element={<Navigate to={getAppDefaultPath()} />} />
      </Routes>

      <ToastContainer
        position='top-center'
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
        style={{
          margin: 0,
          width: `${isMobileView ? '70%' : '30%'}`,
        }}
      />
    </>
  );
};

export default App;
