import {
  clinicClient,
  deleteAuth,
  getPartnerBySlug,
  getPartners,
  ProgressKey,
  reportErrorToHoneybadger,
  storeAnonymousProgress,
  wipeOutAnonymousProgress,
} from '@enaratech/funnel-helper';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingIndicator from 'src/components/Common/LoadingIndicator/LoadingIndicator';
import { useApp } from 'src/contexts/app';
import { useAuth } from 'src/contexts/auth';
import { CLEAR_AUTH_INFO } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { SET_CLINIC_INFO } from 'src/contexts/clinic/types';
import { useOnboarding } from 'src/contexts/onboarding';
import { CLEAR_ONBOARDING_RESULT } from 'src/contexts/onboarding/types';
import { wipeOutProgress } from 'src/lib/IPOStorage';
import { IPOPath, navigateToPage } from '../routes';

const Branding: FC = () => {
  const { partnerSlug } = useParams<{
    partnerSlug: string;
  }>();
  const navigate = useNavigate();
  const { dispatchAuth } = useAuth();
  const { dispatchOnboarding } = useOnboarding();
  const { dispatchClinic, clinicState } = useClinic();
  const { changeSkipPartnerPageStatus, lockNavigationBetweenPages } = useApp();

  useEffect(() => {
    if (!partnerSlug) {
      return navigateToPage({ targetPage: '/goals', navigate });
    }

    const partner = getPartnerBySlug(partnerSlug, clinicState!.partners);

    if (!partner) {
      return navigateToPage({ targetPage: '/goals', navigate });
    }

    // Safeguard: We don't want to create the new account with data stored locally
    wipeOutAnonymousProgress();
    // Clear all data before going to Welcome page
    wipeOutProgress();
    deleteAuth();
    dispatchAuth({ type: CLEAR_AUTH_INFO });
    dispatchOnboarding({ type: CLEAR_ONBOARDING_RESULT });
    (async () => {
      try {
        const clinicInfo = await clinicClient.fetchClosestClinicByZipCode(
          partner.zipCode.toString()
        );

        const partners = await getPartners();

        dispatchClinic({ type: SET_CLINIC_INFO, payload: { ...clinicInfo, partners } });
        changeSkipPartnerPageStatus(true);

        const {
          details: { zipCode, clinicId },
        } = clinicInfo;

        storeAnonymousProgress(ProgressKey.Partner, { zipCode, clinicId });
      } catch (error) {
        reportErrorToHoneybadger({ error });
      }

      lockNavigationBetweenPages();
      navigateToPage({ targetPage: IPOPath.Welcome, navigate });
    })();
  }, [
    navigate,
    partnerSlug,
    dispatchClinic,
    changeSkipPartnerPageStatus,
    lockNavigationBetweenPages,
    dispatchAuth,
    dispatchOnboarding,
  ]);

  return (
    <div className='indicator-container'>
      <LoadingIndicator />
    </div>
  );
};

export default Branding;
