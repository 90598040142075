import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import AfterLossWeight from '../../../assets/images/woman-after.png';
import BeforeLossWeight from '../../../assets/images/woman-before.png';

const ComingCallResult: FC = () => {
  return (
    <BasicLayout
      title='You’re one step closer to a healthier version of yourself.'
      subtitle='Enara has helped members just like you achieve their weight loss goals and improve their health and overall well-being.'>
      <>
        <Box mb={4}>
          <Typography align='center' variant='h3'>
            Linda has lost and maintained a <br />
            48.5lb weight loss!
          </Typography>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={6}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'>
                <Box p={1}>
                  <img
                    src={BeforeLossWeight}
                    alt='Woman before loss weight with Enara Health'
                    width='100%'
                  />
                </Box>
                <Typography
                  variant='subtitle2'
                  fontSize={18}
                  align='center'
                  mt={2}
                  mb={1}
                  lineHeight={1}>
                  Starting weight
                </Typography>
                <Typography variant='subtitle2' fontSize={18} align='center' mb={1} lineHeight={1}>
                  210.98lbs
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'>
                <Box p={1}>
                  <img
                    src={AfterLossWeight}
                    alt='Woman after loss weight with Enara Health'
                    width='100%'
                  />
                </Box>
                <Typography
                  variant='subtitle2'
                  fontSize={18}
                  align='center'
                  mt={2}
                  mb={1}
                  lineHeight={1}>
                  Current weight
                </Typography>
                <Typography variant='subtitle2' fontSize={18} align='center' mb={1} lineHeight={1}>
                  162.48lbs
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Typography variant='subtitle2' fontSize={18} align='center'>
            “Along with my health improving and no longer worrying about adding insulin to my
            diabetes care, I have more ease of movement and feel better in my body. The only real
            drawback has been that I had to buy an entire new wardrobe!”
          </Typography>
        </Box>
        <Box mt={2} display='flex' justifyContent='center' alignItems='center'>
          <Button variant='contained'>Read more</Button>
        </Box>
      </>
    </BasicLayout>
  );
};

export default withProgress(ComingCallResult, Page.ComingCallResult);
