import {
  AvailableTimeSlotData,
  DatesWithPrioritizedProviders,
  PrioritizedDailyProviderAvailability,
  PrioritizedMonthlyProviderAvailability,
  getLocalTime,
} from '@enaratech/funnel-helper';

/**
 * @description Obtains the appointments for the most prioritized clinics and returns them formatted
 */
export const formatPrioritizedProvidersByDates = (
  prioritizedMonthlyAvailability: PrioritizedMonthlyProviderAvailability['dates']
) => {
  const prioritizedAvailability: DatesWithPrioritizedProviders = {};

  Object.entries(prioritizedMonthlyAvailability)
    .filter(([_, slotsData]) => slotsData.slotsByProvider.length > 0)
    .forEach(([date, slotsData]) => {
      prioritizedAvailability[date as string] = slotsData.slotsByProvider.map((p) => ({
        ...p.provider,
        acuityAppointmentTypeId: p.acuityAppointmentTypeId,
      }));
    });

  if (!Object.values(prioritizedAvailability).length) {
    return null;
  }

  return prioritizedAvailability;
};

/**
 * @description Return the SLOT as a key to be able to identify the time slot
 * @example dateTimeSlot_acuityAppointmentTypeId_acuityCalendarId1_acuityCalendarId2_acuityCalendarId3
 */
export const getTimeSlotKey = (slot: {
  acuityAppointmentTypeId: number;
  acuityCalendarIds: number[];
  dateTime: string;
}): string =>
  `${slot.dateTime}_${slot.acuityAppointmentTypeId}_${slot.acuityCalendarIds.join('_')}`;

/**
 * @param timeSlotKey Time slot key: timeSlot_acuityAppointmentTypeId_acuityCalendarId1_acuityCalendarId2_acuityCalendarId3
 */
export const getDataFromTimeSlotKey = (
  timeSlotKey: string
): {
  dateTime: string;
  acuityCalendarIds: number[];
  acuityAppointmentTypeId: number;
} => {
  const [dateTime, acuityAppointmentTypeId, ...acuityCalendarIds] = timeSlotKey.split('_');

  return {
    dateTime,
    acuityCalendarIds: acuityCalendarIds.map((ac) => Number(ac)),
    acuityAppointmentTypeId: Number(acuityAppointmentTypeId),
  };
};

export const cleanAndFormatSlots = (
  availableAppointments: PrioritizedDailyProviderAvailability,
  patientTimezone: string
): AvailableTimeSlotData[] => {
  const slots: { [localDateTime: string]: AvailableTimeSlotData } = {};

  for (let i = 0; i < availableAppointments.slots.length; i++) {
    const slot = availableAppointments.slots[i];

    const localDateTime = getLocalTime({
      isoDateToConvert: slot.time,
      localTimezone: patientTimezone,
    });

    if (!slots[localDateTime]) {
      slots[localDateTime] = {
        acuityAppointmentTypeId: slot.slotsByProvider[0].acuityAppointmentTypeId,
        acuityCalendarIds: slot.slotsByProvider.map(({ provider }) => provider.acuityCalendarId),
        localDateTime,
        dateTime: slot.time,
      };
    }
  }

  return Object.values(slots);
};
