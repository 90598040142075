import { toast, TypeOptions } from 'react-toastify';
import CircleInfoIcon from 'src/components/Common/Icons/CircleInfoIcon';
import CircleSelectionCheckedIcon from 'src/components/Common/Icons/CircleSelectionCheckedIcon';
import ErrorIcon from 'src/components/Common/Icons/ErrorIcon';
import './scss/toast.scss';

const getIcon = (type: string) => {
  if (type === 'success') return <CircleSelectionCheckedIcon />;
  if (type === 'info') return <CircleInfoIcon />;
  if (type === 'error') return <ErrorIcon />;
};

const toastOptions = {
  notification: (
    type: TypeOptions,
    message: string,
    options: {
      autoClose?: boolean;
      closeOnClick?: boolean;
      draggable?: boolean;
    } = {
      autoClose: true,
      closeOnClick: true,
      draggable: true,
    }
  ) => {
    const { autoClose, closeOnClick, draggable } = options;

    toast(message, {
      type,
      closeButton: false,
      className: 'toast-body',
      theme: 'colored',
      position: toast.POSITION.TOP_CENTER,
      icon: ({ type }) => getIcon(type),
      autoClose: autoClose ? undefined : false,
      closeOnClick,
      draggable,
    });
  },
};

export default toastOptions;
