import {
  AvailableLanguages,
  ContactMethod,
  ElementTracker,
  ElementTrackerType,
  FeatureManager,
  FUNNEL_VERSION,
  getAnonymousProgress,
  isEmailValid,
  isPhoneValid,
  isTestingCredential,
  isValidPassword,
  LeadOnboardingStage,
  Metadata,
  MixpanelClient,
  MixpanelEvent,
  onboardingClient,
  ProgressKey,
  ReferralMethod,
  StatsigEventName,
  StatsigManager,
  userClient,
  VARIANT_VERIFICATION_CODE,
  wipeOutAnonymousProgress,
} from '@enaratech/funnel-helper';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Agreements, { RefAgreements } from 'src/components/Common/Agreements/Agreements';
import CheckCircleIcon from 'src/components/Common/Icons/CheckCircleIcon';
import ViewIcon from 'src/components/Common/Icons/ViewIcon';
import ViewIconOff from 'src/components/Common/Icons/ViewIconOff';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import TermsOfUse from 'src/components/Common/TermsOfUse/TermsOfUse';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useApp } from 'src/contexts/app';
import { useAuth } from 'src/contexts/auth';
import { SET_AUTH_INFO } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { useExperiment } from 'src/contexts/experiments';
import { SET_USER_METADATA } from 'src/contexts/experiments/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { expandExperimentUser } from 'src/lib/experiments';
import '../Insurance/scss/insurance.scss';
import { navigateToPage } from '../routes';
import { CreateAccountErrors, CreateAccountForm } from './createAccount.types';
import { inputFields } from './inputFields';
import { getReferralMethods } from './referralMethods';
import './scss/createAccount.scss';
import VerificationCodeInput from './VerificationCodeInput/VerificationCodeInput';

const CreateAccount: FC = () => {
  const [formState, setFormState] = useState<CreateAccountForm>({
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState<Partial<CreateAccountErrors> | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [referralMethod, setReferralMethod] = useState<ReferralMethod | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [validCode, setValidCode] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<{
    password: boolean;
    confirmPassword: boolean;
  }>({
    password: false,
    confirmPassword: false,
  });
  const [agreementsLoaded, setAgreementsLoaded] = useState<boolean>(false);
  const [allAgreementsSelected, setAllAgreementsSelected] = useState<boolean>(false);

  const agreementsRef = useRef<RefAgreements>(null);
  const navigate = useNavigate();

  const {
    dispatchAuth,
    authState: { uuid },
  } = useAuth();
  const { clinicState } = useClinic();
  const { shouldSkipPartnerPage } = useApp();
  const routePath = useRoutePath();

  // Experiment facing changes
  const {
    experimentState: { isInitialized },
    dispatchExperimentState,
  } = useExperiment();

  const isArrivingFromAdwords = useMemo(() => {
    if (!isInitialized) {
      return false;
    }

    return StatsigManager.isArrivingFromAdwords();
  }, [isInitialized]);

  useEffect(() => {
    const progress = getAnonymousProgress();
    const anonymousProgress = progress ? progress[ProgressKey.GetStarted] : null;

    if (!anonymousProgress) {
      // For some reason, user don't have previous data
      navigateToPage({ targetPage: '/get-started', navigate });
      return;
    }

    setUserName(anonymousProgress.firstName);

    if (!!anonymousProgress.email) {
      setFormState((prev) => ({
        ...prev,
        email: anonymousProgress.email!,
      }));
    }
  }, []);

  const onSendCode = async () => {
    try {
      await onboardingClient.verifyCode({ phoneNumber: formState.phone });
    } catch (error) {
      Toast.notification('error', 'There was an error, please try again later');
    }
  };

  const validateForm = (field: string, value: string) => {
    const updatedFormState = { ...formState, [field]: value };

    if (!updatedFormState[field as keyof typeof updatedFormState]) {
      setErrors((prev) => ({
        ...prev,
        [field]: [
          `The ${
            field === 'confirmPassword' ? 'confirm password' : field.toLowerCase()
          } is required`,
        ],
      }));
    }

    if (updatedFormState[field as keyof typeof updatedFormState].length > 0) {
      setErrors((prev) => ({ ...prev, [field]: [] }));
    }

    if (field === 'email' && !isEmailValid(updatedFormState.email)) {
      setErrors((prev) => ({ ...prev, [field]: ['Please enter a valid email'] }));
    }

    if (field === 'phone' && !isTestingCredential(updatedFormState.phone)) {
      if (!isPhoneValid(updatedFormState.phone)) {
        setErrors((prev) => ({ ...prev, [field]: ['Please enter a valid phone number'] }));
      }
    }

    if (['password', 'confirmPassword'].includes(field)) {
      if (!isValidPassword(updatedFormState.password as string)) {
        setErrors((prev) => ({
          ...prev,
          password: [
            'A lowercase letter (a-z)',
            'An uppercase letter (A-Z)',
            'A Number (0-9)',
            'Being at least 8 characters',
          ],
        }));
      } else if (updatedFormState.confirmPassword.length > 0) {
        setErrors((prev) => ({
          ...prev,
          confirmPassword:
            updatedFormState.confirmPassword !== updatedFormState.password
              ? ['Passwords do not match']
              : [],
        }));
      }
    }

    setFormState(updatedFormState);
  };

  const handleSubmit = async () => {
    if (formState.confirmPassword !== formState.password) {
      return setErrors({ ...errors, confirmPassword: ['Passwords do not match'] });
    }

    const anonymousProgress = getAnonymousProgress()!;

    const goals = anonymousProgress[ProgressKey.ProgramGoals];
    const getStarted = anonymousProgress[ProgressKey.GetStarted];
    const dob = anonymousProgress[ProgressKey.DateOfBirth];
    const partner = anonymousProgress[ProgressKey.Partner];

    const { email, phone, password } = formState;

    setIsSubmitting(true);

    if (
      !(await userClient.checkAvailability(email)) ||
      !(await userClient.checkAvailability(phone))
    ) {
      Toast.notification('error', 'The email address or phone number is already in use');
      return setIsSubmitting(false);
    }

    const memberBMI = Number(getStarted.bmi).toFixed(2);

    const { details, program } = clinicState!;

    const user = await userClient.createUser({
      uuid,
      city: details.city,
      clinicId: details.clinicId.toString(),
      contactMethod: ContactMethod.Funnel,
      dateOfBirth: dob.dateOfBirth,
      email,
      firstName: getStarted.firstName,
      gender: dob.gender,
      goals,
      heightFeet: getStarted.heightFeet,
      heightInches: getStarted.heightInches,
      lastName: getStarted.lastName,
      password,
      phone,
      state: details.state,
      weightLbs: getStarted.weightLbs,
      zipCode: partner.zipCode,
      clinicZipCode: details.zipCode,
      programType: clinicState!.program, // In this point the user doesn't have program type, so it's fine to take it from clinic
      bmi: Number(memberBMI),
    });

    if (!user) {
      Toast.notification('error', 'User could not be created');

      MixpanelClient.trackEvent({
        event: MixpanelEvent.Show,
        properties: {
          field: 'Error message',
          value: 'Could not create user',
          source: routePath,
        },
      });

      return setIsSubmitting(false);
    }

    wipeOutAnonymousProgress();

    const userMetadata = {
      email,
      firstName: getStarted.firstName,
      lastName: getStarted.lastName,
      programType: program,
      ...(referralMethod && { referralMethod }),
      ...(user.utmSource && { utmSource: user.utmSource }),
      ...(shouldSkipPartnerPage ? { referral: 'partner-url' } : {}),
      clinicId: clinicState!.details.clinicId,
    };

    MixpanelClient.identityAlias(user.id);
    MixpanelClient.setMetadata({
      ...userMetadata,
      isNewMember: true,
      experiments: StatsigManager.getExperimentsSummary(),
    } as Partial<Metadata>);

    // Updating user for experiments dashboard
    StatsigManager.logEvent({
      eventName: StatsigEventName.AccountCreation,
      metadata: {
        ...userMetadata,
        clinicId: `${userMetadata.clinicId}`,
        memberId: `${user.id}`,
        memberUuid: user.uuid,
        systemSource: ContactMethod.Funnel,
      },
    });

    userClient.createDefaultProviders();

    const updatedUser = await userClient.fetchUser();

    dispatchAuth({
      type: SET_AUTH_INFO,
      payload: { loaded: true, isReferral: false, user: updatedUser },
    });

    dispatchExperimentState({
      type: SET_USER_METADATA,
      payload: expandExperimentUser(user.id, {
        appVersion: FUNNEL_VERSION,
        custom: userMetadata,
        email: userMetadata.email,
        userID: uuid,
      }),
    });

    onboardingClient.updateMemberStatus({
      onboardingStage: LeadOnboardingStage.AccountCreation,
      firstLanguage: AvailableLanguages.English,
      ...(referralMethod && { referralMethod }),
      programType: clinicState!.program,
    });

    if (agreementsRef.current) {
      agreementsRef.current.signAgreements();
    }

    if (isArrivingFromAdwords) {
      navigateToPage({ targetPage: '/appointment/ms-booking', navigate });
    } else {
      navigateToPage({ targetPage: '/insurance', navigate });
    }
  };

  const trackMixpanelEvent = (field: string, event: MixpanelEvent, value: string) => {
    MixpanelClient.trackEvent({
      event,
      properties: {
        field,
        value,
        source: routePath,
      },
    });
  };

  // Experiment facing changes
  const resolveFieldReadOnly = (field: string) => {
    // It should allow to fill email if for some reason is not loaded from get-started
    if (field === 'email' && !!formState.email) {
      return true;
    }

    return undefined;
  };

  return (
    <BasicLayout
      title={`Awesome, ${userName}!`}
      subtitle='Let’s set up your account and check your insurance'
      buttonProps={{
        disabled:
          !agreementsLoaded ||
          !allAgreementsSelected ||
          Object.values(formState).some((i) => i.length === 0) ||
          (!!errors && Object.values(errors).some((i) => i.length > 0)) ||
          FeatureManager.isVariantEnabled(VARIANT_VERIFICATION_CODE)
            ? !validCode
            : false,
        loading: isSubmitting,
        onClick: handleSubmit,
      }}
      back>
      <form>
        <Grid container spacing={2}>
          {inputFields.map(({ label, field, name }) => (
            <Grid item xs={12} sm={6} key={`create-account-grid-${field}`}>
              <ElementTracker
                routePath={routePath}
                name={name}
                type={ElementTrackerType.Blurrable}
                value={
                  ['Confirm Password', 'Password'].includes(name)
                    ? !!errors?.[field as keyof typeof errors] === false
                    : undefined
                }>
                <TextField
                  data-test={`createAccount-input-${field}`}
                  name={name}
                  label={label}
                  fullWidth
                  variant='filled'
                  type={
                    !['confirmPassword', 'password'].includes(field)
                      ? 'text'
                      : showPassword[field as keyof typeof showPassword]
                      ? 'text'
                      : 'password'
                  }
                  // @ts-ignore
                  FormHelperTextProps={{ component: 'span' }}
                  InputProps={{
                    readOnly: resolveFieldReadOnly(field),
                    endAdornment: ['confirmPassword', 'password'].includes(field) ? (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              [field]: !showPassword[field as keyof typeof showPassword],
                            })
                          }>
                          {showPassword[field as keyof typeof showPassword] ? (
                            <ViewIcon />
                          ) : (
                            <ViewIconOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ) : 'phone' === field &&
                      FeatureManager.isVariantEnabled(VARIANT_VERIFICATION_CODE) ? (
                      <InputAdornment position='end'>
                        <IconButton onClick={onSendCode} name={'Send Code'}>
                          <CheckCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                  autoComplete={field}
                  value={formState[field as keyof typeof formState]}
                  onChange={(e) => validateForm(field, e.target.value)}
                  error={!!errors?.[field as keyof typeof errors]?.length}
                  helperText={
                    !!errors?.[field as keyof typeof errors] && (
                      <ul>
                        {(Object.values(errors[field as keyof typeof errors]!) as string[]).map(
                          (entry, i) => (
                            <li key={`password-hint-${i}`}>{entry}</li>
                          )
                        )}
                      </ul>
                    )
                  }
                />
              </ElementTracker>
            </Grid>
          ))}
        </Grid>
      </form>

      {FeatureManager.isVariantEnabled(VARIANT_VERIFICATION_CODE) && (
        <VerificationCodeInput
          phone={formState.phone}
          handleSendCode={onSendCode}
          handleValidCode={setValidCode}
        />
      )}

      <Box my={4}>
        <Typography className='manual-insurance-label' variant={'h5'}>
          How did you hear about us?
        </Typography>
        <Autocomplete
          className='autocomplete'
          options={getReferralMethods()}
          renderInput={(params) => <TextField {...params} placeholder='Select one' />}
          onChange={(_, value) => {
            setReferralMethod(value?.value || null);
            if (value) {
              trackMixpanelEvent('Referral Method', MixpanelEvent.Select, value.value);
            }
          }}
        />
      </Box>

      <TermsOfUse />

      <Box my={2}>
        <Agreements
          ref={agreementsRef}
          agreementsMode={'public'}
          onLoaded={useCallback(() => setAgreementsLoaded(true), [])}
          onSelect={useCallback((all) => setAllAgreementsSelected(all), [])}
        />
      </Box>
    </BasicLayout>
  );
};

export default withProgress(CreateAccount, Page.CreateAccount);
