import { InputBaseComponentProps } from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';
import { forwardRef, useImperativeHandle, useState } from 'react';

const StripeInput = forwardRef<any, InputBaseComponentProps>(function StripeInput(props, ref) {
  const { component: Component, options, ...other } = props;
  const theme = useTheme();
  const [mountNode, setMountNode] = useState<any | null>(null);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => mountNode.focus(),
    }),
    [mountNode]
  );

  return (
    <Component
      onReady={setMountNode}
      placeholder={false}
      options={{
        ...options,
        style: {
          base: {
            color: '#424242',
            fontSize: '16px',
            fontFamily: 'Proxima Nova',
            fontWeight: '400',
            '::placeholder': {
              color: '#9d9d9d',
              fontWeight: '250',
            },
          },
          invalid: {
            color: theme.palette.error.main,
          },
        },
      }}
      {...other}
    />
  );
});

export default StripeInput;
