import { AgreementsStatus } from '@enaratech/funnel-helper';
import { createContext, FC, ReactElement, ReactNode, useContext, useReducer } from 'react';
import {
  CLEAR_ONBOARDING_RESULT,
  OnboardingActionTypes,
  OnboardingContextValue,
  OnboardingState,
  SET_BOOKING_METHOD,
  SET_ELIGIBILITY,
  SET_FIRST_LANGUAGE,
  SET_ONBOARDING_RESULT,
} from './types';

const OnboardingContext = createContext<OnboardingContextValue | undefined>(undefined);

const initialState: OnboardingState = {
  member: {
    firstLanguage: null,
  },
  agreements: {
    status: AgreementsStatus.NotStarted,
    agreementsHistory: null,
  },
  eligibility: {
    pricing: null,
    membershipType: null,
  },
  locationFlow: null,
  bookingMethod: null,
  magicLink: null,
};

const onboardingReducer = (
  prevState: OnboardingState,
  action: OnboardingActionTypes
): OnboardingState => {
  const { type } = action;

  switch (type) {
    case CLEAR_ONBOARDING_RESULT:
      return { ...initialState };

    case SET_ONBOARDING_RESULT:
      return { ...prevState, ...action.payload };

    case SET_ELIGIBILITY:
      return {
        ...prevState,
        eligibility: { ...prevState.eligibility, membershipType: action.payload },
      };

    case SET_BOOKING_METHOD:
      return { ...prevState, bookingMethod: action.payload };

    case SET_FIRST_LANGUAGE:
      return { ...prevState, member: { ...prevState.member, firstLanguage: action.payload } };

    default:
      return prevState;
  }
};

export const OnboardingProvider: FC<{ children: ReactNode }> = ({ children }): ReactElement => {
  const [onboardingState, dispatchOnboarding] = useReducer(onboardingReducer, initialState);

  return (
    <OnboardingContext.Provider value={{ onboardingState, dispatchOnboarding }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = (): OnboardingContextValue => {
  const onboardingContext = useContext(OnboardingContext);

  if (!onboardingContext) {
    throw new Error('useOnboarding must be used within a OnboardingContext.Provider');
  }

  return onboardingContext;
};
