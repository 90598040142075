import { InputFieldsType } from '@enaratech/funnel-helper';

export enum InputFieldsForBMI {
  HeightFeet = 'heightFeet',
  HeightInches = 'heightInches',
  WeightLbs = 'weightLbs',
}

export const FieldMinLength: { [key: string]: number } = {
  email: 6,
  password: 8,
  confirmPassword: 8,
  // These fields are optional if BMI is present
  heightFeet: 0,
  heightInches: 0,
  weightLbs: 0,
};

export const inputFields: InputFieldsType[] = [
  {
    label: 'Your email',
    field: 'email',
    name: 'Email',
    track: true,
  },
  {
    label: 'Your password',
    field: 'password',
    name: 'Password',
    track: false,
  },
  {
    label: 'Confirm password',
    field: 'confirmPassword',
    name: 'Confirm Password',
    track: false,
  },
];
