import { ElementTracker, ElementTrackerType, deleteAuth } from '@enaratech/funnel-helper';
import { Button, Stack } from '@mui/material';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, withProgress } from 'src/components/Common/Progress/IPO/IPOProgress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { CLEAR_AUTH_INFO } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { useOnboarding } from 'src/contexts/onboarding';
import { CLEAR_ONBOARDING_RESULT } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { wipeOutProgress } from 'src/lib/IPOStorage';
import { IPOPath, navigateToPage } from '../../routes';

const Welcome: FC = () => {
  const navigate = useNavigate();
  const { dispatchAuth } = useAuth();
  const { dispatchOnboarding } = useOnboarding();
  const { clinicState } = useClinic();
  const routePath = useRoutePath();
  const getNewReferralURL = () => {
    return `${process.env.REACT_APP_LEADSQUARED_REFERRAL_FORM_URL}?clinicId=${clinicState?.details.clinicId}`;
  };

  useEffect(() => {
    wipeOutProgress();
    deleteAuth();
    dispatchAuth({ type: CLEAR_AUTH_INFO });
    dispatchOnboarding({ type: CLEAR_ONBOARDING_RESULT });
  }, [dispatchAuth, dispatchOnboarding]);

  return (
    <BasicLayout title='WELCOME TO ENARA!'>
      <Stack py={10} spacing={4} alignItems='center'>
        <ElementTracker
          routePath={routePath}
          name='Start on site'
          type={ElementTrackerType.Clickable}>
          <Button
            name='Start Onsite Button'
            variant='contained'
            onClick={() => {
              wipeOutProgress();
              navigateToPage({ targetPage: IPOPath.FAQ, navigate });
            }}>
            Start On-site
          </Button>
        </ElementTracker>
        <ElementTracker
          routePath={routePath}
          name='Continue on site'
          type={ElementTrackerType.Clickable}>
          <Button
            name='Continue Onsite Button'
            variant='contained'
            onClick={() => navigateToPage({ targetPage: IPOPath.PhoneVerification, navigate })}>
            Continue On-site
          </Button>
        </ElementTracker>
        <ElementTracker
          routePath={routePath}
          name='Add referral'
          type={ElementTrackerType.Clickable}>
          <Button
            name='Add Referral Button'
            variant='contained'
            onClick={() => window.open(getNewReferralURL(), '_blank')}>
            Add Referral
          </Button>
        </ElementTracker>
      </Stack>
    </BasicLayout>
  );
};

export default withProgress(Welcome, Page.Welcome);
