import { CoverageInfo } from '@enaratech/funnel-helper';
import { DiscountInfo, PaymentMode } from './paymentType.types';

export function displayDiscountMessage(
  planType: PaymentMode,
  prices: CoverageInfo['prices'],
  discount: DiscountInfo
): string {
  if (!discount.duration) {
    return `You just saved $${(prices.annual - discount.discountAnnually).toFixed(
      2
    )} off your monthly price for the duration of your plan!`;
  }

  if (planType === 'monthly') {
    if (discount.duration === 1) {
      return `You just saved $${(prices.monthly - discount.discountMonthly).toFixed(
        2
      )} off your first month! Each month after that will be at the $${prices.monthly}/month price`;
    }
    return `You just saved $${(prices.monthly - discount.discountMonthly).toFixed(
      2
    )} off your first ${discount.duration} months! Each month after that will be at the $${
      prices.monthly
    }/month price`;
  }

  return `You just saved $${(prices.annual - discount.discountAnnually).toFixed(
    2
  )} off your yearly price!`;
}
