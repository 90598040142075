import {
  ElementTracker,
  ElementTrackerType,
  isEmailValid,
  userClient,
} from '@enaratech/funnel-helper';
import {
  Button,
  CircularProgress,
  Divider,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, FC, FormEvent, ReactElement, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Toast from 'src/components/Common/Toast/Toast';
import AuthLayout from 'src/components/Layout/AuthLayout/AuthLayout';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { storeEmailInSession } from 'src/lib/session-storage';
import { navigateToPage } from '../routes';
import './scss/recoveryPassword.scss';

const ForgotPassword: FC = (): ReactElement => {
  const [values, setValues] = useState<{ email: string }>({ email: '' });
  const [error, setError] = useState<{ email: string }>({ email: '' });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();

  const routePath = useRoutePath();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const validateEmail = (email: string) => {
    if (!isEmailValid(email)) {
      setError({ email: 'Email is not valid' });
      return false;
    }
    return true;
  };

  const requestCode = async (): Promise<boolean> => {
    return await userClient.sendEmailToResetPassword({ email: values?.email });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!validateEmail(values?.email || '')) {
      return;
    }

    setIsSubmitting(true);
    const success = await requestCode();
    setIsSubmitting(false);

    if (success) {
      storeEmailInSession(values?.email);
      navigateToPage({ targetPage: '/verification-code', navigate });
    } else {
      setIsSubmitting(false);
      setValues({ email: '' });
      Toast.notification(
        'error',
        'An error has occurred while trying to recover your password. Please, try again'
      );
    }
  };

  return (
    <AuthLayout>
      <Box mb={1} mt={6}>
        <Typography variant='h1'>Password Reset</Typography>
      </Box>
      <Box mb={3}>
        <Typography variant='h4'>Please enter the email associated with your account.</Typography>
      </Box>
      <Box mb={4}>
        <Divider />
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={6}>
          <FormGroup className='form-group'>
            <Box mb={1}>
              <label htmlFor='email'>Email</label>
            </Box>
            <ElementTracker routePath={routePath} name='Email' type={ElementTrackerType.Blurrable}>
              <TextField
                type='email'
                variant='filled'
                name='email'
                placeholder='Your Email'
                hiddenLabel
                error={!!error?.email}
                helperText={error?.email}
                value={values.email}
                onChange={handleChange}
              />
            </ElementTracker>
          </FormGroup>
        </Box>
        <FormGroup className='form-group'>
          <ElementTracker
            routePath={routePath}
            name='Forgot Password button'
            type={ElementTrackerType.Clickable}>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              disabled={!isEmailValid(values.email) || isSubmitting}>
              {isSubmitting ? <CircularProgress /> : 'Reset'}
            </Button>
          </ElementTracker>
        </FormGroup>
        <Grid container item mt={4} justifyContent='center' alignItems='center'>
          <ElementTracker
            routePath={routePath}
            name='Back to login link'
            type={ElementTrackerType.Clickable}>
            <NavLink to='/login'>
              <Typography variant='subtitle1' color='primary'>
                Back to Login
              </Typography>
            </NavLink>
          </ElementTracker>
        </Grid>
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
