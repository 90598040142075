import {
  ElementTracker,
  ElementTrackerType,
  getFileAsBase64,
  MAX_WIDTH_FOR_RESIZED_IMAGES,
  needsResize,
  QUALITY_FOR_RESIZED_IMAGES,
  resizeImage,
} from '@enaratech/funnel-helper';
import { Button, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ChangeEvent, FC, MouseEvent, ReactElement, useRef, useState } from 'react';
import FileUploadIcon from 'src/components/Common/Icons/FileUploadIcon';
import { useRoutePath } from 'src/hooks/useRoutePath';
import Camera from './Camera';
import './scss/fileUpload.scss';

type Props = {
  text: string;
  face: 'front' | 'back';
  onSelectFile: (base64: string) => void;
  previewTitle?: string;
};

const FileUpload: FC<Props> = ({ text, face, onSelectFile, previewTitle }): ReactElement => {
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [anchorElement, setAnchorElement] = useState<null | SVGSVGElement | HTMLButtonElement>(
    null
  );
  const [showCamera, setShowCamera] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const routePath = useRoutePath();

  const handleMenuItem =
    ({ fromCamera }: { fromCamera: boolean }) =>
    (): void => {
      if (fromCamera) {
        setShowCamera(true);
      } else {
        inputRef.current?.click();
      }

      setAnchorElement(null);
    };

  const handleFileSelection = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { files } = e.target;

    if (!files) {
      return;
    }

    const file = files[0];
    const base64 = await getFileAsBase64(file);

    if (needsResize(file)) {
      const resizedImage = await resizeImage({
        dataUrl: base64,
        maxSize: MAX_WIDTH_FOR_RESIZED_IMAGES,
        type: 'image/jpeg',
        quality: QUALITY_FOR_RESIZED_IMAGES,
      });

      onSelectFile(resizedImage);
      setPreviewImage(resizedImage);
    } else {
      onSelectFile(base64);
      setPreviewImage(base64);
    }
  };

  const handleCaptureImage = (base64: string): void => {
    onSelectFile(base64);
    setPreviewImage(base64);
    setShowCamera(false);
  };

  return (
    <>
      {previewImage ? (
        <>
          <Typography variant='h4'>
            {previewTitle ?? 'Here is the picture of your insurance card. Looks good'}
          </Typography>
          <div className='preview-box'>
            <img src={previewImage} alt={`Insurance card ${face} face`} />
          </div>
          <ElementTracker
            routePath={routePath}
            name={`Reupload file (${face})`}
            type={ElementTrackerType.Clickable}>
            <Button
              variant='outlined'
              onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorElement(e.currentTarget)}>
              Re-upload
            </Button>
          </ElementTracker>
        </>
      ) : (
        <div className='upload-box'>
          <ElementTracker
            routePath={routePath}
            name={`File upload icon (${face})`}
            type={ElementTrackerType.Clickable}>
            <FileUploadIcon
              onClick={(e: MouseEvent<SVGSVGElement>) => setAnchorElement(e.currentTarget)}
            />
          </ElementTracker>
          <Typography variant={'h6'} align={'center'}>
            {text}
          </Typography>
        </div>
      )}
      <ElementTracker
        routePath={routePath}
        name={`File upload (${face})`}
        type={ElementTrackerType.Changeable}>
        <input
          ref={inputRef}
          type='file'
          accept={'image/*'}
          onChange={handleFileSelection}
          hidden
        />
      </ElementTracker>

      {showCamera && (
        <Camera onCaptureImage={handleCaptureImage} onClose={() => setShowCamera(false)} />
      )}

      <Menu anchorEl={anchorElement} open={!!anchorElement} onClose={() => setAnchorElement(null)}>
        <ElementTracker
          routePath={routePath}
          name={`File upload from camera (${face})`}
          type={ElementTrackerType.Clickable}>
          <MenuItem onClick={handleMenuItem({ fromCamera: true })}>Camera</MenuItem>
        </ElementTracker>
        <ElementTracker
          routePath={routePath}
          name={`File upload from library (${face})`}
          type={ElementTrackerType.Clickable}>
          <MenuItem onClick={handleMenuItem({ fromCamera: false })}>Photo Library</MenuItem>
        </ElementTracker>
      </Menu>
    </>
  );
};

export default FileUpload;
