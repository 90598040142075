import {
  ElementTracker,
  ElementTrackerType,
  ENARA_BLOG_URL,
  ENARA_LEARN_MORE_URL,
} from '@enaratech/funnel-helper';
import { Button, Stack } from '@mui/material';
import { FC } from 'react';
import { useRoutePath } from 'src/hooks/useRoutePath';
import './scss/enaraInfo.scss';

const EnaraInfo: FC = () => {
  const routePath = useRoutePath();

  const goToEnaraLearnMore = () => {
    window.open(ENARA_LEARN_MORE_URL, '_blank');
  };

  const goToEnaraGetInspired = () => {
    window.open(ENARA_BLOG_URL, '_blank');
  };

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing='20px'>
      <ElementTracker
        routePath={routePath}
        name='Get Inspired button'
        type={ElementTrackerType.Clickable}>
        <Button
          data-test='lowBmi-button-getInspired'
          className='not-available-buttons'
          variant='outlined'
          onClick={goToEnaraGetInspired}>
          Get Inspired
        </Button>
      </ElementTracker>
      <ElementTracker
        routePath={routePath}
        name='Learn More button'
        type={ElementTrackerType.Clickable}>
        <Button
          data-test='lowBmi-button-learnMore'
          className='not-available-buttons'
          variant='outlined'
          onClick={goToEnaraLearnMore}>
          Learn More
        </Button>
      </ElementTracker>
    </Stack>
  );
};

export default EnaraInfo;
