import {
  coreClient,
  deleteAuth,
  FeatureManager,
  getPartners,
  MembershipType,
  MixpanelClient,
  MixpanelEvent,
  ReferralLandingInfo,
  ReferralRawInfo,
  referralsClient,
  wipeOutAnonymousProgress,
} from '@enaratech/funnel-helper';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingIndicator from 'src/components/Common/LoadingIndicator/LoadingIndicator';
import { Page } from 'src/components/Common/Progress/Progress';
import ProgressBar from 'src/components/Common/Progress/ProgressBar';
import Toast from 'src/components/Common/Toast/Toast';
import { useAuth } from 'src/contexts/auth';
import { SET_AUTH_INFO } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { SET_CLINIC_INFO } from 'src/contexts/clinic/types';
import { useOnboarding } from 'src/contexts/onboarding';
import { SET_ONBOARDING_RESULT } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { navigateToPage } from '../routes';
import CreateReferralAccount from './Steps/CreateReferralAccount/CreateReferralAccount';
import Landing from './Steps/Landing/Landing';

const REFERRALS_QUERY_PARAM = 'rid';

const Referrals: FC = () => {
  const [clinicReferralId, setClinicReferralId] = useState<string | null>(null);
  const [landingInfo, setLandingInfo] = useState<ReferralLandingInfo | null>(null);
  const [currentStep, setCurrentStep] = useState<'loading' | 'check-dob' | 'create-account'>(
    'loading'
  );
  const [rawInfo, setRawInfo] = useState<ReferralRawInfo | null>(null);
  const [dob, setDob] = useState<string | null>(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { dispatchOnboarding } = useOnboarding();
  const { dispatchClinic } = useClinic();
  const { dispatchAuth } = useAuth();
  const routePath = useRoutePath();

  useEffect(() => {
    const clinicReferralId = searchParams.get(REFERRALS_QUERY_PARAM);

    if (!clinicReferralId) {
      return navigateToPage({ targetPage: '/login', navigate });
    }

    (async () => {
      const referralInfo = await referralsClient.getReferralInfo(clinicReferralId);

      if (!referralInfo) {
        MixpanelClient.trackEvent({
          event: MixpanelEvent.InputData,
          properties: {
            field: 'Invalid Referral ID',
            value: clinicReferralId,
            source: routePath,
          },
        });

        Toast.notification('error', 'This does not seem to be a valid referral');

        return navigateToPage({ targetPage: '/login', navigate });
      }

      // Safeguard: We don't want to create the new account with data stored locally
      wipeOutAnonymousProgress();

      setClinicReferralId(clinicReferralId);
      setLandingInfo(referralInfo);

      setCurrentStep('check-dob');
    })();
  }, [navigate, searchParams, routePath]);

  const handleCreateReferralAccount = async (membershipType?: MembershipType): Promise<void> => {
    setCurrentStep('loading');

    const { user, clinic, onboarding, isReferral } = await coreClient.fetchAll();

    const partners = await getPartners();

    MixpanelClient.trackEvent({
      event: MixpanelEvent.InputData,
      properties: {
        field: 'Eligibility match',
        value: !!onboarding.eligibility,
        source: routePath,
      },
    });

    dispatchOnboarding({ type: SET_ONBOARDING_RESULT, payload: onboarding });
    dispatchClinic({ type: SET_CLINIC_INFO, payload: clinic ? { ...clinic, partners } : null });
    dispatchAuth({ type: SET_AUTH_INFO, payload: { loaded: true, isReferral, user } });

    const shouldUseSSBByReferralHubFlow = FeatureManager.shouldForceGoToSSBForReferralHubFlow(
      onboarding.locationFlow
    );
    const shouldUseSSBByFeatures = FeatureManager.shouldReferralGoThroughSSB({
      isReferral,
      clinicId: user!.clinicId,
    });
    const withEligibility = membershipType || !!onboarding.eligibility.membershipType;

    if (shouldUseSSBByReferralHubFlow || shouldUseSSBByFeatures) {
      if (shouldUseSSBByReferralHubFlow) {
        if (withEligibility) {
          return navigateToPage({ targetPage: '/coverage', navigate });
        }

        return navigateToPage({ targetPage: '/appointment/ms-booking', navigate });
      }

      if (!withEligibility) {
        deleteAuth();

        Toast.notification(
          'error',
          'We are still waiting for your insurance coverage to determine your membership alternatives. Please, reach out to our member success team for more information',
          { autoClose: false }
        );

        return navigateToPage({ targetPage: '/login', navigate });
      }

      navigateToPage({ targetPage: '/coverage', navigate });
    } else {
      navigateToPage({ targetPage: '/appointment/ms-booking', navigate });
    }
  };

  return (
    <>
      <ProgressBar currentPage={Page.Referrals} allowUsersToLogin={false} />

      {currentStep === 'loading' ? (
        <div className='indicator-container'>
          <LoadingIndicator />
        </div>
      ) : currentStep === 'check-dob' ? (
        <Landing
          clinicReferralId={clinicReferralId!}
          landingInfo={landingInfo!}
          onValidateDob={(rawInfo, dob) => {
            setRawInfo(rawInfo);
            setDob(dob);
            setCurrentStep('create-account');
          }}
        />
      ) : (
        <CreateReferralAccount
          landingInfo={landingInfo!}
          rawInfo={rawInfo!}
          dateOfBirth={dob!}
          onCreateReferralAccount={handleCreateReferralAccount}
        />
      )}
    </>
  );
};

export default Referrals;
