import { Step, useViewport } from '@enaratech/funnel-helper';
import { StepLabel, Step as StepMui, Stepper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import CheckCircleIcon from 'src/components/Common/Icons/CheckCircleIcon';
import DatePickerIcon from 'src/components/Common/Icons/DatePickerIcon';
import './scss/scheduleSteps.scss';

type SchedulingStepsType = {
  steps: Step[];
};

const SchedulingSteps: FC<SchedulingStepsType> = ({ steps }) => {
  const [ssbSteps, setSsbSteps] = useState<Step[] | null>(null);

  const { isMobileView, isTabletView, isDesktopView } = useViewport();

  useEffect(() => {
    setSsbSteps(steps);
  }, [steps]);

  if (!ssbSteps) {
    return null;
  }

  return (
    <Box
      sx={{
        width: isMobileView || isTabletView ? '80%' : '50%',
        marginBottom: '20px',
        marginTop: '30px',
      }}>
      <Stepper
        id='stepper-1'
        alternativeLabel={!isMobileView && !isTabletView}
        orientation={`${isMobileView || isTabletView ? 'vertical' : 'horizontal'}`}>
        {ssbSteps.map(({ details, label, active, scheduled }, i) => (
          <StepMui
            key={`ssb-step-${i}`}
            className={`scheduling-step`}
            completed={active || scheduled}>
            <StepLabel
              icon={
                scheduled ? (
                  <CheckCircleIcon />
                ) : (
                  <DatePickerIcon
                    viewBox='2 2 20 20'
                    className={`schedule-steps-icon ${
                      active ? 'ssb-icon-active' : 'ssb-icon-disabled'
                    }`}
                  />
                )
              }>
              {isDesktopView && (
                <Typography
                  variant={'h6'}
                  sx={{
                    color: '#bdbdbd',
                  }}>
                  {label}
                </Typography>
              )}
              {details && (
                <Typography
                  variant={'subtitle1'}
                  sx={{ color: `${active ? '#424242' : '#bdbdbd'}`, lineHeight: '20px' }}>
                  {details.description}
                </Typography>
              )}
            </StepLabel>
          </StepMui>
        ))}
      </Stepper>
    </Box>
  );
};

export default SchedulingSteps;
