import {
  ElementTracker,
  ElementTrackerType,
  onboardingClient,
  unformatNumber,
} from '@enaratech/funnel-helper';
import { Grid, TextField } from '@mui/material';
import { ChangeEvent, Dispatch, FC, useState } from 'react';
import CheckCircleIcon from 'src/components/Common/Icons/CheckCircleIcon';
import Toast from 'src/components/Common/Toast/Toast';
import { useRoutePath } from 'src/hooks/useRoutePath';
import './scss/verificationCodeInput.scss';

type Props = {
  handleSendCode: () => void;
  handleValidCode: Dispatch<React.SetStateAction<boolean>>;
  phone: string;
};

const VerificationCodeInput: FC<Props> = ({ handleSendCode, handleValidCode, phone }) => {
  const [value, setValue] = useState('');

  const routePath = useRoutePath();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const validateCode = async () => {
    try {
      const response = await onboardingClient.verifyCode({
        phoneNumber: phone,
        code: unformatNumber(value),
      });

      handleValidCode(response?.success!);
    } catch (error) {
      Toast.notification('error', 'There was an error, please try again later');
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems={'center'} className='verification-code'>
        <Grid item>
          <ElementTracker
            routePath={routePath}
            name='Verification Code'
            type={ElementTrackerType.Blurrable}>
            <TextField
              label='Verification Code'
              variant='filled'
              id='promo-input'
              name='verificationCode'
              type='text'
              value={value}
              onChange={onChange}
            />
          </ElementTracker>
        </Grid>
        <Grid item>
          <CheckCircleIcon onClick={validateCode} name={'Validate Code'} />
        </Grid>
      </Grid>
      <div className='verification-code-again'>
        Didn&apos;t receive the code? <a onClick={handleSendCode}>Send again</a>
      </div>
    </>
  );
};

export default VerificationCodeInput;
