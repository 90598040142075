import {
  CoverageInfo,
  CoveredBy,
  MembershipType,
  getParsedCoverageInfo,
  getPlanCostCopy,
  isTechMembership,
  useViewport,
} from '@enaratech/funnel-helper';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckIcon from 'src/components/Common/Icons/CheckIcon';
import EnaraListItemIcon from 'src/components/Common/Icons/EnaraListItemIcon';
import { Page, withProgress } from 'src/components/Common/Progress/IPO/IPOProgress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { useClinic } from 'src/contexts/clinic';
import { useOnboarding } from 'src/contexts/onboarding';
import 'src/pages/Coverage/scss/coverage.scss';
import { IPOPath, navigateToPage } from '../../routes';

const IPOCoverage: FC = () => {
  const [coverage, setCoverage] = useState<CoverageInfo | null>(null);
  const { clinicState } = useClinic();

  const {
    onboardingState: {
      eligibility: { membershipType },
    },
  } = useOnboarding();

  const {
    authState: { user },
  } = useAuth();

  const navigate = useNavigate();

  const { isMobileView, isTabletView } = useViewport();

  const mustDisplayFooter = (): boolean => {
    return (
      (membershipType === MembershipType.InNetwork ||
        membershipType === MembershipType.Umbrella ||
        membershipType === MembershipType.InNetworkHD) &&
      !isTechMembership(membershipType)
    );
  };

  const handleCreateAccountClick = () => {
    navigateToPage({ targetPage: '/payment-method', navigate });
  };

  const handleCloseClick = () => {
    navigateToPage({ targetPage: IPOPath.Welcome, navigate });
  };

  const getTitle = (): string => {
    if (!coverage) {
      return '';
    }

    if (!membershipType) {
      return getPlanCostCopy(coverage);
    }

    return coverage.title;
  };

  const getSubtitle = (): ReactNode => {
    if (!coverage) {
      return '';
    }

    if (isTechMembership(membershipType)) {
      return (
        <>
          <Typography variant={'h4'}>{coverage.description}</Typography>
          <Typography variant={'h3'} marginTop={5}>
            ${coverage.prices.monthly} per month / ${coverage.prices.annual} per year
          </Typography>
        </>
      );
    } else if (!membershipType) {
      return null;
    }

    return (
      <>
        <Typography variant={'h4'}>{coverage.description}</Typography>
        <Typography variant={'h3'} marginTop={3}>
          {getPlanCostCopy(coverage)}
        </Typography>
      </>
    );
  };

  const getFooterContent = (): string => {
    if (membershipType === MembershipType.InNetworkHD) {
      return 'Copay or deductible amounts owed for your visits will be covered by your $3200 credit. Once you’ve used up your credit amount for the year, you will be responsible for your share of appointment fees';
    }

    return 'Care credit is used to cover copays and deductibles for medical services. Care credit can not be used for lab deductibles';
  };

  const handleGetCoverage = async () => {
    const coverageResponse = await getParsedCoverageInfo({
      membershipType,
      insuranceCompany: user!.insuranceCompany,
      programType: user!.programType,
      clinicId: user!.clinicId,
    });

    setCoverage(coverageResponse);
  };

  useEffect(() => {
    handleGetCoverage();
  }, []);

  return (
    <BasicLayout
      title={getTitle()}
      subtitle={getSubtitle()}
      buttonProps={{ text: 'Create Account', onClick: handleCreateAccountClick }}
      secondaryButtonProps={{ text: 'Close', onClick: handleCloseClick }}
      back={false}
      className='coverage'>
      <Stack direction={isMobileView || isTabletView ? 'column' : 'row'}>
        <List
          className={`coverage-list ${isMobileView || isTabletView ? 'coverage-list-full' : ''}`}>
          <ListItem>
            <ListItemIcon>
              <EnaraListItemIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='h6'>Included in your Enara Membership</Typography>
            </ListItemText>
          </ListItem>
          {coverage?.items.map(
            ({ text, coveredBy }, i) =>
              coveredBy === CoveredBy.Enara && (
                <ListItem key={`coverage-item-${i}`}>
                  <ListItemIcon>
                    <EnaraListItemIcon style={{ width: 15 }} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant='subtitle1'>{text}</Typography>
                  </ListItemText>
                </ListItem>
              )
          )}
        </List>

        <div
          className={`coverage-list-divider ${
            isMobileView || isTabletView ? 'horizontal' : 'vertical'
          }`}></div>

        <List
          className={`coverage-list ${isMobileView || isTabletView ? 'coverage-list-full' : ''}`}>
          <ListItem>
            <ListItemIcon>
              <CheckIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography variant='h6'>
                Covered by your insurance (copays and deductibles may apply)
              </Typography>
            </ListItemText>
          </ListItem>
          {coverage?.items.map(
            ({ text, coveredBy }, i) =>
              coveredBy === CoveredBy.Insurance && (
                <ListItem key={`coverage-item-${i}`}>
                  <ListItemIcon>
                    <CheckIcon style={{ width: 17.5 }} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant='subtitle1'>{text}</Typography>
                  </ListItemText>
                </ListItem>
              )
          )}
        </List>
      </Stack>

      {mustDisplayFooter() && (
        <Paper
          elevation={0}
          square
          className='coverage-bottom-box coverage-list coverage-list-full'>
          <Stack>
            <List>
              <ListItem>
                <ListItemIcon>*</ListItemIcon>
                <ListItemText>
                  <Typography variant='h6'>{getFooterContent()}</Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Stack>
        </Paper>
      )}
    </BasicLayout>
  );
};

export default withProgress(IPOCoverage, Page.IPOCoverage);
