import { ElementTracker, ElementTrackerType } from '@enaratech/funnel-helper';
import { InputAdornment, TextField } from '@mui/material';
import { MobileDatePicker as MuiMobileDatePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { FC } from 'react';
import DatePickerIcon from 'src/components/Common/Icons/DatePickerIcon';
import { useRoutePath } from 'src/hooks/useRoutePath';

type Props = {
  error?: boolean;
  errorMessage?: string;
  value: DateTime | null;
  onChange: (date: DateTime | null) => void;
};

const MobileDatePicker: FC<Props> = ({ error, errorMessage, value, onChange }) => {
  const routePath = useRoutePath();

  return (
    <MuiMobileDatePicker
      disableFuture
      openTo='day'
      inputFormat='MM/dd/yyyy'
      onChange={onChange}
      value={value}
      renderInput={(params) => (
        <ElementTracker routePath={routePath} name='Birthdate' type={ElementTrackerType.Blurrable}>
          <TextField
            id='mobileDatePicker'
            {...params}
            variant='filled'
            fullWidth
            placeholder='MM/DD/YYYY'
            hiddenLabel
            onChange={(e) => onChange(DateTime.fromFormat(e.target.value, 'MM/dd/yyyy'))}
            error={error}
            helperText={error && errorMessage}
            inputProps={{
              ...params.inputProps,
              placeholder: 'MM/DD/YYYY',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <DatePickerIcon />
                </InputAdornment>
              ),
            }}
          />
        </ElementTracker>
      )}
    />
  );
};

export default MobileDatePicker;
